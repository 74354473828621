import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import {
  FileExplorer,
  FileInfo,
  FileUploadProgress,
  FileDownloadProgress,
} from "src/common/app-base-components/FileExplorer/FileExplorer";
import { Card, CardContent } from "src/common/shadcn/card";
import { Button } from "src/common/shadcn/button";
import { ChevronDown, ChevronRight, FileText, Code } from "lucide-react";
import {
  uploadArtifact,
  listArtifacts,
  createFolder,
  moveArtifact,
  renameArtifact,
  deleteArtifact,
  createFileArtifact,
  copyArtifact,
} from "src/usecase/core-subject-management/api/artifact-api";
import { UploadArtifactRequest } from "src/usecase/core-subject-management/DTO/artifact/UploadArtifactRequest";
import { toast } from "src/common/shadcn/use-toast";
import { downloadArtifact } from "src/usecase/core-subject-management/api/artifact-api";
import { AxiosProgressEvent } from "axios";
import { Input } from "src/common/shadcn/input";
import { FileActionDialog, FileActionType } from "src/common/app-base-components/FileExplorer/FileActionDialog";
import { ArtifactDTO } from "src/usecase/core-subject-management/DTO/common/ArtifactDTO";

interface TicketFileExplorerProps {
  ticketId: number;
  onArtifactSelect: (artifactId: number) => void;
  showSelectArtifactDialog?: (isForCopy?: boolean) => Promise<number | undefined>;
  isWritable?: boolean;
}

export interface TicketFileExplorerRef {
  refresh: () => Promise<void>;
  handleCreateFile: (fileName: string, extension: string, parentFolderId: number | null) => Promise<ArtifactDTO | null>;
}

export const TicketFileExplorer = forwardRef<TicketFileExplorerRef, TicketFileExplorerProps>(({
  ticketId,
  onArtifactSelect,
  showSelectArtifactDialog,
  isWritable = true,
}, ref) => {
  const [searchParams] = useSearchParams();
  const workspaceId = parseInt(searchParams.get('wid') || '0');
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [files, setFiles] = useState<FileInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<FileUploadProgress | undefined>(undefined);

  const [downloadProgress, setDownloadProgress] = useState<
    FileDownloadProgress | undefined
  >(undefined);

  const [actionType, setActionType] = useState<FileActionType | null>(null);
  const [actionValue, setActionValue] = useState("");
  const [currentFolderId, setCurrentFolderId] = useState<number | null>(null);

  const fetchArtifacts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await listArtifacts(workspaceId, { ticket_id: ticketId });
      const fileInfos: FileInfo[] = response.artifacts.map((artifact) => ({
        id: artifact.id,
        name: artifact.artifact_file_name,
        size: artifact.size,
        updatedAt: artifact.updated_at,
        isFolder: artifact.is_folder,
        parentFolderId: artifact.parent_folder_id,
      }));
      setFiles(fileInfos);
    } catch (error) {
      console.error("Error fetching artifacts:", error);
      toast({
        title: "Error",
        description: "Failed to fetch artifacts",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  }, [ticketId, workspaceId]);

  useImperativeHandle(ref, () => ({
    refresh: fetchArtifacts,
    handleCreateFile
  }));

  useEffect(() => {
    fetchArtifacts();
  }, [ticketId, workspaceId]);

  const handleUpload = async (files: File[], parentFolderId: number | null) => {
    for (const file of files) {
      console.log("Handle upload:", parentFolderId);
      const request: UploadArtifactRequest = {
        ticket_id: ticketId,
        artifact_title: file.name,
        artifact_description: `Uploaded file: ${file.name}`,
        parent_folder_id: parentFolderId,
      };

      try {
        await uploadArtifact(workspaceId, request, file, (progressEvent) => {
          const { loaded, total } = progressEvent;
          setUploadProgress({
            fileName: file.name,
            progress: total ? Math.round((loaded * 100) / total) : 0,
            loaded,
            total: total || 0,
            completed: false,
          });
        });
        setUploadProgress(undefined);
        await fetchArtifacts();
        toast({
          title: "Success",
          description: `File "${file.name}" uploaded successfully`,
        });
      } catch (error) {
        console.error("Error uploading artifact:", error);
        setUploadProgress(undefined);
        const errorMessage =
          error instanceof Error ? error.message : String(error);
        toast({
          title: "Error",
          description: `Failed to upload file "${file.name}": ${errorMessage}`,
          variant: "destructive",
        });
      }
    }
  };

  const handleDownload = useCallback(
    async (fileIds: number[]) => {
      for (const fileId of fileIds) {
        const file = files.find((f) => f.id === fileId);
        if (!file) continue;

        try {
          setDownloadProgress({
            fileName: file.name,
            progress: 0,
            loaded: 0,
            total: file.size,
            completed: false,
          });

          const blob = await downloadArtifact(workspaceId, fileId, (progressEvent: AxiosProgressEvent) => {
            const { loaded, total } = progressEvent;
            setDownloadProgress({
              fileName: file.name,
              progress: total ? Math.round((loaded * 100) / total) : 0,
              loaded,
              total: total || file.size,
              completed: false,
            });
          });

          const url = window.URL.createObjectURL(blob);
          
          if (downloadLinkRef.current) {
            downloadLinkRef.current.href = url;
            downloadLinkRef.current.download = file.name;
            downloadLinkRef.current.click();
            window.URL.revokeObjectURL(url);
          }

          setDownloadProgress({
            fileName: file.name,
            progress: 100,
            loaded: file.size,
            total: file.size,
            completed: true,
          });

          toast({
            title: "Success",
            description: `Downloaded ${file.name}`,
          });
        } catch (error) {
          console.error("Error downloading file:", error);
          toast({
            title: "Error",
            description: `Failed to download ${file.name}`,
            variant: "destructive",
          });
        } finally {
          setDownloadProgress(undefined);
        }
      }
    },
    [workspaceId, files]
  );

  const handleCreateFolder = async (
    folderName: string,
    parentFolderId: number | null
  ) => {
    try {
      await createFolder(workspaceId, {
        ticket_id: ticketId,
        folder_name: folderName,
        parent_folder_id: parentFolderId,
      });
      await fetchArtifacts();
      toast({
        title: "Success",
        description: `Folder "${folderName}" created successfully`,
      });
    } catch (error) {
      console.error("Error creating folder:", error);
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      toast({
        title: "Error",
        description: `Failed to create folder: ${errorMessage}`,
        variant: "destructive",
      });
    }
  };

  const handleMove = async (
    fileIds: number[],
    targetFolderId: number | null
  ) => {
    try {
      await Promise.all(
        fileIds.map((fileId) =>
          moveArtifact(workspaceId, {
            artifact_id: fileId,
            folder_id: targetFolderId,
          })
        )
      );
      await fetchArtifacts();
      toast({
        title: "Success",
        description: "Items moved successfully",
      });
    } catch (error) {
      console.error("Error moving items:", error);
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      toast({
        title: "Error",
        description: `Failed to move items: ${errorMessage}`,
        variant: "destructive",
      });
    }
  };

  const handleRename = async (fileId: number, newName: string) => {
    try {
      await renameArtifact(workspaceId, {
        artifact_id: fileId,
        new_name: newName,
      });
      await fetchArtifacts();
      toast({
        title: "Success",
        description: "Item renamed successfully",
      });
    } catch (error) {
      console.error("Error renaming item:", error);
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      toast({
        title: "Error",
        description: `Failed to rename item: ${errorMessage}`,
        variant: "destructive",
      });
    }
  };

  const handleDelete = async (fileIds: number[]) => {
    try {
      await Promise.all(
        fileIds.map((fileId) => deleteArtifact(workspaceId, fileId))
      );
      await fetchArtifacts();
      toast({
        title: "Success",
        description:
          fileIds.length > 1
            ? "Items deleted successfully"
            : "Item deleted successfully",
      });
    } catch (error) {
      console.error("Error deleting items:", error);
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      toast({
        title: "Error",
        description: `Failed to delete items: ${errorMessage}`,
        variant: "destructive",
      });
    }
  };

  const handleFileSelect = (fileId: number) => {
    const file = files.find((f) => f.id === fileId);
    if (file && !file.isFolder) {
      onArtifactSelect(fileId);
    }
  };

  const handleFolderChange = (folderId: number | null) => {
    setCurrentFolderId(folderId);
  };

  const handleCreateFile = useCallback(
    async (
      fileName: string,
      extension: string,
      parentFolderId: number | null
    ):Promise<ArtifactDTO | null> =>  {
      try {
        const createdArtifact = await createFileArtifact(workspaceId, {
          ticket_id: ticketId,
          file_name: `${fileName}${extension}`,
          description: null,
          parent_folder_id: parentFolderId,
        });
        await fetchArtifacts();
        toast({
          title: "Success",
          description: `File "${fileName}${extension}" created successfully`,
        });
        return createdArtifact.artifact;
      } catch (error) {
        console.error("Error creating file:", error);
        const errorMessage = error instanceof Error ? error.message : String(error);
        toast({
          title: "Error",
          description: `Failed to create file: ${errorMessage}`,
          variant: "destructive",
        });
        return null;
      }
    },
    [ticketId, workspaceId, fetchArtifacts]
  );

  const handleCopyFromTicket = async () => {
    if (!showSelectArtifactDialog) return;
    
    try {
      const artifactId = await showSelectArtifactDialog(true);
      if (!artifactId) return;

      await copyArtifact(workspaceId, {
        source_artifact_id: artifactId,
        target_ticket_id: ticketId,
        target_folder_id: currentFolderId || null,
      });

      await fetchArtifacts();
      toast({
        title: "Success",
        description: "Artifact copied successfully",
      });
    } catch (error) {
      console.error("Error copying artifact:", error);
      toast({
        title: "Error",
        description: "Failed to copy artifact",
        variant: "destructive",
      });
    }
  };

  const supportedFileTypes = [
    {
      extension: ".solartext",
      label: "Rich Text File",
      icon: <FileText className="h-4 w-4" />,
    },
    {
      extension: ".txt",
      label: "Text File",
      icon: <FileText className="h-4 w-4" />,
    },
    // {
    //   extension: ".md",
    //   label: "Markdown",
    //   icon: <FileText className="h-4 w-4" />,
    // },
    // {
    //   extension: ".js",
    //   label: "JavaScript",
    //   icon: <Code className="h-4 w-4" />,
    // },
    // Add more file types as needed
  ];

  return (
    <Card className="shadow-md bg-white mt-6">
      <CardContent className="p-0">
        <Button
          variant="ghost"
          className="w-full flex justify-between items-center p-4 border-b"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <span className="font-semibold text-purple-900">
            Artifacts ({files.length})
          </span>
          {isExpanded ? (
            <ChevronDown className="h-4 w-4 text-purple-700" />
          ) : (
            <ChevronRight className="h-4 w-4 text-purple-700" />
          )}
        </Button>
        {isExpanded && (
            <FileExplorer
              files={files}
              onUpload={handleUpload}
              onCreateFolder={handleCreateFolder}
              onMove={handleMove}
              onRename={handleRename}
              onDelete={handleDelete}
              onDownload={handleDownload}
              uploadProgress={uploadProgress}
              downloadProgress={downloadProgress}
              onFileSelect={handleFileSelect}
              onFolderChange={handleFolderChange}
              supportedFileTypes={supportedFileTypes}
              onCreateFile={handleCreateFile}
              onCopyFromTicket={showSelectArtifactDialog ? handleCopyFromTicket : undefined}
              disabled={!isWritable}
            />
        )}
        <div className="flex flex-col h-full">
          <a ref={downloadLinkRef} className="hidden" />
        </div>
      </CardContent>
    </Card>
  );
});
