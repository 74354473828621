import React, { useEffect, useState } from "react";
import { Button } from "src/common/shadcn/button";
import { Input } from "src/common/shadcn/input";
import { Label } from "src/common/shadcn/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/common/shadcn/card";
import { GetCurrentUser, updateUser } from "../user/api/api";
import { useToast } from "src/common/shadcn/use-toast";
import { AxiosError } from "axios";
import { GetCurrentUserResponseDTO } from "../user/DTO/GetCurrentUserResponseDTO";

interface ApiErrorResponse {
  message: string;
  error?: string;
}

const AccountPasswordPage: React.FC = () => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [user, setUser] = useState<GetCurrentUserResponseDTO | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const userData = await GetCurrentUser();
        setUser(userData);
      } catch (error) {
        console.error('Error loading user data:', error);
        let errorMessage = "An unexpected error occurred while loading user data";
        
        if (error instanceof AxiosError) {
          const apiError = error.response?.data as ApiErrorResponse;
          errorMessage = apiError?.message || apiError?.error || error.message;
        } else if (error instanceof Error) {
          errorMessage = error.message;
        }

        toast({
          title: "Error Loading User Data",
          description: errorMessage,
          variant: "destructive",
        });
      }
    };
    loadUserData();
  }, []);

  const handleUpdatePassword = async () => {
    if (!user) return;
    
    if (newPassword !== confirmPassword) {
      toast({
        title: "Error",
        description: "New passwords do not match",
        variant: "destructive",
      });
      return;
    }
    
    setIsLoading(true);
    try {
      await updateUser(user.id, {
        oldPassword: oldPassword,
        newPassword: newPassword,
      });
      
      toast({
        title: "Success",
        description: "Password updated successfully",
      });
      
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.error('Error updating password:', error);
      let errorMessage = "An unexpected error occurred while updating password";
      
      if (error instanceof AxiosError) {
        const apiError = error.response?.data as ApiErrorResponse;
        errorMessage = apiError?.message || apiError?.error || error.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      
      toast({
        title: "Password Update Failed",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-8 overflow-auto">
      <Card className="max-w-2xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-semibold text-gray-900">
            Change Password
          </CardTitle>
          <CardDescription>
            Update your account password
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="old-password">Current Password</Label>
            <Input
              id="old-password"
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="new-password">New Password</Label>
            <Input
              id="new-password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="confirm-password">Confirm New Password</Label>
            <Input
              id="confirm-password"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full"
            />
          </div>
          <Button 
            className="w-full" 
            onClick={handleUpdatePassword}
            disabled={isLoading || !oldPassword || !newPassword || !confirmPassword}
          >
            {isLoading ? "Updating..." : "Update Password"}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default AccountPasswordPage;
