import { Label } from "src/common/shadcn/label";
import { Input } from "src/common/shadcn/input";
import { TicketDTO } from "src/usecase/core-subject-management/DTO/common/TicketDTO";
import { UpdateTicketRequest } from "src/usecase/core-subject-management/DTO/ticket/UpdateTicketRequest";
import { DueDatePicker } from "./DueDatePicker";
import { TimestampInfo } from "./TimestampInfo";

export const TicketMetadata = ({
  ticket,
  handleUpdate,
  isWritable = true,
}: {
  ticket: TicketDTO;
  handleUpdate: (field: keyof UpdateTicketRequest, value: any) => Promise<void>;
  isWritable?: boolean;
}) => (
  <div className="grid grid-cols-2 gap-4">
    <div className="col-span-2 text-xs text-gray-500 mt-2">
      <TimestampInfo ticket={ticket} />
    </div>
  </div>
);
