import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { LucideIcon, Heading1, Heading2, Bold, Italic, Strikethrough, List, ListOrdered, Quote, Code, Table, CheckSquare, Pilcrow } from "lucide-react";
import "./command_list.css";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandEmpty,
} from "src/common/shadcn/command";

export type CommandListProps = {
  items: unknown[];
  command: (item: unknown) => void;
  editor: unknown;
};

const getIconByName = (name: string): JSX.Element => {
  const icons: Record<string, JSX.Element> = {
    "header-one": <Heading1 />,
    "header-two": <Heading2 />,
    "paragraph": <Pilcrow />,
    "bold": <Bold />,
    "italic": <Italic />,
    "strikethrough": <Strikethrough />,
    "bullet-list": <List />,
    "numbered-list": <ListOrdered />,
    "citation": <Quote />,
    "code": <Code />,
    "th": <Table />,
    "form": <CheckSquare />,
  };

  const IconComponent = icons[name];
  return IconComponent || <></>;
};

export const TheCommandList = forwardRef(
  ({ items, command, editor }: CommandListProps, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
      setSelectedIndex(0);
    }, [items]);

    console.log("####Command list created. items", items);

    const onKeyDown = useCallback(
      ({ event }: { event: React.KeyboardEvent<HTMLDivElement> }) => {
        if (event.key === "ArrowUp") {
          setSelectedIndex((selectedIndex + items.length - 1) % items.length);
          return true;
        }

        if (event.key === "ArrowDown") {
          setSelectedIndex((selectedIndex + 1) % items.length);
          return true;
        }

        if (event.key === "Enter") {
          if (items[selectedIndex]) {
            command(items[selectedIndex]);
          }
          return true;
        }

        return false;
      },
      [command, items, selectedIndex]
    );

    useImperativeHandle(ref, () => ({
      onKeyDown,
    }));


    return (
      <div className="relative rounded-lg overflow-hidden text-sm">
        <Command>
        {items.length !== 0 && (
            <CommandList>
              <CommandGroup>
                {items.map((item: any, index: number) => (
                  <CommandItem
                    key={index}
                    onSelect={() => {
                      if (item) {
                        command(item);
                      }
                    }}
                    className={`commandListItem ${
                      index === selectedIndex ? "commandListItemSelected" : ""
                    }`}
                  >
                    {getIconByName(item.icon)}
                    {item.title}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
        )}
        {items.length === 0 && <CommandEmpty>No results found.</CommandEmpty>}
        </Command>
      </div>
    );
  }
);
