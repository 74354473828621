import { AtSign, Mail } from "lucide-react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "src/common/shadcn/hover-card";
import { Avatar, AvatarImage, AvatarFallback } from "src/common/shadcn/avatar";
import {
  SubjectDTO,
  SubjectType,
} from "src/usecase/core-subject-management/DTO/common/SubjectDTO";
import { getSubjectIcon } from "../common";

interface SubjectHoverCardProps {
  subject: SubjectDTO;
  children: React.ReactNode;
}

export const SubjectHoverCard = ({
  subject,
  children,
}: SubjectHoverCardProps) => (
  <HoverCard openDelay={300} closeDelay={200}>
    <HoverCardTrigger asChild>
      <div className="inline-block cursor-pointer">{children}</div>
    </HoverCardTrigger>
    <HoverCardContent className="w-64" sideOffset={5} alignOffset={-40}>
      <div className="flex gap-4">
        <Avatar className="h-12 w-12">
          <AvatarImage src={`/avatars/${subject.avatar_id}`} />
          <AvatarFallback className="bg-gray-100 text-gray-500">
            {subject.name.charAt(0)}
          </AvatarFallback>
        </Avatar>
        <div className="space-y-1">
          <h4 className="text-sm font-semibold">{subject.name}</h4>
          {subject.alias && (
            <div className="flex items-center gap-1.5">
              <AtSign className="h-3.5 w-3.5 text-gray-400" />
              <span className="text-xs text-gray-600">{subject.alias}</span>
            </div>
          )}
          <div className="flex items-center gap-1.5">
            <Mail className="h-3.5 w-3.5 text-gray-400" />
            <span className="text-xs text-gray-600">
              {subject.contact_email}
            </span>
          </div>
          <div className="flex items-center gap-1.5">
            {getSubjectIcon(subject.type as SubjectType)}
            <span className="text-xs text-gray-600 capitalize">
              {subject.type}
            </span>
          </div>
        </div>
      </div>
    </HoverCardContent>
  </HoverCard>
);
