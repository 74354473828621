import { useWorkspaceNavigate } from "src/common/hooks/useWorkspaceNavigate";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "src/common/shadcn/dropdown";
import { ArtifactDTO } from "src/usecase/core-subject-management/DTO/common/ArtifactDTO";
import { formatFileSize } from "src/utils/utils";
import { File, Folder, Clock, Download, MoreVertical, Loader2, ExternalLink, Search, X } from 'lucide-react';
import { Badge } from "src/common/shadcn/badge";
import { Button } from "src/common/shadcn/button";
import { format } from 'date-fns';

interface ArtifactListItemProps {
  artifact: ArtifactDTO;
  onDownload?: (artifactId: number) => void;
  onClick?: (artifactId: number) => void;
  isWritable?: boolean;
}

export const ArtifactListItem: React.FC<ArtifactListItemProps> = ({
  artifact,
  onDownload,
  onClick,
  isWritable,
}) => {
  const navigate = useWorkspaceNavigate();
  
  const handleTicketClick = (e: React.MouseEvent, ticketId: number) => {
    e.stopPropagation();
    navigate(`/ticket/${ticketId}`);
  };

  return (
    <div 
      className="group flex items-center gap-4 px-4 py-3 hover:bg-gray-50 bg-white hover:shadow-md transition-all cursor-pointer border border-gray-200 rounded-lg mb-2"
      onClick={() => onClick?.(artifact.id)}
    >
      <div className="flex items-center gap-3 min-w-0 flex-1">
        <div className="shrink-0">
          {artifact.is_folder ? (
            <Folder className="h-5 w-5 text-blue-500" />
          ) : (
            <File className="h-5 w-5 text-gray-500" />
          )}
        </div>

        <div className="flex flex-col min-w-0 flex-1">
          <div className="flex items-center gap-2">
            <h4 className="text-sm font-medium text-gray-900 truncate">
              {artifact.artifact_title || artifact.artifact_file_name}
            </h4>
            <span className="text-xs text-gray-500">#{artifact.id}</span>
          </div>
          {artifact.associated_ticket && (
            <div className="flex items-center gap-2 text-xs text-gray-500">
              <span className={`inline-block w-2 h-2 rounded-full ${
                artifact.associated_ticket.terminal_status === 'open' 
                  ? 'bg-green-500' 
                  : 'bg-gray-500'
              }`} />
              <Badge 
                variant="outline" 
                className="text-xs hover:bg-gray-100 cursor-pointer"
                onClick={(e) => handleTicketClick(e, artifact.associated_ticket!.id)}
              >
                Ticket #{artifact.associated_ticket.id}
              </Badge>
              <span>•</span>
              <span className="truncate">{artifact.associated_ticket.title}</span>
              <span>•</span>
              <span className="shrink-0">{artifact.associated_ticket.current_status}</span>
            </div>
          )}
        </div>

        <div className="flex items-center gap-4 text-xs text-gray-600 shrink-0 ml-auto">
          <Badge variant="secondary" className="text-xs">
            {formatFileSize(artifact.size)}
          </Badge>

          <div className="flex items-center gap-1.5">
            <Clock className="h-3.5 w-3.5 text-gray-500" />
            <span>
              {format(new Date(artifact.created_at), "MMM dd, yyyy")}
            </span>
          </div>

          {isWritable && (
            <div className="opacity-0 group-hover:opacity-100 transition-opacity">
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                    <MoreVertical className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  {!artifact.is_folder && (
                    <DropdownMenuItem onClick={(e) => {
                      e.stopPropagation();
                      onDownload?.(artifact.id);
                    }}>
                      <Download className="mr-2 h-4 w-4" />
                      Download
                    </DropdownMenuItem>
                  )}
                  {artifact.associated_ticket && (
                    <DropdownMenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTicketClick(e, artifact.associated_ticket!.id);
                      }}
                    >
                      <ExternalLink className="mr-2 h-4 w-4" />
                      Go to Ticket
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};