import { useEffect, useState, useRef, useCallback } from "react";
import { ThreadDTO } from "src/usecase/core-subject-management/DTO/common/ThreadDTO";
import { CreateThreadRequest } from "src/usecase/core-subject-management/DTO/thread/CreateThreadRequest";
import { Button } from "src/common/shadcn/button";
import { Input } from "src/common/shadcn/input";
import { Card } from "src/common/shadcn/card";
import SingleMdEditor from "src/common/app-base-components/TextEditor/single_md_editor";
import { createThread, listThreadsByTicket  } from "src/usecase/core-subject-management/api/thread-api";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "src/common/shadcn/form";
import { SingleThreadDetail } from "./SingleThreadDetail";
import { artifactCommand } from "src/usecase/core-subject-management/artifact-extension-editor/artifact-extention/command";
import { ArtifactNode } from "src/usecase/core-subject-management/artifact-extension-editor/artifact-extention/ArtifactNode";
import { TicketFileExplorerRef } from "./TicketFileExplorer";
import { ArtifactDTO } from "src/usecase/core-subject-management/DTO/common/ArtifactDTO";
import { useSearchParams } from 'react-router-dom';

const threadFormSchema = z.object({
  thread_title: z.string().min(1, "Title is required"),
  thread_content: z.string().min(1, "Content is required"),
});

interface ThreadsViewProps {
  ticketId: number;
  fileExplorerRef: React.RefObject<TicketFileExplorerRef>;
  handleArtifactSelect: (artifactId: number) => void;
  showSelectArtifactDialog: () => Promise<number | undefined>;
  handleInsertNewEmptyArtifact: (extension: string) => Promise<ArtifactDTO | null>;
  isWritable?: boolean;
}

export const ThreadsView: React.FC<ThreadsViewProps> = ({ 
  ticketId, 
  fileExplorerRef, 
  handleArtifactSelect, 
  showSelectArtifactDialog, 
  handleInsertNewEmptyArtifact,
  isWritable = true 
}) => {
  const [searchParams] = useSearchParams();
  const workspaceId = parseInt(searchParams.get('wid') || '0');
  const [threads, setThreads] = useState<ThreadDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const editorRef = useRef<any>(null);

  const form = useForm<Omit<CreateThreadRequest, "ticket_id">>({
    resolver: zodResolver(threadFormSchema),
    defaultValues: {
      thread_title: "",
      thread_content: "",
    },
  });

  const fetchThreads = useCallback(async () => {
    if (!workspaceId) {
      setThreads([]);
      setLoading(false);
      return;
    }
    try {
      const response = await listThreadsByTicket(workspaceId, ticketId);
      setThreads(response || []);
    } catch (error) {
      console.error("Failed to fetch threads:", error);
      setThreads([]);
    } finally {
      setLoading(false);
    }
  }, [ticketId, workspaceId]);

  useEffect(() => {
    fetchThreads();
  }, [fetchThreads]);

  const handleCreateThread = useCallback(async (data: Omit<CreateThreadRequest, "ticket_id">) => {
    if (!workspaceId) return;
    try {
      const content = editorRef.current?.getJsonString();
      const request: CreateThreadRequest = {
        ticket_id: ticketId,
        thread_title: data.thread_title,
        thread_content: content,
      };
      await createThread(workspaceId, request);
      form.reset();
      editorRef.current?.setContent("");
      setShowCreateForm(false);
      await fetchThreads();
    } catch (error) {
      console.error("Failed to create thread:", error);
    }
  }, [workspaceId, fetchThreads]);

  return (
    <div className="space-y-4">
      {isWritable && (
        <div className="flex justify-end">
          <Button onClick={() => setShowCreateForm(true)} disabled={showCreateForm}>
            Create Thread
          </Button>
        </div>
      )}

      {showCreateForm && (
        <Card className="p-4">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleCreateThread)} className="space-y-4">
              <FormField
                control={form.control}
                name="thread_title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Title</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter thread title" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="thread_content"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Content</FormLabel>
                    <FormControl>
                      <SingleMdEditor
                        ref={editorRef}
                        visible={true}
                        additionalQueryCommand={(query) => artifactCommand(query, ticketId, workspaceId, () => {
                          fileExplorerRef.current?.refresh();
                        }, showSelectArtifactDialog, handleInsertNewEmptyArtifact)}
                        onChange={() => {
                          const content = editorRef.current?.getJsonString();
                          field.onChange(content);
                        }}
                        viewingMode={ isWritable? "edit" : "display"}
                        additionalExtensions={[ArtifactNode.configure(
                          {
                            showArtifact: handleArtifactSelect
                          }
                        )]}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex justify-end space-x-2">
                <Button type="button" variant="outline" onClick={() => setShowCreateForm(false)}>
                  Cancel
                </Button>
                <Button type="submit">Create</Button>
              </div>
            </form>
          </Form>
        </Card>
      )}

      <div className="space-y-4">
        {threads.map((thread) => (
          <SingleThreadDetail
            key={thread.id}
            thread={thread}
            fileExplorerRef={fileExplorerRef}
            handleArtifactSelect={handleArtifactSelect}
            showSelectArtifactDialog={showSelectArtifactDialog}
            handleInsertNewEmptyArtifact={handleInsertNewEmptyArtifact}
            isWritable={isWritable}
            onThreadDelete={fetchThreads}
          />
        ))}
      </div>
    </div>
  );
};
