import React from 'react';

interface PptxArtifactViewProps {
  artifactId: string;
  workspaceId: number;
}

export const PptxArtifactView: React.FC<PptxArtifactViewProps> = ({
  artifactId,
  workspaceId,
}) => {
  const baseURL = process.env.REACT_APP_API_URL;
  const pptxViewerUrl = `/pptxjs/index.html`;
  const fileUrl = `${baseURL}/workspaces/${workspaceId}/artifacts/raw/${artifactId}`;

  return (
    <div className="w-full h-full bg-white">
      <iframe
        src={`${pptxViewerUrl}?fileUrl=${encodeURIComponent(fileUrl)}`}
        className="w-full h-full border-0"
        title="PPTX Viewer"
        allow="fullscreen"
      />
    </div>
  );
};
