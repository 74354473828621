import React, { useState } from 'react';
import { useUsersWithinWorkspace } from 'src/common/hooks/useUsers';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'src/common/shadcn/select';

interface PiCPickerProps {
  value: number | undefined;
  onChange: (value: number) => void;
  disabled?: boolean;
  workspaceId: number;
}

export const PiCPicker: React.FC<PiCPickerProps> = ({ value, onChange, disabled = false, workspaceId }) => {
  const { users, loading: loadingUsers } = useUsersWithinWorkspace(workspaceId);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Select
      onValueChange={(val) => onChange(Number(val))}
      value={value?.toString() || ""}
      disabled={disabled || loadingUsers}
      onOpenChange={setIsOpen}
    >
      <SelectTrigger
        className={`${isOpen ? 'border' : 'border-none'} shadow-none`}
        style={{ backgroundImage: 'none' }}
      >
        <SelectValue placeholder="Select PIC" />
      </SelectTrigger>
      <SelectContent>
        {!loadingUsers && users?.map((user) => (
          <SelectItem key={user.id} value={user.id.toString()}>
            {user.username} ({user.email})
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
