import { Card } from "src/common/shadcn/card";
import { Skeleton } from "src/common/shadcn/skeleton";

export const SubjectSkeleton = () => {
  return (
    <Card className="mx-auto max-w-4xl border-0 shadow-md bg-white p-6">
      <Skeleton className="h-8 w-2/3 mb-4" />
      <Skeleton className="h-4 w-1/3 mb-8" />
      <div className="space-y-6">
        {[...Array(5)].map((_, i) => (
          <div key={i}>
            <Skeleton className="h-4 w-1/4 mb-2" />
            <Skeleton className="h-10 w-full" />
          </div>
        ))}
      </div>
    </Card>
  );
};
