import { createBrowserRouter, Navigate, useSearchParams } from "react-router-dom";
import { HomeLayout } from "src/usecase/home-layout/HomeLayout";
import { MainSidebar } from "src/usecase/home-layout/MainSidebar";
import LoginPage from "src/usecase/auth/component/LoginPage";
import { LogoutPage } from "src/usecase/auth/component/LogoutPage";
import { AccountSidebar } from "src/usecase/account/AccountSidebar";
import TicketHomeView from "src/usecase/ticket-manager/TicketHomeView";
import { SubjectHomeView } from "src/usecase/subject-manager/SubjectHomeView";
import { SubjectDetailView } from "src/usecase/subject-manager/SubjectDetailView";
import { ActivityHomeView } from "src/usecase/activity-manager/ActivityHomeView";
import { TicketDetailView } from "src/usecase/ticket-manager/TicketDetailView";
import RegisterPage from "src/usecase/user/register/RegisterPage";
import CreateWorkspacePage from "src/usecase/home-layout/CreateWorkspacePage";
import AccountBasicInfoPage from "src/usecase/account/AccountBasicInfoPage";
import AccountPasswordPage from "src/usecase/account/AccountPasswordPage";
import { ArtifactHomeView } from "src/usecase/artifact-manager/ArtifactHomeView";

const WorkspaceNavigate = () => {
  const [searchParams] = useSearchParams();
  const wid = searchParams.get('wid');
  return <Navigate to={`/ticket?wid=${wid || ''}`} replace />;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <WorkspaceNavigate />,
  },
  {
    path: "/create-workspace",
    element: <CreateWorkspacePage />,
  },
  {
    path: "/subject",
    element: <HomeLayout 
      innerComponent={<SubjectHomeView />} 
      sidebar={<MainSidebar />}
    />,
  },
  {
    path: "/subject/:id",
    element: <HomeLayout 
      innerComponent={<SubjectDetailView />} 
      sidebar={<MainSidebar />}
    />,
  },
  {
    path: "/ticket",
    element: <HomeLayout 
      innerComponent={<TicketHomeView />} 
      sidebar={<MainSidebar />}
    />,
  },
  {
    path: "/activity",
    element: <HomeLayout 
      innerComponent={<ActivityHomeView />} 
      sidebar={<MainSidebar />}
    />,
  },
  {
    path: "/account",
    element: <HomeLayout 
      innerComponent={<AccountBasicInfoPage />} 
      sidebar={<AccountSidebar />}
    />,
  },
  {
    path: "/account/password",
    element: <HomeLayout 
      innerComponent={<AccountPasswordPage />} 
      sidebar={<AccountSidebar />}
    />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/logout",
    element: <LogoutPage />,
  },
  {
    path: "/ticket/:id",
    element: <HomeLayout 
      innerComponent={<TicketDetailView />} 
      sidebar={<MainSidebar />}
    />,
  },
  {
    path: "/artifact",
    element: <HomeLayout 
      innerComponent={<ArtifactHomeView />} 
      sidebar={<MainSidebar />}
    />,
  }
]);
