import React, { useEffect, useRef, useState } from "react";
import { renderAsync } from "docx-preview";

interface DocxArtifactViewProps {
  artifactId: string;
  workspaceId: number;
}

export const DocxArtifactView: React.FC<DocxArtifactViewProps> = ({
  artifactId,
  workspaceId,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const baseURL = process.env.REACT_APP_API_URL;
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadDocument = async () => {
      try {
        if (!containerRef.current) return;

        const response = await fetch(
          `${baseURL}/workspaces/${workspaceId}/artifacts/raw/${artifactId}`,
          {
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch document");
        }

        const blob = await response.blob();
        await renderAsync(blob, containerRef.current, containerRef.current, {
          className: "docx-viewer",
          inWrapper: true,
        });
      } catch (err) {
        console.error("Error loading DOCX:", err);
        setError("Failed to load document");
      }
    };

    loadDocument();
  }, [artifactId, workspaceId, baseURL]);

  if (error) {
    return <div className="text-red-500 p-4">{error}</div>;
  }

  return (
    <div className="h-full w-full overflow-auto">
      <div ref={containerRef} className="docx-container" />
    </div>
  );
};
