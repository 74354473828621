import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button } from 'src/common/shadcn/button';
import { Input } from 'src/common/shadcn/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/common/shadcn/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/common/shadcn/select';
import { CreateTicketRequest } from '../core-subject-management/DTO/ticket/CreateTicketRequest';
import { SubjectDTO } from '../core-subject-management/DTO/common/SubjectDTO';
import { PiCPicker } from './components/PICPicker';
import SingleMdEditor from "src/common/app-base-components/TextEditor/single_md_editor";
import { useRef } from 'react';

const ticketFormSchema = z.object({
  title: z.string().min(1, 'Title is required'),
  description: z.string().min(1, 'Description is required'),
  priority: z.number({
    required_error: "Priority is required",
    invalid_type_error: "Priority is required"
  }).min(1, 'Invalid priority').max(3, 'Invalid priority'),
  due_date: z.string().optional(),
  pic_user_id: z.number({
    required_error: "Person In Charge is required",
    invalid_type_error: "Person In Charge is required"
  }),
  subject_id: z.number({
    required_error: "Subject is required",
    invalid_type_error: "Subject is required"
  }),
});

interface TicketFormProps {
  onSubmit: (data: CreateTicketRequest & { subject_id: number }) => void;
  onCancel: () => void;
  subjects: SubjectDTO[];
  initialData?: Partial<CreateTicketRequest> & { subject_id: number } | null;
  isEdit?: boolean;
  workspaceId?: number;
}

export const TicketForm: React.FC<TicketFormProps> = ({
  onSubmit,
  onCancel,
  subjects,
  initialData,
  isEdit = false,
  workspaceId,
}) => {
  const form = useForm<CreateTicketRequest & { subject_id: number }>({
    resolver: zodResolver(ticketFormSchema),
    defaultValues: {
      title: initialData?.title || '',
      description: initialData?.description || '',
      priority: initialData?.priority || 2,
      subject_id: initialData?.subject_id || (subjects[0]?.id || 0),
      pic_user_id: initialData?.pic_user_id,
      due_date: initialData?.due_date,
    },
  });

  const editorRef = useRef<any>(null);

  const handleSubmit = (data: CreateTicketRequest & { subject_id: number }) => {
    const content = editorRef.current?.getJsonString();
    const formattedData = {
      ...data,
      description: content,
      due_date: data.due_date ? new Date(data.due_date).toISOString() : undefined,
    };
    onSubmit(formattedData);
  };

  if (!workspaceId) return <div>Workspace ID not found</div>;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Title *</FormLabel>
                <FormControl>
                  <Input placeholder="Enter ticket title" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="subject_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Subject *</FormLabel>
                <Select onValueChange={(val) => field.onChange(Number(val))} defaultValue={field.value?.toString()}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a subject" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {subjects.map((subject) => (
                      <SelectItem key={subject.id} value={subject.id.toString()}>
                        {subject.name} ({subject.type})
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="priority"
            render={({ field: { onChange, value, ...field } }) => (
              <FormItem>
                <FormLabel>Priority</FormLabel>
                <Select 
                  onValueChange={(val) => onChange(Number(val))}
                  value={value?.toString()}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select priority" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="1">P1 - High</SelectItem>
                    <SelectItem value="2">P2 - Medium</SelectItem>
                    <SelectItem value="3">P3 - Low</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="due_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Due Date</FormLabel>
                <FormControl>
                  <Input type="date" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="pic_user_id"
            render={({ field: { onChange, value, ...field } }) => (
              <FormItem>
                <FormLabel>Person In Charge *</FormLabel>
                <PiCPicker
                  value={value}
                  onChange={onChange}
                  disabled={false}
                  workspaceId={workspaceId}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description *</FormLabel>
              <FormControl>
                <SingleMdEditor
                  ref={editorRef}
                  visible={true}
                  onChange={() => {
                    const content = editorRef.current?.getJsonString();
                    field.onChange(content);
                  }}
                  viewingMode="edit"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-end gap-3">
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">
            {isEdit ? 'Update Ticket' : 'Create Ticket'}
          </Button>
        </div>
      </form>
    </Form>
  );
}; 