import React, { useEffect, useState } from "react";
import { PDFArtifactView } from "./ArtifactSidebarComponent/PDFArtifactView";
import { TxtArtifactView } from "./ArtifactSidebarComponent/TxtArtifactView";
import { ImageArtifactView } from "./ArtifactSidebarComponent/ImageArtifactView";
import { AudioArtifactView } from "./ArtifactSidebarComponent/AudioArtifactView";
import { VideoArtifactView } from "./ArtifactSidebarComponent/VideoArtifactView";
import { getArtifact } from "src/usecase/core-subject-management/api/artifact-api";
import { ArtifactDTO } from "src/usecase/core-subject-management/DTO/common/ArtifactDTO";
import { Button } from "src/common/shadcn/button";
import { X, Folder, FileText, AlertCircle, Pencil } from "lucide-react";
import { ArtifactHeader } from "./ArtifactSidebarHeader";
import { renderArtifactContent } from "./ArtifactSidebarComponent/artifactHelper";
import { useAlertDialog } from "src/common/hooks/useAlertDialog";
import { useSearchParams } from 'react-router-dom';

interface ArtifactSidebarProps {
  isOpen: boolean;
  artifactId: number | null;
  onClose: () => void;
  isWritable?: boolean;
}

const Spinner: React.FC<{ className?: string }> = ({ className = "" }) => (
  <div
    className={`animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 ${className}`}
  ></div>
);

export const ArtifactSidebar: React.FC<ArtifactSidebarProps> = ({
  isOpen,
  artifactId,
  onClose,
  isWritable = false,
}) => {
  const [artifact, setArtifact] = useState<ArtifactDTO | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const { showAlert, AlertDialogComponent } = useAlertDialog();
  const [searchParams] = useSearchParams();
  const workspaceId = parseInt(searchParams.get('wid') || '0');

  useEffect(() => {
    if (isOpen) {
      setIsAnimationComplete(false);
      const timer = setTimeout(() => {
        setIsAnimationComplete(true);
      }, 300);
      return () => clearTimeout(timer);
    } else {
      setIsAnimationComplete(false);
      setIsEditing(false);
      setIsModified(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchArtifact = async () => {
      // Disable the edit mode
      setIsEditing(false);

      if (artifactId) {
        setLoading(true);
        setError(null);
        try {
          const data = await getArtifact(workspaceId, artifactId);
          setArtifact(data);
        } catch (error) {
          console.error("Failed to fetch artifact:", error);
          setError("Failed to load artifact. Is it deleted?");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchArtifact();
  }, [artifactId, workspaceId]);

  const handleEditStatusChange = async (newEditingStatus: boolean) => {
    if (isModified && !newEditingStatus) {
      const shouldExit = await showAlert({
        title: "Unsaved Changes",
        description:
          "You have unsaved changes. Are you sure you want to exit edit mode? All changes will be lost.",
        confirmText: "Exit",
        cancelText: "Cancel",
      });

      if (!shouldExit) {
        return;
      }
    }
    setIsEditing(newEditingStatus);
    if (!newEditingStatus) {
      setIsModified(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className={`h-full overflow-hidden transition-all duration-300 ${
          isOpen ? "w-1/2" : "w-0"
        }`}
      >
        {isOpen && (
          <div className="h-full p-1 bg-gray-100 rounded-l-2xl">
            <div className="h-full flex flex-col border border-gray-200 shadow-lg bg-white rounded-xl overflow-hidden">
              <ArtifactHeader
                title="Error"
                onClose={onClose}
              />
              <div className="flex flex-col items-center justify-center h-full p-4 space-y-4 w-full">
                <AlertCircle className="w-12 h-12 text-red-500" />
                <p className="text-red-500 text-center">{error}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  const { component, isEditable } = renderArtifactContent(
    artifact,
    isEditing,
    handleEditStatusChange,
    isModified,
    setIsModified,
    workspaceId
  );

  const renderPlaceholder = () => (
    <div className="h-full flex items-center justify-center">
      <div className="animate-pulse flex flex-col items-center">
        <div className="w-16 h-16 bg-gray-300 rounded-full mb-4"></div>
        <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
        <div className="h-4 bg-gray-300 rounded w-1/2"></div>
      </div>
    </div>
  );

  return (
    <div
      className={`h-full overflow-hidden transition-all duration-300 ${
        isOpen ? "w-1/2" : "w-0"
      }`}
    >
      {isOpen && (
        <div className="h-full p-1 bg-gray-100 rounded-l-2xl">
          <div className="h-full flex flex-col border border-gray-200 shadow-lg bg-white rounded-xl overflow-hidden">
            <ArtifactHeader
              title={
                isAnimationComplete
                  ? artifact?.artifact_title || "Loading..."
                  : "Loading..."
              }
              onClose={onClose}
              editableContent={
                artifact && !artifact.is_folder && isEditable && isWritable
                  ? {
                      canEdit: true,
                      onEdit: () => handleEditStatusChange(!isEditing),
                      isEditing: isEditing,
                      isModified: isModified,
                    }
                  : undefined
              }
            />
            <div className="flex-grow overflow-y-auto">
              {isAnimationComplete ? component : renderPlaceholder()}
            </div>
          </div>
          <AlertDialogComponent />
        </div>
      )}
    </div>
  );
};
