import React, { useState } from "react";
import { FileInfo } from "./FileExplorer";
import { FolderIcon, ChevronRightIcon, ChevronDownIcon } from "lucide-react";
import { Button } from "src/common/shadcn/button";

interface FolderSelectorProps {
  files: FileInfo[];
  onSelect: (folderId: number | null) => void;
}

interface FolderNode {
  id: number;
  name: string;
  children: FolderNode[];
}

const FolderSelector: React.FC<FolderSelectorProps> = ({ files, onSelect }) => {
  const [expandedFolders, setExpandedFolders] = useState<Set<number>>(
    new Set()
  );
  const [selectedFolder, setSelectedFolder] = useState<number | null>(null);

  // Build folder tree
  const buildFolderTree = (files: FileInfo[]): FolderNode[] => {
    const folderMap = new Map<number, FolderNode>();
    const rootFolders: FolderNode[] = [];

    files.forEach((file) => {
      if (file.isFolder) {
        folderMap.set(file.id, { id: file.id, name: file.name, children: [] });
      }
    });

    files.forEach((file) => {
      if (file.isFolder) {
        const folder = folderMap.get(file.id)!;
        if (file.parentFolderId === null) {
          rootFolders.push(folder);
        } else {
          const parentFolder = folderMap.get(file.parentFolderId);
          if (parentFolder) {
            parentFolder.children.push(folder);
          }
        }
      }
    });

    return rootFolders;
  };

  const folderTree = buildFolderTree(files);

  const toggleFolder = (folderId: number) => {
    setExpandedFolders((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(folderId)) {
        newSet.delete(folderId);
      } else {
        newSet.add(folderId);
      }
      return newSet;
    });
  };

  const handleSelect = (folderId: number | null) => {
    setSelectedFolder(folderId);
    onSelect(folderId);
  };

  const renderFolder = (folder: FolderNode, depth: number = 0) => {
    const isExpanded = expandedFolders.has(folder.id);
    const isSelected = selectedFolder === folder.id;

    return (
      <div key={folder.id} style={{ marginLeft: `${depth * 20}px` }}>
        <div
          className={`flex items-center py-1 px-2 cursor-pointer hover:bg-gray-100 ${
            isSelected ? "bg-blue-100" : ""
          }`}
          onClick={() => handleSelect(folder.id)}
        >
          <Button
            variant="ghost"
            size="sm"
            className="mr-1"
            onClick={(e) => {
              e.stopPropagation();
              toggleFolder(folder.id);
            }}
          >
            {folder.children.length > 0 ? (
              isExpanded ? (
                <ChevronDownIcon className="h-4 w-4" />
              ) : (
                <ChevronRightIcon className="h-4 w-4" />
              )
            ) : (
              <span className="w-4" />
            )}
          </Button>
          <FolderIcon className="h-4 w-4 mr-2" />
          <span>{folder.name}</span>
        </div>
        {isExpanded &&
          folder.children.map((childFolder) =>
            renderFolder(childFolder, depth + 1)
          )}
      </div>
    );
  };

  return (
    <div className="max-h-60 overflow-y-auto border rounded p-2">
      <div
        className={`flex items-center py-1 px-2 cursor-pointer hover:bg-gray-100 ${
          selectedFolder === null ? "bg-blue-100" : ""
        }`}
        onClick={() => handleSelect(null)}
      >
        <FolderIcon className="h-4 w-4 mr-2" />
        <span>Root</span>
      </div>
      {folderTree.map((folder) => renderFolder(folder))}
    </div>
  );
};

export default FolderSelector;
