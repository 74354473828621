import Commands from "./command";
import getStaticSuggestionItems from "./getSuggestItems";
import renderItems from "./renderItems";

export type SuggestionItem = {
  title: string;
  command?: ({ editor, range }: { editor: any; range: any }) => void;
  icon?: string;
};

export type QuerySuggestionItem = (query: string) => SuggestionItem[];

export const configureCommand = (queryAdditionalItems?: QuerySuggestionItem) => {
  return Commands.configure({
    suggestion: {
      items: ({ query }: { query: string }) => {
        const staticItems = getStaticSuggestionItems(query);
        const queryItems = queryAdditionalItems?.(query);
        const allItems = [
          ...staticItems,
          ...(queryItems || []),];
        return allItems
          .filter((item) =>
            item.title.toLowerCase().startsWith(query.toLowerCase())
          )
          .slice(0, 10);
      },
      render: renderItems,
    },
  });
};
