import React from 'react';
import { useWorkspaceNavigate } from 'src/common/hooks/useWorkspaceNavigate';
import { User, Building2, Ticket, FileText, MessagesSquare, Clock } from 'lucide-react';

interface SingleActivityProps {
  activity: string;
  updatedAt: string;
}

const getIconComponent = (type: string) => {
  switch (type.toLowerCase()) {
    case 'user':
      return User;
    case 'subject':
      return Building2;
    case 'ticket':
      return Ticket;
    case 'artifact':
      return FileText;
    case 'thread':
      return MessagesSquare;
    default:
      return null;
  }
};

const getEntityPath = (type: string, id: string) => {
  switch (type.toLowerCase()) {
    case 'user':
      return ``;
    case 'subject':
      return `/subject/${id}`;
    case 'ticket':
      return `/ticket/${id}`;
    case 'artifact':
      return ``;
    case 'thread':
      return ``;
    default:
      return '';
  }
};

export const SingleActivity: React.FC<SingleActivityProps> = ({ activity, updatedAt }) => {
  const navigate = useWorkspaceNavigate();
  
  const formatTimestamp = (timestamp: string): string => {
    const date = new Date(timestamp);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    
    if (diffInSeconds < 60) {
      return 'just now';
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else if (diffInSeconds < 604800) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    } else {
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }
  };

  const renderActivityPart = (text: string): React.ReactNode[] => {
    const parts: React.ReactNode[] = [];
    let lastIndex = 0;
    const regex = /\[(User|Subject|Ticket|Artifact|Thread):(\d+):([^\]]+)\]/g;
    
    let match;
    while ((match = regex.exec(text)) !== null) {
      // Add text before the match
      if (match.index > lastIndex) {
        parts.push(text.substring(lastIndex, match.index));
      }
      
      const [, type, id, displayText] = match;
      const IconComponent = getIconComponent(type);
      const path = getEntityPath(type, id);
      
      parts.push(
        path ? (
          <span
            key={`${type}-${id}-${match.index}`}
            className="inline-flex items-center gap-1 text-blue-600 hover:text-blue-800 cursor-pointer"
            onClick={() => navigate(path)}
          >
            {IconComponent && <IconComponent className="h-4 w-4 stroke-2" />}
            <span>{displayText}</span>
          </span>
        ) : (
          <span
            key={`${type}-${id}-${match.index}`}
            className="inline-flex items-center gap-1"
          >
            {IconComponent && <IconComponent className="h-4 w-4 stroke-2" />}
            <span>{displayText}</span>
          </span>
        )
      );
      
      lastIndex = match.index + match[0].length;
    }
    
    // Add remaining text
    if (lastIndex < text.length) {
      parts.push(text.substring(lastIndex));
    }
    
    return parts;
  };

  return (
    <div className="text-gray-700">
      <div className="flex items-start justify-between gap-4">
        <div className="flex-1">
          {renderActivityPart(activity)}
        </div>
        <div className="flex items-center text-sm text-gray-500 whitespace-nowrap">
          <Clock className="h-3 w-3 mr-1" />
          {formatTimestamp(updatedAt)}
        </div>
      </div>
    </div>
  );
};