import React, { useState, useEffect } from "react";
import { Button } from "src/common/shadcn/button";
import { Save, X } from "lucide-react";
import { updateArtifactContent } from "src/usecase/core-subject-management/api/artifact-api";
import { toast } from "src/common/shadcn/use-toast";
import { useSearchParams } from 'react-router-dom';

interface TxtArtifactViewProps {
  artifactId: string;
  workspaceId: number;
  isEditing: boolean;
  onEditStatusChange: (status: boolean) => void;
  isModified: boolean;
  onModifiedChange: (isModified: boolean) => void;
}

export const TxtArtifactView: React.FC<TxtArtifactViewProps> = ({
  artifactId,
  workspaceId,
  isEditing,
  onEditStatusChange,
  isModified,
  onModifiedChange,
}) => {
  const [content, setContent] = useState<string>("");
  const [editedContent, setEditedContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const baseURL = process.env.REACT_APP_API_URL;

  const fetchTextContent = async () => {
    try {
      const response = await fetch(
        `${baseURL}/workspaces/${workspaceId}/artifacts/raw/${artifactId}`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch text content");
      }
      const text = await response.text();
      setContent(text);
      setEditedContent(text);
    } catch (err) {
      console.error("Fetch error:", err);
      throw err;
    }
  };

  useEffect(() => {
    const loadContent = async () => {
      try {
        await fetchTextContent();
      } finally {
        setLoading(false);
      }
    };

    loadContent();
  }, [artifactId, workspaceId]);

  useEffect(() => {
    if (!isEditing) {
      setEditedContent(content);
    }
  }, [isEditing, content]);

  const handleContentChange = (newContent: string) => {
    setEditedContent(newContent);
    onModifiedChange(newContent !== content);
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const file = new File([editedContent], "content.txt", {
        type: "text/plain",
      });

      await updateArtifactContent(workspaceId, parseInt(artifactId), file);
      setContent(editedContent);
      onModifiedChange(false);
      toast({
        title: "Success",
        description: "Changes saved successfully.",
      });
    } catch (err) {
      toast({
        title: "Save Error",
        description: "Failed to save changes. Please try again.",
        variant: "destructive",
      });
      console.error("Save error:", err);
    } finally {
      setSaving(false);
    }
  };

  const handleExitEdit = () => {
    onEditStatusChange(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col h-full">
      {isEditing && (
        <div className="flex items-center gap-2 p-2 bg-gray-50 border-b">
          <Button
            size="sm"
            onClick={handleSave}
            disabled={saving}
            className={`flex items-center gap-1 ${saving ? "opacity-50" : ""}`}
          >
            <Save className="w-4 h-4" />
            {saving ? "Saving..." : "Save"}
          </Button>
          <Button
            size="sm"
            variant="ghost"
            onClick={handleExitEdit}
            disabled={saving}
            className="flex items-center gap-1"
          >
            <X className="w-4 h-4" />
            Exit Edit
          </Button>
        </div>
      )}
      {isEditing ? (
        <textarea
          value={editedContent}
          onChange={(e) => handleContentChange(e.target.value)}
          className="flex-grow p-4 font-mono text-sm resize-none focus:outline-none"
          spellCheck={false}
        />
      ) : (
        <div className="p-4 whitespace-pre-wrap font-mono text-sm overflow-auto">
          {content}
        </div>
      )}
    </div>
  );
};
