/* eslint-disable import/prefer-default-export */
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import "./emoji_list.css";
import { Command, CommandGroup, CommandItem } from "src/common/shadcn/command";

export const EmojiList = forwardRef(({ command, items }, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = useCallback(
    (index) => {
      const item = items[index];

      if (item) {
        command({ name: item.name });
      }
    },
    [command, items]
  );

  const upHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  }, [items, selectedIndex]);

  const downHandler = useCallback(() => {
    setSelectedIndex((selectedIndex + 1) % items.length);
  }, [items, selectedIndex]);

  const enterHandler = useCallback(() => {
    selectItem(selectedIndex);
  }, [selectItem, selectedIndex]);

  useEffect(() => setSelectedIndex(0), [items]);

  useImperativeHandle(
    ref,
    () => {
      return {
        onKeyDown: (x) => {
          if (x.event.key === "ArrowUp") {
            upHandler();
            return true;
          }

          if (x.event.key === "ArrowDown") {
            downHandler();
            return true;
          }

          if (x.event.key === "Enter") {
            enterHandler();
            return true;
          }

          return false;
        },
      };
    },
    [upHandler, downHandler, enterHandler]
  );

  return (
    <div className="emojiItems">
      {items.length !== 0 && (
        <Command>
          <CommandGroup>
            {items.map((item, index) => (
              <CommandItem
                key={index}
                onSelect={() => selectItem(index)}
                className={`emojiItem ${
                  index === selectedIndex ? "emojiItemSelected" : ""
                }`}
              >
                <span className="emojiIcon">
                  {item.fallbackImage ? (
                    <img src={item.fallbackImage} alt={item.name} />
                  ) : (
                    item.emoji
                  )}
                </span>
                <span>:{item.name}:</span>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      )}
    </div>
  );
});
