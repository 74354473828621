import React from "react";
import { Badge } from "src/common/shadcn/badge";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "src/common/shadcn/dropdown";

export const PriorityBadge = ({
  priority,
  onPriorityChange,
  disabled = false,
}: {
  priority: number;
  onPriorityChange: (value: number) => void;
  disabled?: boolean;
}) => {
  const getBadgeColor = (priority: number) => {
    switch (priority) {
      case 1:
        return "bg-red-500";
      case 2:
        return "bg-orange-500";
      case 3:
        return "bg-yellow-500";
      default:
        return "bg-gray-500";
    }
  };

  const getPriorityLabel = (priority: number) => {
    switch (priority) {
      case 1:
        return "High";
      case 2:
        return "Medium";
      case 3:
        return "Low";
      default:
        return "Unknown";
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <div className={`outline-none focus:ring-0 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
          <Badge
            variant="outline"
            className={`text-sm h-8 min-w-[100px] flex items-center justify-center rounded-md ${getBadgeColor(
              priority
            )} text-white ${disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:opacity-90'}`}
          >
            {getPriorityLabel(priority)}
          </Badge>
        </div>
      </DropdownMenuTrigger>
      {!disabled && (
        <DropdownMenuContent>
          {[1, 2, 3].map((p) => (
            <DropdownMenuItem key={p} onSelect={() => onPriorityChange(p)}>
              P{p} - {getPriorityLabel(p)}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  );
};
