import React from 'react';
import { Users, Building2, Box, Mail, AtSign, Ticket, Clock, MoreVertical } from "lucide-react";
import { Card, CardHeader, CardTitle, CardContent } from 'src/common/shadcn/card';
import { Button } from 'src/common/shadcn/button';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from 'src/common/shadcn/dropdown';
import { SubjectDTO, SubjectType } from 'src/usecase/core-subject-management/DTO/common/SubjectDTO';
import { TicketTag } from './TicketTag';
import { TicketDTO } from 'src/usecase/core-subject-management/DTO/common/TicketDTO';

interface SubjectCardProps {
  subject: SubjectDTO;
  showPastFooter?: boolean;
  showOpenTickets?: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onSubjectClick: () => void;
  isWritable: boolean;
}

export const SubjectCard = ({ 
  subject, 
  showPastFooter, 
  showOpenTickets,
  onEdit,
  onDelete,
  onSubjectClick,
  isWritable
}: SubjectCardProps) => {
  const getTypeConfig = (type: SubjectType) => {
    const configs = {
      person: {
        icon: <Users className="h-4 w-4" />,
        bgColor: 'bg-blue-100',
        textColor: 'text-blue-700',
        label: 'Individual'
      },
      company: {
        icon: <Building2 className="h-4 w-4" />,
        bgColor: 'bg-purple-100',
        textColor: 'text-purple-700',
        label: 'Company'
      },
      entity: {
        icon: <Box className="h-4 w-4" />,
        bgColor: 'bg-emerald-100',
        textColor: 'text-emerald-700',
        label: 'Entity'
      }
    };
    return configs[type];
  };

  const typeConfig = getTypeConfig(subject.type as SubjectType);

  return (
    <Card 
      className="group relative overflow-hidden transition-all duration-300 hover:shadow-lg bg-white border-0 ring-1 ring-gray-200 flex flex-col h-full cursor-pointer"
      onClick={onSubjectClick}
    >
      <CardHeader className="p-4 pb-3 flex-1">
        <div className="flex items-start justify-between gap-3">
          <div className="flex items-start gap-3 min-w-0">
            <div className={`shrink-0 p-2 rounded-lg ${typeConfig.bgColor} ${typeConfig.textColor}`}>
              {typeConfig.icon}
            </div>
            
            <div className="min-w-0 space-y-1">
              <div className="flex items-center gap-2 flex-wrap">
                <CardTitle className="text-sm font-semibold text-gray-900">
                  {subject.name}
                </CardTitle>
                <span className={`text-xs px-2 py-0.5 rounded-full ${typeConfig.bgColor} ${typeConfig.textColor} font-medium`}>
                  {typeConfig.label}
                </span>
              </div>
              
              <div className="flex flex-wrap items-center gap-3">
                <div className="flex items-center gap-1.5">
                  <AtSign className="h-3.5 w-3.5 text-gray-400" />
                  <span className="text-xs text-gray-600">
                    {subject.alias || 'No alias set'}
                  </span>
                </div>
                {subject.contact_email && (
                  <div className="flex items-center gap-1.5">
                    <Mail className="h-3.5 w-3.5 text-gray-400" />
                    <span className="text-xs text-gray-600">
                      {subject.contact_email}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button 
                variant="ghost" 
                size="sm" 
                className="h-8 w-8 p-0 opacity-0 group-hover:opacity-100 transition-opacity hover:bg-gray-100"
                onClick={(e) => e.stopPropagation()}
              >
                <MoreVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-32">
              <DropdownMenuItem 
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit();
                }}
                disabled={!isWritable}
              >
                Edit
              </DropdownMenuItem>
              <DropdownMenuItem 
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
                disabled={!isWritable}
                className="text-red-600 focus:text-red-600 data-[disabled]:text-red-300"
              >
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {subject.description && (
          <p className="text-xs text-gray-600 mt-2 whitespace-pre-wrap line-clamp-2">
            {subject.description}
          </p>
        )}
      </CardHeader>

      {showOpenTickets && subject.associated_open_tickets && subject.associated_open_tickets.length > 0 && (
        <CardContent className="border-t border-gray-100 px-4 py-1.5 bg-gray-50 ">
          <div className="flex flex-wrap gap-1.5">
            {subject.associated_open_tickets?.map((ticket) => (
                <TicketTag key={ticket.id} ticket={ticket} ongoing={true} />
            ))}
          </div>
        </CardContent>
      )}

      {showPastFooter && (
        <CardContent className="border-t border-gray-100 bg-gray-50 px-4 py-1.5 mt-auto">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-1">
                <Ticket className="h-3.5 w-3.5 text-gray-500" />
                <span className="text-xs font-medium text-gray-600">
                  {subject.total_ticket_count} total tickets
                </span>
              </div>
              <div className="flex items-center gap-1">
                <Clock className="h-3.5 w-3.5 text-gray-500" />
                <span className="text-xs font-medium text-gray-600">
                  {subject.last_activity_date ? new Date(subject.last_activity_date).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'short', 
                    day: '2-digit',
                  }) : 'No activity'}
                </span>
              </div>
            </div>
          </div>
        </CardContent>
      )}
    </Card>
  );
};