import React from 'react';
import Masonry from 'react-masonry-css';
import { SubjectDTO } from 'src/usecase/core-subject-management/DTO/common/SubjectDTO';
import { SubjectCard } from './SubjectCard';

interface SubjectSectionProps {
  title: string;
  subjects: SubjectDTO[];
  showOpenTickets?: boolean;
  showPastFooter?: boolean;
  onEdit: (subject: SubjectDTO) => void;
  onDelete: (subject: SubjectDTO) => void;
  onSubjectClick: (subjectId: number) => void;
  isWritable: boolean;
}

export const SubjectSection = ({ 
  title, 
  subjects, 
  showOpenTickets,
  showPastFooter, 
  onEdit,
  onDelete,
  onSubjectClick,
  isWritable
}: SubjectSectionProps) => {
  if (subjects.length === 0) return null;

  // Define breakpoints for responsive layout
  const breakpointColumns = {
    default: 4, // Default number of columns
    1536: 4,    // 2xl breakpoint
    1280: 3,    // xl breakpoint
    1024: 3,    // lg breakpoint
    768: 2,     // md breakpoint
    640: 2,     // sm breakpoint
    480: 1,     // xs breakpoint
  };

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold text-gray-900">
        {title}
      </h2>
      <Masonry
        breakpointCols={breakpointColumns}
        className="flex -ml-4 w-auto"
        columnClassName="pl-4 bg-clip-padding"
      >
        {subjects.map(subject => (
          <div key={subject.id} className="mb-4">
            <SubjectCard 
              subject={subject}
              showOpenTickets={showOpenTickets}
              showPastFooter={showPastFooter}
              onEdit={() => onEdit(subject)}
              onDelete={() => onDelete(subject)}
              onSubjectClick={() => onSubjectClick(subject.id)}
              isWritable={isWritable}
            />
          </div>
        ))}
      </Masonry>
    </div>
  );
}; 