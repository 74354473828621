import React, { useEffect, useState, useCallback } from 'react';
import { PlusCircle, ChevronDown, Settings } from "lucide-react";
import { Button } from 'src/common/shadcn/button';
import { Link, useLocation, useSearchParams, Navigate } from 'react-router-dom';
import { getMyWorkspaces, MyWorkspace, createWorkspace } from '../workspace/api/workspace-api';
import { useAlertDialog } from "src/common/hooks/useAlertDialog";
import { CreateWorkspaceDialog } from './CreateWorkspaceDialog';
import { EditWorkspaceDialog } from './EditWorkspaceDialog';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuPortal } from 'src/common/shadcn/dropdown';
import { useToast } from "src/common/shadcn/use-toast";

export const WorkspaceSwitcher: React.FC = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [workspaces, setWorkspaces] = useState<MyWorkspace[]>([]);
  const [currentWorkspace, setCurrentWorkspace] = useState<MyWorkspace | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [workspaceToEdit, setWorkspaceToEdit] = useState<MyWorkspace | null>(null);
  const { showAlert, AlertDialogComponent } = useAlertDialog();
  const { toast } = useToast();

  // Separate the workspace fetching from the current workspace selection
  const fetchWorkspaces = useCallback(async (skipLoadingState = false) => {
    try {
      if (!skipLoadingState) {
        setIsLoading(true);
      }
      const spaces = await getMyWorkspaces();
      setWorkspaces(spaces);
      return spaces;
    } catch (error) {
      console.error('Failed to fetch workspaces:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch workspaces. Please try again later."
      });
      return [];
    } finally {
      if (!skipLoadingState) {
        setIsLoading(false);
      }
    }
  }, []); // No dependencies needed for just fetching

  // Handle workspace selection separately
  const updateCurrentWorkspace = useCallback((spaces: MyWorkspace[]) => {
    if (!spaces) return;
    
    const wid = searchParams.get('wid');
    if (wid) {
      const current = spaces.find(w => w.id === parseInt(wid));
      if (current) {
        setCurrentWorkspace(current);
      } else if (spaces.length > 0) {
        setCurrentWorkspace(spaces[0]);
        setSearchParams(prev => {
          prev.set('wid', spaces[0].id.toString());
          return prev;
        }, { replace: true });
      } else {
        setCurrentWorkspace(null);
      }
    } else if (spaces.length > 0) {
      setCurrentWorkspace(spaces[0]);
      setSearchParams(prev => {
        prev.set('wid', spaces[0].id.toString());
        return prev;
      }, { replace: true });
    }
  }, [searchParams, setSearchParams]);

  // Initial load of workspaces
  useEffect(() => {
    fetchWorkspaces().then(spaces => {
      updateCurrentWorkspace(spaces);
    });
  }, []); // Only run on mount

  // Update current workspace when URL changes
  useEffect(() => {
    updateCurrentWorkspace(workspaces);
  }, [searchParams, workspaces, updateCurrentWorkspace]);

  const handleCreateWorkspace = async () => {
    const dialogState = {
      workspaceName: "",
      selectedEmoji: null as string | null
    };

    await showAlert({
      title: "Create New Workspace",
      description: "Enter workspace name and optionally select an emoji",
      confirmText: "Create",
      cancelText: "Cancel",
      content: (
        <CreateWorkspaceDialog
          name={dialogState.workspaceName}
          selectedEmoji={dialogState.selectedEmoji}
          onNameChange={(name) => { dialogState.workspaceName = name; }}
          onEmojiSelect={(emoji) => { dialogState.selectedEmoji = emoji; }}
        />
      ),
      onClose: async (confirmed: boolean) => {
        if (!confirmed || !dialogState.workspaceName.trim()) {
          return;
        }
        
        try {
          const name = dialogState.selectedEmoji 
            ? `${dialogState.selectedEmoji} ${dialogState.workspaceName}` 
            : dialogState.workspaceName;
            
          await createWorkspace({ name });
          await fetchWorkspaces().then(spaces => {
            updateCurrentWorkspace(spaces);
          });
        } catch (error) {
          console.error('Failed to create workspace:', error);
          toast({
            variant: "destructive",
            title: "Error",
            description: "Failed to create workspace. Please try again later."
          });
        }
      }
    });
  };

  if (isLoading) {
    return (
      <div className="p-2">
        <Button variant="outline" className="w-full justify-between" disabled>
          Loading...
        </Button>
      </div>
    );
  }

  // Redirect to create workspace page if no workspaces exist
  if (!isLoading && (!workspaces || workspaces.length === 0) && location.pathname !== '/create-workspace') {
    return <Navigate to="/create-workspace" replace />;
  }

  return (
    <div className="p-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            className="w-full justify-between text-left font-normal"
          >
            <span className="truncate">
              {currentWorkspace ? currentWorkspace.name : 'Select Workspace'}
            </span>
            <ChevronDown className="h-4 w-4 opacity-50" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-[--radix-dropdown-menu-trigger-width]">
          <div className="max-h-[300px] overflow-y-auto py-1">
            {workspaces && workspaces.length > 0 ? (
              workspaces.map((workspace) => (
                <DropdownMenuItem
                  key={workspace.id}
                  asChild
                >
                  <Link to={`/ticket?wid=${workspace.id}`} className="w-full">
                    <div className="flex justify-between items-center w-full">
                      <span className="font-medium truncate">{workspace.name}</span>
                      <span className="text-sm text-muted-foreground ml-2">{workspace.role}</span>
                    </div>
                  </Link>
                </DropdownMenuItem>
              ))
            ) : (
              <div className="px-2 py-4 text-sm text-center text-muted-foreground">
                No workspaces found
              </div>
            )}
          </div>
          <DropdownMenuSeparator />
          <DropdownMenuItem onSelect={handleCreateWorkspace}>
            <PlusCircle className="mr-2 h-4 w-4" />
            New Workspace
          </DropdownMenuItem>
          {currentWorkspace && (
            <DropdownMenuItem onSelect={() => {
              setWorkspaceToEdit(currentWorkspace);
              setShowEditDialog(true);
            }}>
              <Settings className="mr-2 h-4 w-4" />
              Edit Current Workspace
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      
      <AlertDialogComponent />
      
      {workspaceToEdit && (
        <EditWorkspaceDialog
          workspace={workspaceToEdit}
          isOpen={showEditDialog}
          onClose={() => {
            setShowEditDialog(false);
            setWorkspaceToEdit(null);
          }}
          onUpdate={() => {
            // Skip loading state for updates to prevent screen flash
            fetchWorkspaces(true).then(spaces => {
              updateCurrentWorkspace(spaces);
            });
          }}
        />
      )}
    </div>
  );
};
