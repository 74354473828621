import { useEffect, useState } from "react";
import { listArtifacts } from "../../core-subject-management/api/artifact-api";
import { ArtifactDTO } from "../../core-subject-management/DTO/common/ArtifactDTO";
import { listTickets } from "../../core-subject-management/api/ticket-api";
import { TicketDTO } from "../../core-subject-management/DTO/common/TicketDTO";
import { Button } from "src/common/shadcn/button";
import { ChevronRightIcon, ChevronDownIcon, FolderIcon, FileIcon } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/common/shadcn/select";
import { useSearchParams } from 'react-router-dom';

interface SelectArtifactDialogProps {
  ticketId?: number;
  onSelect: (artifactId: number) => void;
  showTicketSelector?: boolean;
}

interface ArtifactNode {
  id: number;
  title: string;
  description: string;
  children: ArtifactNode[];
  parentId: number | null;
  isFolder: boolean;
}

export const SelectArtifactDialog = ({ ticketId: initialTicketId, onSelect, showTicketSelector = false }: SelectArtifactDialogProps) => {
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [selectedTicketId, setSelectedTicketId] = useState<number | null>(initialTicketId || null);
  const [tickets, setTickets] = useState<TicketDTO[]>([]);
  const [artifacts, setArtifacts] = useState<ArtifactDTO[]>([]);
  const [expandedNodes, setExpandedNodes] = useState<Set<number>>(new Set());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const workspaceId = parseInt(searchParams.get('wid') || '0');

  useEffect(() => {
    const fetchTickets = async () => {
      if (!showTicketSelector) return;
      try {
        const response = await listTickets(workspaceId);
        setTickets(response);
      } catch (err) {
        console.error("Error fetching tickets:", err);
      }
    };

    fetchTickets();
  }, [showTicketSelector, workspaceId]);

  useEffect(() => {
    const fetchArtifacts = async () => {
      if (!selectedTicketId) return;
      try {
        setIsLoading(true);
        const response = await listArtifacts(workspaceId, { ticket_id: selectedTicketId });
        setArtifacts(response.artifacts);
        setError(null);
      } catch (err) {
        setError("Failed to load artifacts. Please try again.");
        console.error("Error fetching artifacts:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArtifacts();
  }, [selectedTicketId, workspaceId]);

  const buildArtifactTree = (artifacts: ArtifactDTO[]): ArtifactNode[] => {
    const artifactMap = new Map<number, ArtifactNode>();
    const rootNodes: ArtifactNode[] = [];

    // First pass: create nodes
    artifacts.forEach((artifact) => {
      artifactMap.set(artifact.id, {
        id: artifact.id,
        title: artifact.artifact_title,
        description: artifact.artifact_description,
        children: [],
        parentId: artifact.parent_folder_id,
        isFolder: artifact.is_folder
      });
    });

    // Second pass: build tree structure
    artifacts.forEach((artifact) => {
      const node = artifactMap.get(artifact.id)!;
      if (artifact.parent_folder_id === null) {
        rootNodes.push(node);
      } else {
        const parentNode = artifactMap.get(artifact.parent_folder_id);
        if (parentNode) {
          parentNode.children.push(node);
        }
      }
    });

    return rootNodes;
  };

  const toggleNode = (nodeId: number) => {
    setExpandedNodes((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(nodeId)) {
        newSet.delete(nodeId);
      } else {
        newSet.add(nodeId);
      }
      return newSet;
    });
  };

  const handleSelect = (artifactId: number) => {
    setSelectedId(artifactId);
    onSelect(artifactId);
  };

  const handleClick = (node: ArtifactNode, e: React.MouseEvent) => {
    handleSelect(node.id);

    // Handle double click
    const currentTime = new Date().getTime();
    const lastClickTime = (e.target as any).lastClickTime || 0;
    (e.target as any).lastClickTime = currentTime;

    if (currentTime - lastClickTime < 300) {
      if (node.isFolder) {
        toggleNode(node.id);
      }
    }
  };

  const renderArtifactNode = (node: ArtifactNode, depth: number = 0) => {
    const isExpanded = expandedNodes.has(node.id);
    const isSelected = selectedId === node.id;

    return (
      <div key={node.id} style={{ marginLeft: `${depth * 20}px` }}>
        <div
          className={`flex items-center py-1 px-2 cursor-pointer hover:bg-gray-100 select-none ${
            isSelected ? "bg-blue-100" : ""
          }`}
          onClick={(e) => handleClick(node, e)}
        >
          <Button
            variant="ghost"
            size="sm"
            className="mr-1 shrink-0"
            onClick={(e) => {
              e.stopPropagation();
              toggleNode(node.id);
            }}
          >
            {node.children.length > 0 ? (
              isExpanded ? (
                <ChevronDownIcon className="h-4 w-4" />
              ) : (
                <ChevronRightIcon className="h-4 w-4" />
              )
            ) : (
              <span className="w-4" />
            )}
          </Button>
          {node.isFolder ? (
            <FolderIcon className="h-4 w-4 mr-2 shrink-0" />
          ) : (
            <FileIcon className="h-4 w-4 mr-2 shrink-0" />
          )}
          <div>{node.title}</div>
        </div>
        {isExpanded && node.children.length > 0 && (
          <div>
            {node.children.map((child) => renderArtifactNode(child, depth + 1))}
          </div>
        )}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin h-6 w-6 border-2 border-primary border-t-transparent rounded-full" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-center text-red-500">
        {error}
      </div>
    );
  }

  const artifactTree = buildArtifactTree(artifacts);

  return (
    <div className="space-y-4">
      {showTicketSelector && (
        <Select
          value={selectedTicketId?.toString()}
          onValueChange={(value) => setSelectedTicketId(Number(value))}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select a ticket" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>Open Tickets</SelectLabel>
              {tickets
                .filter((ticket) => ticket.terminal_status === "open")
                .map((ticket) => (
                  <SelectItem key={ticket.id} value={ticket.id.toString()}>
                    {`#${ticket.id} - ${ticket.title}`}
                  </SelectItem>
                ))}
            </SelectGroup>
            <SelectGroup>
              <SelectLabel>All Tickets</SelectLabel>
              {tickets
                .filter((ticket) => ticket.terminal_status !== "open")
                .map((ticket) => (
                  <SelectItem key={ticket.id} value={ticket.id.toString()}>
                    {`#${ticket.id} - ${ticket.title}`}
                  </SelectItem>
                ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      )}
      
      {selectedTicketId && (
        <div className="max-h-96 overflow-y-auto border rounded">
          <div className="p-4">
            {artifactTree.map((node) => renderArtifactNode(node))}
            {artifactTree.length === 0 && (
              <div className="text-center text-gray-500">
                No artifacts found for this ticket
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
