import React from "react";
import { Badge } from "src/common/shadcn/badge";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "src/common/shadcn/dropdown";
import { getStatusColor } from "../common";

export const CurrentStatusSelect = ({
  status,
  onStatusChange,
  disabled = false,
}: {
  status: string;
  onStatusChange: (value: string) => void;
  disabled?: boolean;
}) => {
  const statuses = [
    "blocked",
    "waiting",
    "pending",
    "ongoing",
    "resolved",
    "failed",
    "cancelled",
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <div className={`outline-none focus:ring-0 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
          <Badge
            variant="outline"
            className={`text-sm h-8 min-w-[100px] flex items-center justify-center rounded-md ${getStatusColor(
              status
            )} text-white ${disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:opacity-90'}`}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </Badge>
        </div>
      </DropdownMenuTrigger>
      {!disabled && (
        <DropdownMenuContent>
          {statuses.map((s) => (
            <DropdownMenuItem key={s} onSelect={() => onStatusChange(s)}>
              {s.charAt(0).toUpperCase() + s.slice(1)}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  );
};
