import React, { useState } from 'react';
import { Input } from 'src/common/shadcn/input';
import { Label } from 'src/common/shadcn/label';
import { Button } from 'src/common/shadcn/button';
import { ScrollArea } from 'src/common/shadcn/scroll-area';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/common/shadcn/card";
import { useToast } from "src/common/shadcn/use-toast";
import { useWorkspaceNavigate } from 'src/common/hooks/useWorkspaceNavigate';
import { createWorkspace } from '../workspace/api/workspace-api';

const EMOJI_LIST = [
  '🏠', '🏢', '🏗️', '🏭', '🏬', '🏫', '🏪', '🏥',
  '📚', '💻', '🖥️', '📱', '⌨️', '🖱️', '🖨️', '📷',
  '📹', '🎥', '📺', '📻', '📟', '📞', '📠', '🔋',
  '🔌', '💡', '🔦', '🕯️', '📖', '📗', '📘', '📙',
];

export const CreateWorkspacePage = () => {
  const { toast } = useToast();
  const navigate = useWorkspaceNavigate();
  const [name, setName] = useState('');
  const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!name.trim()) {
      toast({
        title: "Workspace name is required",
        variant: "destructive",
      });
      return;
    }

    try {
      const workspaceName = selectedEmoji 
        ? `${selectedEmoji} ${name}` 
        : name;
      
      await createWorkspace({ name: workspaceName });
      toast({
        title: "Workspace created successfully",
      });
      navigate("/");
    } catch (error) {
      toast({
        title: "Failed to create workspace",
        description: error instanceof Error ? error.message : "Unknown error occurred",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-900 to-indigo-900">
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1">
          <div className="flex justify-center mb-4">
            <img src="/logo-full.png" alt="Subject Manager" className="h-12" />
          </div>
          <CardTitle className="text-2xl font-bold text-center">
            Create Your First Workspace
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="workspace-name">Workspace Name</Label>
              <Input
                id="workspace-name"
                placeholder="Enter workspace name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                autoFocus
              />
            </div>
            
            <div className="space-y-2">
              <Label>Emoji (Optional)</Label>
              <ScrollArea className="h-[200px] w-full rounded-md border">
                <div className="grid grid-cols-8 gap-2 p-2">
                  {EMOJI_LIST.map((emoji) => (
                    <Button
                      key={emoji}
                      variant={selectedEmoji === emoji ? "secondary" : "ghost"}
                      className="h-8 w-8 p-0 hover:bg-gray-100"
                      onClick={() => setSelectedEmoji(emoji)}
                      type="button"
                    >
                      {emoji}
                    </Button>
                  ))}
                </div>
              </ScrollArea>
            </div>

            <Button type="submit" className="w-full">
              Create Workspace
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default CreateWorkspacePage;