import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Card } from "src/common/shadcn/card";
import { ScrollArea } from "src/common/shadcn/scroll-area";
import {
  getSubjectWithTicketsAndEvents,
  updateSubject,
} from "../core-subject-management/api/subject-api";
import { SubjectDTO, SubjectType } from "../core-subject-management/DTO/common/SubjectDTO";
import { TicketSection } from "../ticket-manager/components/TicketSection";
import { useWorkspaceNavigate } from 'src/common/hooks/useWorkspaceNavigate';
import { UpdateSubjectRequest } from "../core-subject-management/DTO/subject/UpdateSubjectRequest";
import { SubjectDetails } from "./components/SubjectDetailViewComponent/SubjectDetails";
import { SubjectHeader } from "./components/SubjectDetailViewComponent/SubjectHeader";
import { SubjectSkeleton } from "./components/SubjectDetailViewComponent/SubjectSkeleton";
import { useDebounce } from "src/common/hooks/useDebounce";
import { getMyWorkspaceRole } from "../workspace/api/workspace-api";
import { WorkspaceRole } from "../workspace/DTO/workspace_user/WorkspaceUserDTO";

export const SubjectDetailView = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const workspaceId = parseInt(searchParams.get('wid') || '0');
  const navigate = useWorkspaceNavigate();
  const [subject, setSubject] = useState<SubjectDTO | null>(null);
  const [loading, setLoading] = useState(true);

  const [myRole, setMyRole] = useState<WorkspaceRole | null>(null);

  const isWritable = myRole === WorkspaceRole.Owner || myRole === WorkspaceRole.Member;

  useEffect(() => {
    const fetchMyRole = async () => {
      if (!workspaceId) return;
      try {
        const roleData = await getMyWorkspaceRole(workspaceId);
        setMyRole(roleData?.role || null);
      } catch (error) {
        console.error("Failed to fetch user role:", error);
      }
    };

    fetchMyRole();
  }, [workspaceId]);

  useEffect(() => {
    const fetchSubject = async () => {
      if (!workspaceId || !id) return;
      try {
        const data = await getSubjectWithTicketsAndEvents(workspaceId, parseInt(id));

        // Loop through the associated_open_tickets and associated_tickets and set the subject_id to the id of the subject
        if (data.associated_open_tickets) {
          data.associated_open_tickets.forEach((ticket) => {
            ticket.associated_subject = data;
          });
        }
        if (data.associated_tickets) {
          data.associated_tickets.forEach((ticket) => {
            ticket.associated_subject = data;
          });
        }
        // if (data.associated_events) {
        //   data.associated_events.forEach((event) => {
        //     event.associated_subject = data;
        //   });
        // }

        setSubject(data);
      } catch (error:any) {
        if (error.response.status === 404) {
          setSubject(null);
        }
        console.error("Failed to fetch subject:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubject();
  }, [workspaceId, id]);

  const debouncedUpdate = useDebounce(async (
    field: keyof UpdateSubjectRequest,
    value: any,
    subjectId: number,
    currentSubject: SubjectDTO
  ) => {
    try {
      const updateData: UpdateSubjectRequest = {
        name: currentSubject.name,
        alias: currentSubject.alias,
        type: currentSubject.type as SubjectType,
        description: currentSubject.description,
        remark: currentSubject.remark,
        contact_address: currentSubject.contact_address,
        contact_email: currentSubject.contact_email,
        contact_phone: currentSubject.contact_phone,
        contact_wechat: currentSubject.contact_wechat,
        contact_whatsapp: currentSubject.contact_whatsapp,
        [field]: value,
      };

      await updateSubject(workspaceId, subjectId, updateData);
      const refreshedSubject = await getSubjectWithTicketsAndEvents(workspaceId, subjectId);

      // Loop through the associated_open_tickets and associated_tickets and set the subject_id to the id of the subject
      if (refreshedSubject.associated_open_tickets) {
        refreshedSubject.associated_open_tickets.forEach((ticket) => {
          ticket.associated_subject = refreshedSubject;
        });
      }
      if (refreshedSubject.associated_tickets) {
        refreshedSubject.associated_tickets.forEach((ticket) => {
          ticket.associated_subject = refreshedSubject;
        });
      }

      // if (refreshedSubject.associated_events) {
      //   refreshedSubject.associated_events.forEach((event) => {
      //     event.associated_subject = refreshedSubject;
      //   });
      // }

      setSubject(refreshedSubject);
    } catch (error) {
      console.error(`Failed to update subject ${field}:`, error);
    }
  }, 500);

  const handleSubjectUpdate = (field: keyof UpdateSubjectRequest, value: any) => {
    if (!subject || !workspaceId || !id) return;
    
    // Update local state immediately
    setSubject(prev => {
      if (!prev) return prev;
      return { ...prev, [field]: value };
    });

    // Debounce the API call
    debouncedUpdate(field, value, parseInt(id), subject);
  };

  const handleTicketClick = (ticketId: number) => {
    navigate(`/ticket/${ticketId}`);
  };

  const refreshSubject = async () => {
    if (!id) return;
    try {
      const data = await getSubjectWithTicketsAndEvents(workspaceId, parseInt(id));

      // Loop through the associated_open_tickets and associated_tickets and set the subject_id to the id of the subject
      if (data.associated_open_tickets) {
        data.associated_open_tickets.forEach((ticket) => {
          ticket.associated_subject = data;
        });
      }
      if (data.associated_tickets) {
        data.associated_tickets.forEach((ticket) => {
          ticket.associated_subject = data;
        });
      }

      setSubject(data);
    } catch (error) {
      console.error("Failed to fetch subject:", error);
    }
  };

  if (loading) return <SubjectSkeleton />;
  if (!subject) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <div className="text-gray-500">Subject not found</div>
      </div>
    );
  }

  return (
    <ScrollArea className="h-full w-full">
      <Card className="mx-auto max-w-5xl border-0 shadow-md bg-white">
        <SubjectHeader
          subject={subject}
          onTypeChange={(value) => handleSubjectUpdate("type", value)}
          onNameChange={(value) => handleSubjectUpdate("name", value)}
          onTicketCreated={refreshSubject}
          onSubjectUpdated={refreshSubject}
          isWritable={isWritable}
        />
        <div className="p-6 space-y-4">
          <SubjectDetails
            subject={subject}
            handleUpdate={handleSubjectUpdate as (field: string, value: any) => void}
            isWritable={isWritable}
          />

          {/* Open Tickets Section */}
          <div className="mt-6">
            <TicketSection
              title="🔥 Open Tickets"
              tickets={subject.associated_open_tickets || []}
              isOpen={true}
              onTicketClick={handleTicketClick}
              workspaceId={workspaceId}
              viewType="list"
            />
          </div>

          {/* All Tickets Section */}
          <div className="mt-0">
            <TicketSection
              title="📋 All Tickets"
              tickets={subject.associated_tickets || []}
              onTicketClick={handleTicketClick}
              workspaceId={workspaceId}
              viewType="list"
            />
          </div>
        </div>
      </Card>
    </ScrollArea>
  );
};
