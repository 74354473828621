export enum WorkspaceRole {
    Owner = "owner",
    Member = "member",
    Reader = "reader"
}

export interface WorkspaceUserDTO {
    id: number;
    workspace_id: number;
    user_id: number;
    role: WorkspaceRole;
    created_at: string;
    updated_at: string;
}
