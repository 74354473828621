import { useCallback, useRef, useState, ReactNode } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "src/common/shadcn/alert-dialog";

interface AlertDialogProps {
  title: string;
  description: string;
  cancelText?: string;
  confirmText?: string;
  content?: ReactNode;
  onClose?: (value: any) => void;
}

export const useAlertDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<AlertDialogProps | null>(null);
  const resolveRef = useRef<(value: any) => void>();

  const showAlert = useCallback((props: AlertDialogProps) => {
    return new Promise<any>((resolve) => {
      resolveRef.current = resolve;
      setDialogProps(props);
      setIsOpen(true);
    });
  }, []);

  const handleAction = useCallback(
    (value: any) => {
      setIsOpen(false);
      if (dialogProps?.onClose) {
        dialogProps.onClose(value);
      }
      resolveRef.current?.(value);
    },
    [dialogProps]
  );

  const AlertDialogComponent = useCallback(() => {
    if (!dialogProps) return null;

    return (
      <AlertDialog
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) handleAction(undefined);
        }}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{dialogProps.title}</AlertDialogTitle>
            <AlertDialogDescription>
              {dialogProps.description}
            </AlertDialogDescription>
          </AlertDialogHeader>
          {dialogProps.content}
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => handleAction(false)}>
              {dialogProps.cancelText || "Cancel"}
            </AlertDialogCancel>
            <AlertDialogAction onClick={() => handleAction(true)}>
              {dialogProps.confirmText || "Proceed"}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }, [isOpen, dialogProps, handleAction]);

  return {
    showAlert,
    AlertDialogComponent,
  };
};
