import { ArtifactDTO } from "src/usecase/core-subject-management/DTO/common/ArtifactDTO";
import { PDFArtifactView } from "./PDFArtifactView";
import { TxtArtifactView } from "./TxtArtifactView";
import { ImageArtifactView } from "./ImageArtifactView";
import { AudioArtifactView } from "./AudioArtifactView";
import { VideoArtifactView } from "./VideoArtifactView";
import { RichtextArtifactView } from "./RichtextArtifactView";
import { DocxArtifactView } from "./DocxArtifactView";
import { XlsxArtifactView } from "./XlsxArtifactView";
import { PptxArtifactView } from "./PptxArtifactView";
import { FileText, Folder } from "lucide-react";

interface RenderArtifactResult {
  component: JSX.Element;
  isEditable: boolean;
}

export const renderArtifactContent = (
  artifact: ArtifactDTO | null,
  isEditing: boolean,
  onEditStatusChange: (status: boolean) => void,
  isModified: boolean,
  onModifiedChange: (isModified: boolean) => void,
  workspaceId: number
): RenderArtifactResult => {
  if (!artifact) {
    return { component: <></>, isEditable: false };
  }

  if (artifact.is_folder) {
    return {
      component: (
        <div className="flex flex-col items-center justify-center h-full text-gray-500">
          <Folder className="w-16 h-16 mb-4" />
          <p>This is a folder. Contents cannot be displayed.</p>
        </div>
      ),
      isEditable: false,
    };
  }

  const fileExtension = artifact.artifact_file_name
    .split(".")
    .slice(-1)[0]
    ?.toLowerCase();

  switch (fileExtension) {
    case "pdf":
      return {
        component: <PDFArtifactView artifactId={artifact.id.toString()} workspaceId={workspaceId} />,
        isEditable: false,
      };
    case "pptx":
      return {
        component: <PptxArtifactView artifactId={artifact.id.toString()} workspaceId={workspaceId} />,
        isEditable: false,
      };
    case "docx":
      return {
        component: <DocxArtifactView artifactId={artifact.id.toString()} workspaceId={workspaceId} />,
        isEditable: false,
      };
    case "xlsx":
      return {
        component: <XlsxArtifactView artifactId={artifact.id.toString()} workspaceId={workspaceId} />,
        isEditable: false,
      };
    case "txt":
    case "log":
    case "json":
    case "xml":
    case "solartext":
      return {
        component: (
          fileExtension === "solartext" ? (
            <RichtextArtifactView
              artifactId={artifact.id.toString()}
              workspaceId={workspaceId}
              isEditing={isEditing}
              onEditStatusChange={onEditStatusChange}
              isModified={isModified}
              onModifiedChange={onModifiedChange}
            />
          ) : (
            <TxtArtifactView
              artifactId={artifact.id.toString()}
              workspaceId={workspaceId}
              isEditing={isEditing}
              onEditStatusChange={onEditStatusChange}
              isModified={isModified}
              onModifiedChange={onModifiedChange}
            />
          )
        ),
        isEditable: true,
      };
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
    case "svg":
      return {
        component: <ImageArtifactView artifactId={artifact.id.toString()} workspaceId={workspaceId} />,
        isEditable: false,
      };
    case "mp3":
    case "wav":
    case "ogg":
      return {
        component: <AudioArtifactView artifactId={artifact.id.toString()} workspaceId={workspaceId} />,
        isEditable: false,
      };
    case "mp4":
    case "webm":
    case "mov":
      return {
        component: <VideoArtifactView artifactId={artifact.id.toString()} workspaceId={workspaceId} />,
        isEditable: false,
      };
    default:
      return {
        component: (
          <div className="flex flex-col items-center justify-center h-full text-gray-500">
            <FileText className="w-16 h-16 mb-4" />
            <p>This file type is not supported yet.</p>
          </div>
        ),
        isEditable: false,
      };
  }
};
