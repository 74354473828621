import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { Button } from "src/common/shadcn/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/common/shadcn/popover";
import { Calendar } from "src/common/shadcn/calendar";

export const DueDatePicker = ({
  value,
  onChange,
  disabled = false,
}: {
  value?: string | null;
  onChange: (date: Date | undefined) => void;
  disabled?: boolean;
}) => (
  <div>
    <Popover>
      <PopoverTrigger asChild disabled={disabled}>
        <Button
          variant="outline"
          className={`w-full mt-1.5 justify-start text-left font-normal border-transparent focus:border-gray-300 shadow-none ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={disabled}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value ? format(new Date(value), "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      {!disabled && (
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={value ? new Date(value) : undefined}
            onSelect={onChange}
            className="rounded-md border"
          />
        </PopoverContent>
      )}
    </Popover>
  </div>
);
