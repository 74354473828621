import { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useWorkspaceNavigate } from 'src/common/hooks/useWorkspaceNavigate';
import {
  getTicket,
  updateTicket,
} from "../core-subject-management/api/ticket-api";
import { TicketDTO } from "../core-subject-management/DTO/common/TicketDTO";
import { UpdateTicketRequest } from "../core-subject-management/DTO/ticket/UpdateTicketRequest";
import { ScrollArea } from "src/common/shadcn/scroll-area";
import { Card } from "src/common/shadcn/card";
import { TicketDetails } from "./components/TicketDetailViewComponent/TicketDetails";
import { TicketHeader } from "./components/TicketDetailViewComponent/TicketHeader";
import { TicketMetadata } from "./components/TicketDetailViewComponent/TicketMetadata";
import { TicketSkeleton } from "./components/TicketDetailViewComponent/TicketSkeleton";
import { TicketFileExplorer, TicketFileExplorerRef } from "./components/TicketDetailViewComponent/TicketFileExplorer";
import { useDebounce } from "src/common/hooks/useDebounce";
import { ArtifactSidebar } from "./components/ArtifactSidebar";
import { ThreadsView } from "./components/TicketDetailViewComponent/ThreadsView";
import { useAlertDialog } from "src/common/hooks/useAlertDialog";
import { Button } from "src/common/shadcn/button";
import { SelectArtifactDialog } from "./components/SelectArtifactDialog";
import { GetInputDialog } from "./components/GetInputDialog";
import { getMyWorkspaceRole } from "../workspace/api/workspace-api";
import { WorkspaceRole, WorkspaceUserDTO } from "../workspace/DTO/workspace_user/WorkspaceUserDTO";

export const TicketDetailView = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const workspaceId = parseInt(searchParams.get('wid') || '0');
  const [ticket, setTicket] = useState<TicketDTO | null>(null);
  const [loading, setLoading] = useState(true);
  const [savingStatus, setSavingStatus] = useState<'idle' | 'saving' | 'saved'>('idle');
  const navigate = useWorkspaceNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedArtifactId, setSelectedArtifactId] = useState<number | null>(null);
  const fileExplorerRef = useRef<TicketFileExplorerRef>(null);
  const [myRole, setMyRole] = useState<WorkspaceRole | null>(null);

  const isWritable = myRole === WorkspaceRole.Owner || myRole === WorkspaceRole.Member;

  useEffect(() => {
    const fetchTicket = async () => {
      if (!id || !workspaceId) return;
      try {
        const data = await getTicket(workspaceId, parseInt(id));
        setTicket(data);
      } catch (error) {
        console.error("Failed to fetch ticket:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTicket();
  }, [id, workspaceId]);

  useEffect(() => {
    const fetchMyRole = async () => {
      if (!workspaceId) return;
      try {
        const roleData = await getMyWorkspaceRole(workspaceId);
        setMyRole(roleData?.role || null);
      } catch (error) {
        console.error("Failed to fetch user role:", error);
      }
    };

    fetchMyRole();
  }, [workspaceId]);

  const debouncedUpdate = useDebounce(
    async (
      field: keyof UpdateTicketRequest,
      value: any,
      ticketId: number,
      currentTicket: TicketDTO
    ) => {
      try {
        setSavingStatus('saving');

        const updateData: UpdateTicketRequest = {
          title: currentTicket.title,
          description: currentTicket.description,
          current_status: currentTicket.current_status,
          terminal_status: currentTicket.terminal_status,
          priority: currentTicket.priority,
          due_date: currentTicket.due_date || undefined,
          pic_user_id: currentTicket.pic_user_id,
          [field]: value,
        };

        await updateTicket(workspaceId, ticketId, updateData);

        setSavingStatus('saved');
        setTimeout(() => setSavingStatus('idle'), 2000);

        // Refresh the ticket if field is not description
        if (field !== "description") {
          const refreshedTicket = await getTicket(workspaceId, ticketId);
          setTicket(refreshedTicket);
        }
      } catch (error) {
        console.error(`Failed to update ticket ${field}:`, error);
      }
    },
    500
  );

  const handleUpdate = async (field: keyof UpdateTicketRequest, value: any) => {
    if (!ticket || !id) return;

    // Optimistically update the UI if field is not description
    if (field !== "description") {
      setTicket((prev) => (prev ? { ...prev, [field]: value } : null));
    } else {
      setSavingStatus('saving');
    }

    // Debounced API call
    debouncedUpdate(field, value, parseInt(id), ticket);
  };

  const handleSubjectClick = (subjectId: number) => {
    navigate(`/subject/${subjectId}`);
  };

  const handleArtifactSelect = useCallback((artifactId: number) => {
    setSelectedArtifactId(artifactId);
    setIsSidebarOpen(true);
  }, [setSelectedArtifactId, setIsSidebarOpen]);

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
    setSelectedArtifactId(null);
  };

  const {  showAlert,
    AlertDialogComponent,} = useAlertDialog();

  const showSelectArtifactDialog = useCallback(async (isForCopy: boolean = false) => {
    if (!ticket) return;

    let finalSelectedArtifactId = null;

    await showAlert({
      title: isForCopy ? "Copy From Existing Ticket" : "Artifact Selection",
      description: isForCopy ? "Select an artifact to copy" : "Select an existing artifact",
      content: (
        <SelectArtifactDialog
          ticketId={ticket.id}
          onSelect={(artifactId: number) => finalSelectedArtifactId = artifactId}
          showTicketSelector={isForCopy}
        />
      ),
    })

    if (finalSelectedArtifactId) {
      return finalSelectedArtifactId
    }
  }, [showAlert, ticket])

  const showGetInputDialog = useCallback(async (config: {
    title?: string;
    label?: string;
    placeholder?: string;
  }) => {
    let inputValue = "";

    await showAlert({
      title: config.title || "Enter Input",
      description: "",
      content: (
        <GetInputDialog
          title={config.title}
          label={config.label}
          placeholder={config.placeholder}
          onChange={(value) => {
            inputValue = value;
          }}
        />
      ),
    });

    return inputValue;
  }, [showAlert]);

  const handleInsertNewEmptyArtifact = useCallback(async (extension: string) => {
    const fileNamePart = await showGetInputDialog({
      title: "New Artifact",
      label: "Enter file name",
      placeholder: "Enter file name (without extension)"
    });
    
    if (!fileNamePart) return null;
    
    const result = await fileExplorerRef.current?.handleCreateFile(
      fileNamePart,
      extension,
      null // parent folder id
    );
    
    return result || null;
  }, [showGetInputDialog, fileExplorerRef]);

  if (!ticket) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <div className="text-gray-500">Ticket not found</div>
      </div>
    );
  }

  return (
    <div className="flex h-full">
      <ScrollArea
        className={`h-full ${
          isSidebarOpen ? "w-1/2" : "w-full"
        } transition-all duration-300`}
      >
        <div className="mx-auto max-w-4xl space-y-8 p-6">
          <Card className="border-0 shadow-md bg-white">
            <TicketHeader
              ticket={ticket}
              onPriorityChange={(value) => handleUpdate("priority", value)}
              onTerminalStatusChange={(value) =>
                handleUpdate("terminal_status", value)
              }
              onCurrentStatusChange={(value) =>
                handleUpdate("current_status", value)
              }
              isWritable={isWritable}
              workspaceId={workspaceId}
            />
            <div className="p-6 space-y-8">
              <TicketDetails
                ticket={ticket}
                onSubjectClick={handleSubjectClick}
                handleUpdate={handleUpdate}
                savingStatus={savingStatus}
                fileExplorerRef={fileExplorerRef}
                handleArtifactSelect={handleArtifactSelect}
                showSelectArtifactDialog={showSelectArtifactDialog}
                handleInsertNewEmptyArtifact={handleInsertNewEmptyArtifact}
                workspaceId={workspaceId}
                isWritable={isWritable}
              />
              <TicketMetadata 
                ticket={ticket} 
                handleUpdate={handleUpdate} 
                isWritable={isWritable}
              />
            </div>
          </Card>
          <TicketFileExplorer
            ref={fileExplorerRef}
            ticketId={ticket.id}
            onArtifactSelect={handleArtifactSelect}
            showSelectArtifactDialog={showSelectArtifactDialog}
            isWritable={isWritable}
          />
          <ThreadsView 
            ticketId={ticket.id} 
            fileExplorerRef={fileExplorerRef} 
            handleArtifactSelect={handleArtifactSelect} 
            showSelectArtifactDialog={showSelectArtifactDialog}
            handleInsertNewEmptyArtifact={handleInsertNewEmptyArtifact}
            isWritable={isWritable}
          />
        </div>
      </ScrollArea>
      <ArtifactSidebar
        isOpen={isSidebarOpen}
        artifactId={selectedArtifactId}
        onClose={handleCloseSidebar}
        isWritable={isWritable}
      />
      <AlertDialogComponent />
    </div>
  );
};
