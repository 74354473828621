import { Plus } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { useWorkspaceNavigate } from 'src/common/hooks/useWorkspaceNavigate';
import { Button } from "src/common/shadcn/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "src/common/shadcn/dialog";
import { toast } from "src/common/shadcn/use-toast";
import { TicketForm } from "./TicketForm";
import { TicketSection } from "./components/TicketSection";
import {
  createTicket,
  listTickets,
  deleteTicket,
} from "../core-subject-management/api/ticket-api";
import { TicketDTO } from "../core-subject-management/DTO/common/TicketDTO";
import { SubjectDTO } from "../core-subject-management/DTO/common/SubjectDTO";
import { CreateTicketRequest } from "../core-subject-management/DTO/ticket/CreateTicketRequest";
import { listSubjectsWithOpenTicketsAndLastEvent } from "../core-subject-management/api/subject-api";
import { WorkspaceRole } from "../workspace/DTO/workspace_user/WorkspaceUserDTO";
import { getMyWorkspaceRole } from "../workspace/api/workspace-api";
import { useAlertDialog } from "src/common/hooks/useAlertDialog";

const TicketHomeView = () => {
  const [searchParams] = useSearchParams();
  const workspaceIdStr = searchParams.get('wid') || undefined;
  const workspaceId = workspaceIdStr ? parseInt(workspaceIdStr) : undefined;
  const navigate = useWorkspaceNavigate();
  const [tickets, setTickets] = useState<TicketDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isNewTicketModalOpen, setIsNewTicketModalOpen] = useState(false);
  const [subjects, setSubjects] = useState<SubjectDTO[]>([]);

  const [myRole, setMyRole] = useState<WorkspaceRole | null>(null);

  const isWritable = myRole === WorkspaceRole.Owner || myRole === WorkspaceRole.Member;

  useEffect(() => {
    const fetchMyRole = async () => {
      if (!workspaceId) return;
      try {
        const roleData = await getMyWorkspaceRole(workspaceId);
        setMyRole(roleData?.role || null);
      } catch (error) {
        console.error("Failed to fetch user role:", error);
      }
    };

    fetchMyRole();
  }, [workspaceId]);

  const fetchTickets = useCallback(async () => {
    if (!workspaceId) {
      setError('No workspace ID provided');
      setLoading(false);
      return;
    }

    try {
      const response = await listTickets(workspaceId);
      setTickets(response);
      setError(null);
    } catch (err) {
      setError("Failed to load tickets");
      toast({
        title: "Error",
        description: "Failed to load tickets",
        variant: "destructive",
      });
      console.error("Error fetching tickets:", err);
    } finally {
      setLoading(false);
    }
  }, [workspaceId]);

  const fetchSubjects = useCallback(async () => {
    if (!workspaceId) {
      return;
    }

    try {
      const response = await listSubjectsWithOpenTicketsAndLastEvent(workspaceId);
      setSubjects(response.subjects || []);
    } catch (err) {
      console.error("Error fetching subjects:", err);
      toast({
        title: "Error",
        description: "Failed to load subjects",
        variant: "destructive",
      });
    }
  }, [workspaceId]);

  useEffect(() => {
    Promise.all([fetchTickets(), fetchSubjects()]);
  }, [fetchTickets, fetchSubjects]);

  const handleCreateTicket = useCallback(async (
    data: CreateTicketRequest & { subject_id: number }
  ) => {
    if (!workspaceId) return;

    try {
      const { subject_id, ...ticketData } = data;
      await createTicket(workspaceId, subject_id, ticketData);
      toast({
        title: "Success",
        description: "Ticket created successfully",
      });
      setIsNewTicketModalOpen(false);
      fetchTickets();
    } catch (error) {
      console.error("Failed to create ticket:", error);
      toast({
        title: "Error",
        description: "Failed to create ticket",
        variant: "destructive",
      });
    }
  }, [workspaceId, fetchTickets]);

  const handleTicketClick = (ticketId: number) => {
    navigate(`/ticket/${ticketId}`);
  };

  const { showAlert, AlertDialogComponent } = useAlertDialog();

  const handleDeleteTicket = async (ticketId: number) => {
    if (!isWritable || !workspaceId) return;
    
    const confirmed = await showAlert({
      title: "Delete Ticket",
      description: "Are you sure you want to delete this ticket? This action cannot be undone.",
      confirmText: "Delete",
      cancelText: "Cancel"
    });

    if (confirmed) {
      try {
        await deleteTicket(workspaceId, ticketId);
        await fetchTickets();
      } catch (error) {
        console.error('Failed to delete ticket:', error);
        showAlert({
          title: "Error",
          description: "Failed to delete ticket. Please try again.",
          confirmText: "OK",
          cancelText: ""
        });
      }
    }
  };

  if (loading) {
    return (
      <div className="flex-1 flex items-center justify-center">Loading...</div>
    );
  }

  if (error) {
    return (
      <div className="flex-1 flex items-center justify-center text-red-500">
        {error}
      </div>
    );
  }

  const openTickets = tickets.filter(
    (ticket) => ticket.terminal_status === "open"
  );
  const closedTickets = tickets.filter(
    (ticket) => ticket.terminal_status === "closed"
  );

  return (
    <div className="flex-1 overflow-auto bg-gray-50/50 h-full">
      <div className="max-w-[1400px] mx-auto p-4 lg:p-6">
        <div className="flex items-center justify-between mb-6">
          <Button
            onClick={() => setIsNewTicketModalOpen(true)}
            className="flex items-center gap-2"
            disabled={!isWritable}
          >
            <Plus className="h-4 w-4" />
            New Ticket
          </Button>
        </div>

        <div className="space-y-8">
          <TicketSection
            title="Open Tickets"
            tickets={openTickets}
            onDelete={handleDeleteTicket}
            isWritable={isWritable}
            workspaceId={workspaceId!}
            isOpen={true}
            onTicketClick={handleTicketClick}
            columns={3}
          />
          <TicketSection
            title="Closed Tickets"
            tickets={closedTickets}
            onDelete={handleDeleteTicket}
            isWritable={isWritable}
            workspaceId={workspaceId!}
            onTicketClick={handleTicketClick}
            viewType="list"
            columns={3}
          />
        </div>

        <Dialog
          open={isNewTicketModalOpen}
          onOpenChange={setIsNewTicketModalOpen}
        >
          <DialogContent className="sm:max-w-[600px]">
            <DialogHeader>
              <DialogTitle>Create New Ticket</DialogTitle>
            </DialogHeader>
            <TicketForm
              onSubmit={handleCreateTicket}
              onCancel={() => setIsNewTicketModalOpen(false)}
              subjects={subjects}
              workspaceId={workspaceId}
            />
          </DialogContent>
        </Dialog>
        <AlertDialogComponent />
      </div>
    </div>
  );
};

export default TicketHomeView;
