import { axiosClient } from "src/common/axios/axiosClient";
import { LoginRequestDTO } from "../DTO/LoginRequestDTO";
import { LoginResponseDTO } from "../DTO/LoginResponseDTO";

export const Login = async (
  request: LoginRequestDTO
): Promise<LoginResponseDTO> => {
  const result = await axiosClient.post("/login", request);
  return result?.data;
};

export const Logout = async (): Promise<void> => {
  await axiosClient.post("/logout");
};
