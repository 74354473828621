import { axiosClient } from "src/common/axios/axiosClient";
import { CreateWorkspaceRequest } from "../DTO/workspace/CreateWorkspaceRequest";
import { UpdateWorkspaceRequest } from "../DTO/workspace/UpdateWorkspaceRequest";
import { WorkspaceDTO } from "../DTO/workspace/WorkspaceDTO";
import { CreateWorkspaceUserRequest } from "../DTO/workspace_user/CreateWorkspaceUserRequest";
import { UpdateWorkspaceUserRequest } from "../DTO/workspace_user/UpdateWorkspaceUserRequest";
import { WorkspaceUserDTO } from "../DTO/workspace_user/WorkspaceUserDTO";
import { AddWorkspaceUserByUsernameRequest } from "../DTO/workspace_user/AddWorkspaceUserByUsernameRequest";
import { CreateWorkspaceInviteRequest } from "../DTO/invite/CreateWorkspaceInviteRequest";
import { CreateWorkspaceInviteByUsernameRequest } from "../DTO/invite/CreateWorkspaceInviteByUsernameRequest";
import { WorkspaceInviteDTO } from "../DTO/invite/WorkspaceInviteDTO";

// Workspace API functions
export const createWorkspace = async (
    request: CreateWorkspaceRequest
): Promise<WorkspaceDTO> => {
    const response = await axiosClient.post<WorkspaceDTO>(
        "/workspaces",
        request
    );
    return response.data;
};

export const updateWorkspace = async (
    workspaceId: number,
    request: UpdateWorkspaceRequest
): Promise<WorkspaceDTO> => {
    const response = await axiosClient.put<WorkspaceDTO>(
        `/workspaces/${workspaceId}`,
        request
    );
    return response.data;
};

export const deleteWorkspace = async (
    workspaceId: number
): Promise<{ message: string }> => {
    const response = await axiosClient.delete<{ message: string }>(
        `/workspaces/${workspaceId}`
    );
    return response.data;
};

export const getWorkspace = async (
    workspaceId: number
): Promise<WorkspaceDTO> => {
    const response = await axiosClient.get<WorkspaceDTO>(
        `/workspaces/${workspaceId}`
    );
    return response.data;
};

// Workspace User API functions
export const addWorkspaceUser = async (
    workspaceId: number,
    request: CreateWorkspaceUserRequest
): Promise<WorkspaceUserDTO> => {
    const response = await axiosClient.post<WorkspaceUserDTO>(
        `/workspaces/${workspaceId}/users`,
        request
    );
    return response.data;
};

export const addWorkspaceUserByUsername = async (
    workspaceId: number,
    request: AddWorkspaceUserByUsernameRequest
): Promise<WorkspaceUserDTO> => {
    const response = await axiosClient.post<WorkspaceUserDTO>(
        `/workspaces/${workspaceId}/users/add-by-username`,
        request
    );
    return response.data;
};

export const updateWorkspaceUser = async (
    workspaceId: number,
    userId: number,
    request: UpdateWorkspaceUserRequest
): Promise<WorkspaceUserDTO> => {
    const response = await axiosClient.put<WorkspaceUserDTO>(
        `/workspaces/${workspaceId}/users/${userId}`,
        request
    );
    return response.data;
};

export const removeWorkspaceUser = async (
    workspaceId: number,
    userId: number
): Promise<{ message: string }> => {
    const response = await axiosClient.delete<{ message: string }>(
        `/workspaces/${workspaceId}/users/${userId}`
    );
    return response.data;
};

export const listWorkspaceUsers = async (
    workspaceId: number
): Promise<WorkspaceUserDTO[]> => {
    const response = await axiosClient.get<WorkspaceUserDTO[]>(
        `/workspaces/${workspaceId}/users`
    );
    return response.data;
};

export const getUserWorkspaceRole = async (
    workspaceId: number,
    userId: number
): Promise<WorkspaceUserDTO> => {
    const response = await axiosClient.get<WorkspaceUserDTO>(
        `/workspaces/${workspaceId}/users/${userId}/role`
    );
    return response.data;
};

// Workspace Invite API functions
export const createWorkspaceInvite = async (
    workspaceId: number,
    request: CreateWorkspaceInviteRequest
): Promise<void> => {
    await axiosClient.post(
        `/workspaces/${workspaceId}/invites`,
        request
    );
};

export const createWorkspaceInviteByUsername = async (
    workspaceId: number,
    request: CreateWorkspaceInviteByUsernameRequest
): Promise<void> => {
    await axiosClient.post(
        `/workspaces/${workspaceId}/invites/by-username`,
        request
    );
};

export const deleteWorkspaceInvite = async (
    workspaceId: number,
    inviteId: number
): Promise<{ message: string }> => {
    const response = await axiosClient.delete<{ message: string }>(
        `/workspaces/${workspaceId}/invites/${inviteId}`
    );
    return response.data;
};

export const acceptWorkspaceInvite = async (
    workspaceId: number,
    inviteId: number
): Promise<{ message: string }> => {
    const response = await axiosClient.post<{ message: string }>(
        `/workspaces/${workspaceId}/invites/${inviteId}/accept`
    );
    return response.data;
};

export const rejectWorkspaceInvite = async (
    workspaceId: number,
    inviteId: number
): Promise<{ message: string }> => {
    const response = await axiosClient.post<{ message: string }>(
        `/workspaces/${workspaceId}/invites/${inviteId}/reject`
    );
    return response.data;
};

export const listWorkspaceInvites = async (
    workspaceId: number
): Promise<WorkspaceInviteDTO[]> => {
    const response = await axiosClient.get<WorkspaceInviteDTO[]>(
        `/workspaces/${workspaceId}/invites`
    );
    return response.data;
};

export const listWorkspacePendingInvites = async (
    workspaceId: number
): Promise<WorkspaceInviteDTO[]> => {
    const response = await axiosClient.get<{ invites: WorkspaceInviteDTO[] }>(
        `/workspaces/${workspaceId}/invites/pending`
    );
    return response.data.invites;
};

export const getMyWorkspaceInvites = async (): Promise<WorkspaceInviteDTO[]> => {
    const response = await axiosClient.get<WorkspaceInviteDTO[]>(
        `/my-invites`
    );
    return response.data;
};

// My Workspace API functions
export interface MyWorkspace {
    id: number;
    name: string;
    role: string;
    created_at: string;
    updated_at: string;
}

export async function getMyWorkspaces(): Promise<MyWorkspace[]> {
    const response = await axiosClient.get<MyWorkspace[]>('/workspaces/my');
    return response.data;
}

export const getMyWorkspaceRole = async (
    workspaceId: number
): Promise<WorkspaceUserDTO> => {
    const response = await axiosClient.get<WorkspaceUserDTO>(
        `/workspaces/${workspaceId}/my-role`
    );
    return response.data;
};

export const quitMyWorkspace = async (
    workspaceId: number
): Promise<{ message: string }> => {
    const response = await axiosClient.delete<{ message: string }>(
        `/workspaces/${workspaceId}/quit`
    );
    return response.data;
};
