import { SubjectDTO } from "../../../core-subject-management/DTO/common/SubjectDTO";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/common/shadcn/select";
import { Users, Building2, Box, Plus } from "lucide-react";
import { Button } from "src/common/shadcn/button";
import { Input } from "src/common/shadcn/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "src/common/shadcn/dialog";
import { useState, useCallback, useEffect } from "react";
import { TicketForm } from "../../../ticket-manager/TicketForm";
import { CreateTicketRequest } from "../../../core-subject-management/DTO/ticket/CreateTicketRequest";
import { createTicket } from "../../../core-subject-management/api/ticket-api";
import { updateSubject } from "../../../core-subject-management/api/subject-api";
import { toast } from "src/common/shadcn/use-toast";
import { useSearchParams } from "react-router-dom";

interface SubjectHeaderProps {
  subject: SubjectDTO;
  onTypeChange: (value: string) => void;
  onTicketCreated?: () => void;
  onSubjectUpdated?: () => void;
  onNameChange?: (value: string) => void;
  isWritable?: boolean;
}

export const SubjectHeader: React.FC<SubjectHeaderProps> = ({
  subject,
  onTypeChange,
  onTicketCreated,
  onSubjectUpdated,
  onNameChange,
  isWritable = true,
}) => {
  const [isNewTicketModalOpen, setIsNewTicketModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const workspaceId = parseInt(searchParams.get("wid") || "0");

  const handleCreateTicket = useCallback(
    async (data: CreateTicketRequest & { subject_id: number }) => {
      if (!workspaceId) return;
      try {
        const { subject_id, ...ticketData } = data;
        await createTicket(workspaceId, subject_id, ticketData);
        toast({
          title: "Success",
          description: "Ticket created successfully",
        });
        setIsNewTicketModalOpen(false);
        onTicketCreated?.();
      } catch (error) {
        console.error("Failed to create ticket:", error);
        toast({
          title: "Error",
          description: "Failed to create ticket",
          variant: "destructive",
        });
      }
    },
    [workspaceId, onTicketCreated]
  );

  const handleUpdateSubject = useCallback(
    async (field: string, value: any) => {
      if (!workspaceId) return;
      try {
        await updateSubject(workspaceId, subject.id, { [field]: value });
        toast({
          title: "Success",
          description: "Subject updated successfully",
        });
        onSubjectUpdated?.();
      } catch (error) {
        console.error("Failed to update subject:", error);
        // Revert local state on error
        toast({
          title: "Error",
          description: "Failed to update subject",
          variant: "destructive",
        });
      }
    },
    [workspaceId, subject.id, onSubjectUpdated]
  );

  const getTypeConfig = (type: string) => {
    const configs = {
      person: {
        icon: <Users className="h-4 w-4" />,
        bgColor: "bg-blue-100",
        textColor: "text-blue-700",
        label: "Individual",
      },
      company: {
        icon: <Building2 className="h-4 w-4" />,
        bgColor: "bg-purple-100",
        textColor: "text-purple-700",
        label: "Company",
      },
      entity: {
        icon: <Box className="h-4 w-4" />,
        bgColor: "bg-emerald-100",
        textColor: "text-emerald-700",
        label: "Entity",
      },
    };
    return configs[type as keyof typeof configs] || configs.person;
  };

  const typeConfig = getTypeConfig(subject.type);

  return (
    <div
      className={`${typeConfig.bgColor} border-b ${typeConfig.textColor.replace(
        "text-",
        "border-"
      )}`}
    >
      <div className="p-6">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-2">
              <span className="text-2xl mr-2" role="img" aria-label="Subject">
                👤
              </span>
              <div>
                <div className="flex items-center gap-2">
                  {typeConfig.icon}
                  <Input
                    value={subject.name}
                    onChange={(e) => onNameChange?.(e.target.value)}
                    className="text-xl font-semibold bg-transparent border-transparent focus:border-gray-300 transition-colors duration-200 shadow-none focus:shadow-none"
                    readOnly={!isWritable}
                  />
                </div>
                {subject.alias && (
                  <span className={`text-sm ${typeConfig.textColor}`}>
                    @{subject.alias}
                  </span>
                )}
              </div>
            </div>

            <Select
              value={subject.type}
              onValueChange={onTypeChange}
              disabled={!isWritable}
            >
              <SelectTrigger
                className={`w-auto border-0 bg-white/50 backdrop-blur-sm ${typeConfig.textColor} rounded-full px-3 py-1`}
              >
                <SelectValue>
                  <div className="flex items-center gap-2">
                    {typeConfig.icon}
                    <span className="text-xs font-medium">
                      {typeConfig.label}
                    </span>
                  </div>
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="person">
                  <div className="flex items-center gap-2">
                    <Users className="h-4 w-4" />
                    <span>Individual</span>
                  </div>
                </SelectItem>
                <SelectItem value="company">
                  <div className="flex items-center gap-2">
                    <Building2 className="h-4 w-4" />
                    <span>Company</span>
                  </div>
                </SelectItem>
                <SelectItem value="entity">
                  <div className="flex items-center gap-2">
                    <Box className="h-4 w-4" />
                    <span>Entity</span>
                  </div>
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          <Button
            variant="outline"
            size="sm"
            onClick={() => setIsNewTicketModalOpen(true)}
            disabled={!isWritable}
          >
            <Plus className="h-4 w-4" />
            New Ticket
          </Button>
        </div>
      </div>

      <Dialog
        open={isNewTicketModalOpen}
        onOpenChange={setIsNewTicketModalOpen}
      >
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Create New Ticket</DialogTitle>
          </DialogHeader>
          <TicketForm
            onSubmit={handleCreateTicket}
            onCancel={() => setIsNewTicketModalOpen(false)}
            subjects={[subject]}
            workspaceId={workspaceId} 
            initialData={{
              subject_id: subject.id,
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};
