import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Logout } from "../api/api";

export const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      try {
        await Logout();
        navigate("/login");
      } catch (error) {
        console.error("Logout failed:", error);
        // Still redirect to login in case of error
        navigate("/login");
      }
    };

    performLogout();
  }, [navigate]);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-lg">Logging you out...</div>
    </div>
  );
};
