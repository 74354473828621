import { Badge } from "src/common/shadcn/badge";
import { TicketDTO } from "src/usecase/core-subject-management/DTO/common/TicketDTO";
import { PriorityBadge } from "./PrioritySelect";
import { TerminalStatusSelect } from "./TerminalStatusSelect";
import { CurrentStatusSelect } from "./CurrentStatusSelect";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "src/common/shadcn/dropdown";
import { MoreVertical, Trash2 } from "lucide-react";
import { deleteTicket } from "src/usecase/core-subject-management/api/ticket-api";
import { useWorkspaceNavigate } from "src/common/hooks/useWorkspaceNavigate";
import { useAlertDialog } from "src/common/hooks/useAlertDialog";

export const TicketHeader = ({
  ticket,
  onPriorityChange,
  onTerminalStatusChange,
  onCurrentStatusChange,
  isWritable = true,
  workspaceId,
}: {
  ticket: TicketDTO;
  onPriorityChange: (value: number) => void;
  onTerminalStatusChange: (value: string) => void;
  onCurrentStatusChange: (value: string) => void;
  isWritable?: boolean;
  workspaceId: number;
}) => {
  const navigate = useWorkspaceNavigate();
  const { showAlert, AlertDialogComponent } = useAlertDialog();

  const handleDelete = async () => {
    if (!isWritable) return;
    
    const confirmed = await showAlert({
      title: "Delete Ticket",
      description: "Are you sure you want to delete this ticket? This action cannot be undone.",
      confirmText: "Delete",
      cancelText: "Cancel"
    });

    if (confirmed) {
      try {
        await deleteTicket(workspaceId, ticket.id);
        navigate('/ticket');
      } catch (error) {
        console.error('Failed to delete ticket:', error);
        showAlert({
          title: "Error",
          description: "Failed to delete ticket. Please try again.",
          confirmText: "OK",
          cancelText: ""
        });
      }
    }
  };

  return (
    <>
      <div className="bg-gradient-to-r from-blue-100 to-blue-300 border-b border-blue-200 relative">
        {isWritable && (
          <div className="absolute top-4 right-4">
            <DropdownMenu>
              <DropdownMenuTrigger className="h-8 w-8 flex items-center justify-center rounded-full hover:bg-blue-200">
                <MoreVertical className="h-5 w-5" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={handleDelete}
                  className="text-red-600 focus:text-red-600 cursor-pointer"
                >
                  <Trash2 className="mr-2 h-4 w-4" />
                  Delete Ticket
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
        <div className="max-w-4xl mx-auto flex flex-col p-6">
          <div className="flex items-center mb-4">
            <span className="text-2xl mr-2" role="img" aria-label="Ticket">
              📝
            </span>
            <h1 className="text-2xl font-bold text-blue-900 mr-2">
              {ticket.title}
            </h1>
            <span className="text-sm text-blue-700">#{ticket.id}</span>
          </div>
          <div className="flex items-center space-x-4">
            <CurrentStatusSelect
              status={ticket.current_status}
              onStatusChange={onCurrentStatusChange}
              disabled={!isWritable}
            />
            <PriorityBadge
              priority={ticket.priority ?? 0}
              onPriorityChange={onPriorityChange}
              disabled={!isWritable}
            />
            <TerminalStatusSelect
              status={ticket.terminal_status}
              onStatusChange={onTerminalStatusChange}
              disabled={!isWritable}
            />
          </div>
        </div>
      </div>
      <AlertDialogComponent />
    </>
  );
};
