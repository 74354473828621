import React from "react";

interface VideoArtifactViewProps {
  artifactId: string;
  workspaceId: number;
}

export const VideoArtifactView: React.FC<VideoArtifactViewProps> = ({
  artifactId,
  workspaceId,
}) => {
  const baseURL = process.env.REACT_APP_API_URL;

  return (
    <div className="flex items-center justify-center h-full">
      <video
        controls
        className="max-w-full max-h-full"
        src={`${baseURL}/workspaces/${workspaceId}/artifacts/raw/${artifactId}`}
      >
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
