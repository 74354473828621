import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

interface WorkspaceLinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
}

export const WorkspaceLink = React.forwardRef<HTMLAnchorElement, WorkspaceLinkProps>(
  ({ to, children, className }, ref) => {
    const [searchParams] = useSearchParams();
    const wid = searchParams.get('wid');

    const finalTo = wid ? 
      (to.includes('?') ? `${to}&wid=${wid}` : `${to}?wid=${wid}`) 
      : to;

    return (
      <Link ref={ref} to={finalTo} className={className}>
        {children}
      </Link>
    );
  }
);

WorkspaceLink.displayName = 'WorkspaceLink';
