import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";

interface XlsxArtifactViewProps {
  artifactId: string;
  workspaceId: number;
}

export const XlsxArtifactView: React.FC<XlsxArtifactViewProps> = ({
  artifactId,
  workspaceId,
}) => {
  const [data, setData] = useState<any[][]>([]);
  const [activeSheet, setActiveSheet] = useState<string>("");
  const [sheetNames, setSheetNames] = useState<string[]>([]);
  const [workbook, setWorkbook] = useState<XLSX.WorkBook | null>(null);
  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchXlsxData = async () => {
      try {
        const response = await fetch(
          `${baseURL}/workspaces/${workspaceId}/artifacts/raw/${artifactId}`,
          {
            credentials: "include",
          }
        );
        
        if (!response.ok) {
          throw new Error("Failed to fetch XLSX file");
        }

        const arrayBuffer = await response.arrayBuffer();
        const wb = XLSX.read(new Uint8Array(arrayBuffer), { type: "array" });
        setWorkbook(wb);
        
        setSheetNames(wb.SheetNames);
        if (wb.SheetNames.length > 0) {
          const firstSheet = wb.SheetNames[0];
          setActiveSheet(firstSheet);
          const worksheet = wb.Sheets[firstSheet];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          setData(jsonData as any[][]);
        }
      } catch (error) {
        console.error("Error loading XLSX file:", error);
      }
    };

    fetchXlsxData();
  }, [artifactId, workspaceId, baseURL]);

  const handleSheetChange = (sheetName: string) => {
    if (!workbook) return;
    
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    setData(jsonData as any[][]);
    setActiveSheet(sheetName);
  };

  return (
    <div className="w-full h-full overflow-auto p-4">
      {sheetNames.length > 1 && (
        <div className="mb-4">
          <select
            value={activeSheet}
            onChange={(e) => handleSheetChange(e.target.value)}
            className="border rounded px-2 py-1"
          >
            {sheetNames.map((name) => (
              <option key={name} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="overflow-x-auto">
        <table className="min-w-full border-collapse">
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell: any, cellIndex: number) => (
                  <td
                    key={cellIndex}
                    className="border px-4 py-2"
                    style={{
                      backgroundColor: rowIndex === 0 ? "#f3f4f6" : "white",
                      fontWeight: rowIndex === 0 ? "bold" : "normal",
                    }}
                  >
                    {cell?.toString() || ""}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
