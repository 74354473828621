import React from "react";
import { Input } from "src/common/shadcn/input";
import { Button } from "src/common/shadcn/button";

export type FileActionType = "createFolder" | "rename" | "newFile";

interface FileActionDialogProps {
  type: FileActionType;
  isOpen: boolean;
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
  fileExtension?: string;
}

export const FileActionDialog: React.FC<FileActionDialogProps> = ({
  type,
  isOpen,
  value,
  onChange,
  onSubmit,
  onCancel,
  fileExtension,
}) => {
  if (!isOpen) return null;

  const titles = {
    createFolder: "New Folder",
    rename: "Rename",
    newFile: "New File",
  };

  const placeholders = {
    createFolder: "Folder name",
    rename: "New name",
    newFile: "File name",
  };

  return (
    <div className="p-2 border-b flex items-center">
      <div className="flex-1 flex items-center">
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholders[type]}
          className="mr-2"
          autoFocus
        />
        {type === "newFile" && fileExtension && (
          <span className="text-muted-foreground mr-2">{fileExtension}</span>
        )}
      </div>
      <Button onClick={onSubmit}>{titles[type]}</Button>
      <Button variant="ghost" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  );
}; 