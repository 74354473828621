import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { ArtifactComponent } from './ArtifactComponent'

export interface ArtifactOptions {
  HTMLAttributes: Record<string, any>
  showArtifact: (artifactId: number) => void
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    artifact: {
      setArtifact: (options: { title: string; description: string; artifactId?: number }) => ReturnType
    }
  }
}

export const ArtifactNode = Node.create<ArtifactOptions>({
  name: 'artifact',

  group: 'block',

  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
      showArtifact: () => {},
    }
  },

  addAttributes() {
    return {
      title: {
        default: '',
      },
      description: {
        default: '',
      },
      artifactId: {
        default: null,
      },
      size: {
        default: null,
      },
      date: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'artifact-element',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['artifact-element', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
  },

  addCommands() {
    return {
      setArtifact:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          })
        },
    }
  },

  addNodeView() {
    return ReactNodeViewRenderer(ArtifactComponent)
  },
})
