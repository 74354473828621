import React from "react";
import { BubbleMenu, Editor } from "@tiptap/react";
import {
  Bold,
  Italic,
  Strikethrough,
  Heading1,
  Heading2,
  Pilcrow,
  List,
  ListOrdered,
} from "lucide-react";
import { Button } from "src/common/shadcn/button";

export const TextBubbleMenu = ({ editor }: { editor: Editor }) => {
  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{ placement: "bottom" }}
      shouldShow={({ from, to }) => from !== to && !editor.isActive("image")}
    >
      <div className="flex flex-row bg-background p-1 items-center justify-center rounded-md shadow-md border border-border">
        <Button
          variant="ghost"
          size="icon"
          className={
            editor.isActive("heading", { level: 1 }) ? "bg-accent" : ""
          }
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
        >
          <Heading1 className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          className={
            editor.isActive("heading", { level: 2 }) ? "bg-accent" : ""
          }
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
        >
          <Heading2 className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => editor.chain().focus().setParagraph().run()}
        >
          <Pilcrow className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          className={editor.isActive("bold") ? "bg-accent" : ""}
          onClick={() => editor.chain().focus().toggleBold().run()}
        >
          <Bold className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          className={editor.isActive("italic") ? "bg-accent" : ""}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        >
          <Italic className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          className={editor.isActive("strike") ? "bg-accent" : ""}
          onClick={() => editor.chain().focus().toggleStrike().run()}
        >
          <Strikethrough className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          className={editor.isActive("bulletList") ? "bg-accent" : ""}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          <List className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          className={editor.isActive("orderedList") ? "bg-accent" : ""}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <ListOrdered className="h-4 w-4" />
        </Button>
      </div>
    </BubbleMenu>
  );
};
