import React, { useEffect, useState } from 'react';
import { SingleActivity } from './component/SingleActivity';
import { listActivities } from './activity-api';
import { ActivityDTO } from './DTO/ActivityDTO';
import { useSearchParams } from 'react-router-dom';

export const ActivityHomeView = () => {
  const [activities, setActivities] = useState<ActivityDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const workspaceId = parseInt(searchParams.get('wid') || '0');

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        if (!workspaceId) {
          throw new Error('Workspace ID is required');
        }
        const data = await listActivities(workspaceId);
        setActivities(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch activities');
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, [workspaceId]);

  if (loading) {
    return <div className="p-6">Loading activities...</div>;
  }

  if (error) {
    return <div className="p-6 text-red-600">Error: {error}</div>;
  }

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Activity Feed</h1>
      <div className="space-y-4">
        {activities.map((activity) => (
          <div key={activity.id} className="p-4 bg-white rounded-lg shadow">
            <SingleActivity 
              activity={activity.full_activity} 
              updatedAt={activity.updated_at}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
