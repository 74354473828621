import React from 'react';
import { useWorkspaceNavigate } from 'src/common/hooks/useWorkspaceNavigate';
import { Ticket } from "lucide-react";
import { TicketDTO } from 'src/usecase/core-subject-management/DTO/common/TicketDTO';

export const TicketTag: React.FC<{ ticket: TicketDTO; ongoing?: boolean }> = ({ ticket, ongoing }) => {
  const navigate = useWorkspaceNavigate();
  
  return (
    <button
      onClick={() => navigate(`/ticket/${ticket.id}`)}
      className={`
        inline-flex items-center gap-1 px-2 py-0.5 text-xs font-medium rounded-full
        transition-all duration-200 hover:scale-105
        ${ongoing 
          ? 'bg-gradient-to-r from-blue-100 to-sky-100 text-blue-800 ring-1 ring-blue-300/60 hover:shadow-sm hover:shadow-blue-200 hover:ring-blue-300' 
          : 'bg-gray-50 text-gray-700 ring-1 ring-gray-200/70 hover:shadow-sm hover:bg-gray-100/80'
        }`}
    >
      <Ticket 
        className={`h-3 w-3 ${
          ongoing 
            ? 'text-blue-600' 
            : 'text-gray-400'
        }`}
      />
      <span className="truncate max-w-[160px]">{ticket.title}</span>
      {ticket.due_date && (
        <span className={`
          text-[10px] pl-1 border-l
          ${ongoing 
            ? 'border-blue-300/60 text-blue-700' 
            : 'border-gray-200 text-gray-500'
          }`}
        >
          {new Date(ticket.due_date).toLocaleDateString()}
        </span>
      )}
    </button>
  );
}; 