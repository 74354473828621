import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/common/shadcn/card";
import { Input } from "src/common/shadcn/input";
import { Label } from "src/common/shadcn/label";
import { Button } from "src/common/shadcn/button";
import { Login } from "../api/api";
import { useToast } from "src/common/shadcn/use-toast";
import { useWorkspaceNavigate } from 'src/common/hooks/useWorkspaceNavigate';

const LoginPage = () => {
  const { toast } = useToast();
  const navigate = useWorkspaceNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-900 to-indigo-900">
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1">
          <div className="flex justify-center mb-4">
            <img src="/logo-full.png" alt="Subject Manager" className="h-12" />
          </div>
          <CardTitle className="text-2xl font-bold text-center">
            Login to Subject Manager
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form
            className="space-y-4"
            onSubmit={(e) => {
              e.preventDefault();
              console.log("Login form submitted");

              // Get the form data
              const formData = new FormData(e.target as HTMLFormElement);
              const username = formData.get("username") as string;
              const password = formData.get("password") as string;

              // Proceed with the login request
              (async () => {
                const result = await Login({ username, password });
                if (!result) {
                  return;
                }

                if (!result.success) {
                  toast({
                    title: "Failed to Login",
                    description: `Failed to login: ${result.error}`,
                    variant: "destructive",
                  });
                  return;
                }

                // Only redirect to the dashboard on successful login
                navigate("/");
              })();
            }}
          >
            <div className="space-y-2">
              <Label htmlFor="username">Username</Label>
              <Input name="username" placeholder="Enter your username" />
            </div>
            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              <Input
                name="password"
                type="password"
                placeholder="Enter your password"
              />
            </div>
            <Button type="submit" className="w-full">
              Login
            </Button>
            <div className="text-center text-sm">
              Don't have an account?{" "}
              <a
                href="/register"
                className="text-primary hover:underline"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/register");
                }}
              >
                Register here
              </a>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default LoginPage;
