import React, { forwardRef, useImperativeHandle, useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import Placeholder from "@tiptap/extension-placeholder";
import "./editorStyles.css";
import "./single_md_editor_style.css";
import { TableBubbleMenu } from "./menus/table_bubble_menu";
import { TextBubbleMenu } from "./menus/text_bubble_menu";
import { staticExtensions } from "./extension/create_editor_extensions";
import { configureCommand, QuerySuggestionItem } from "./extension/command/command_configure";

export default forwardRef(
  (
    {
      visible,
      onChange,
      viewingMode,
      placeholder = "Click to add some content...",
      onMount,
      additionalExtensions,
      additionalQueryCommand
    }: {
      visible: boolean;
      onChange: () => void;
      viewingMode: "display" | "edit";
      placeholder?: string;
      onMount?: () => void;
      additionalExtensions?: any;
      additionalQueryCommand?: QuerySuggestionItem
    },
    ref
  ) => {
    const editor = useEditor({
      extensions: [
        ...staticExtensions,
        ...(additionalExtensions ?? []),
        configureCommand(additionalQueryCommand),
        Placeholder.configure({
          placeholder,
          emptyEditorClass: "is-editor-empty",
        }),
      ],
      editable: viewingMode === "edit",
      onUpdate: ({ editor, transaction }) => {
        // Only trigger onChange when there's an actual content change
        if (transaction.docChanged) {
          onChange();
        }
      },
      onCreate: () => {
        onMount?.();
      },
    });

    useEffect(() => {
      if (editor) {
        editor.setEditable(viewingMode === "edit");
      }
    }, [editor, viewingMode]);

    useImperativeHandle(ref, () => ({
      insert: (text: string) => {
        editor?.commands.insertContent(text);
      },
      getContent: () => {
        return editor?.getJSON();
      },
      getJsonString: () => {
        const resultObject = editor?.getJSON();
        const result = JSON.stringify(resultObject);
        return result;
      },
      setJsonString: (jsonString: string) => {
        const content = JSON.parse(jsonString);
        editor?.commands.setContent(content);
      },
      getText: () => {
        return editor?.getText();
      },
      setContent: (content: any) => {
        editor?.commands.setContent(content);
      },
    }));

    return (
      <div
        className="flex flex-col h-full w-full overflow-auto bg-background relative"
        style={visible ? {} : { display: "none" }}
      >
        <EditorContent
          className={`h-full w-full flex flex-row justify-center items-center`}
          editor={editor}
        />
        {editor && (
          <>
            <TableBubbleMenu editor={editor} />
            <TextBubbleMenu editor={editor} />
          </>
        )}
      </div>
    );
  }
);
