import { useCurrentLoggedInUser } from "../auth/hooks/useCurrentLoggedInUser";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import React from "react";
import { Button } from "src/common/shadcn/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "src/common/shadcn/dropdown";

import {
  User,
  Plus,
  MessageSquareMore,
  NotebookPen,
  Binoculars,
  Database,
  Bot,
} from "lucide-react";
import { WorkspaceLink } from 'src/common/components/WorkspaceLink';
import { Avatar, AvatarFallback, AvatarImage } from "src/common/shadcn/avatar";
import { Separator } from "src/common/shadcn/separator";
import { NotificationPopover } from "./NotificationPopover";

interface HomeLayoutProps {
  innerComponent: React.ReactElement;
  sidebar?: React.ReactElement;
}

export const HomeLayout = ({ innerComponent, sidebar }: HomeLayoutProps) => {
  const currentUser = useCurrentLoggedInUser();
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    setMenuOpen(false);
    navigate("/logout");
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {/* Full-width Top Navigation Bar */}
      <nav className="bg-white shadow-sm w-full">
        <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-12">
            {/* Left side: Logo and Nav items */}
            <div className="flex items-center">
              {/* Logo */}
              <div className="flex-shrink-0 flex items-center mr-12">
                <WorkspaceLink to={"/"}>
                  <img
                    className="h-10 w-auto"
                    src="/logo-full.png"
                    alt="Subject Manager"
                  />
                </WorkspaceLink>
              </div>
            </div>

            {/* Right side: Knowledge Base and User profile */}
            <div className="flex items-center space-x-4">
              <NotificationPopover />
              <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
                <DropdownMenuTrigger asChild>
                  <div className="flex items-center space-x-4 select-none hover:bg-gray-100 px-2">
                    <Avatar>
                      <AvatarImage src="https://github.com/shadcn.png" />
                      <AvatarFallback>CN</AvatarFallback>
                    </Avatar>
                    <div>{currentUser?.username}</div>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="min-w-[200px]">
                  <WorkspaceLink to={"/account"} className="w-full">
                    <DropdownMenuItem className="w-full cursor-pointer">
                      Account
                    </DropdownMenuItem>
                  </WorkspaceLink>
                  <DropdownMenuItem onClick={handleLogout} className="w-full cursor-pointer">
                    Logout
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </nav>
      {/* Updated main content area to include sidebar */}
      <div className="flex flex-1 h-full overflow-hidden">
        {sidebar}
        <div className="flex-1 p-6 overflow-y-auto">
          {innerComponent}
        </div>
      </div>
    </div>
  );
};
