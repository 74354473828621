import { common, createLowlight } from "lowlight";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Emoji, { gitHubEmojis } from "@tiptap-pro/extension-emoji";
import FileHandler from "@tiptap-pro/extension-file-handler";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import StarterKit from "@tiptap/starter-kit";

import emojiSuggestions from "./emoji/emoji_suggestions";
import { _generateText } from "./generateText";
import { ArtifactNode } from "../../../../usecase/core-subject-management/artifact-extension-editor/artifact-extention/ArtifactNode";

const lowlight = createLowlight(common);
export const staticExtensions = [
  StarterKit.configure({
    codeBlock: false,
  }),
  Link,
  CodeBlockLowlight.configure({
    lowlight,
  }),

  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  Emoji.configure({
    emojis: gitHubEmojis,
    enableEmoticons: true,
    suggestion: emojiSuggestions,
    HTMLAttributes: {
      class: "mdEmoji",
    },
  }),

  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
];

export const generateText = (content: any) => {
  try {
    // First let's deserialize the content
    const deserializedContent = JSON.parse(content);
    const result = _generateText(deserializedContent, staticExtensions);

    // Remove multiple consecutive newlines, keeping only one
    const cleanedResult = result.replace(/\n{2,}/g, '\n');

    // Return the cleaned result instead of the original result
    return cleanedResult;
  } catch (error) {
    console.log("error", error);
    return "invalid content";
  }
}
