import { useState, useEffect } from 'react';
import { ListUsersWithinWorkspace } from 'src/usecase/user/api/api';

export interface User {
  id: number;
  username: string;
  email: string;
}

export const useUsersWithinWorkspace = (workspaceId: number) => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await ListUsersWithinWorkspace(workspaceId);
        console.log('Users API Response:', response);
        setUsers(response.users || []);
      } catch (err) {
        console.error('Error fetching users:', err);
        setError('Failed to load users');
        setUsers([]);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return { users, loading, error };
}; 