import { Pencil, X } from "lucide-react";
import { Button } from "src/common/shadcn/button";

export interface EditableArtifactContent {
  canEdit: boolean;
  onEdit?: () => void;
  isEditing?: boolean;
  isModified?: boolean;
}

interface ArtifactHeaderProps {
  title: string;
  onClose: () => void;
  editableContent?: EditableArtifactContent;
}

export const ArtifactHeader: React.FC<ArtifactHeaderProps> = ({
  title,
  onClose,
  editableContent,
}) => {
  return (
    <div className="py-1 px-2 bg-gray-50 border-b border-gray-200 flex items-center justify-between">
      <div className="flex-grow flex items-center justify-between px-2">
        <h2 className="text-xs font-medium text-gray-700 truncate flex items-center gap-1">
          {title}
          {editableContent?.isModified && (
            <span className="text-gray-400 text-xs">*</span>
          )}
        </h2>
        <div className="flex gap-2">
          {editableContent?.canEdit && (
            <Button
              variant="ghost"
              size="sm"
              onClick={editableContent.onEdit}
              className={`h-6 w-6 p-0 hover:bg-gray-200 ${
                editableContent.isEditing ? "bg-gray-200" : ""
              }`}
            >
              <Pencil className="h-4 w-4" />
            </Button>
          )}
          <Button
            variant="ghost"
            size="sm"
            onClick={onClose}
            className="h-6 w-6 p-0 hover:bg-gray-200"
          >
            <X className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  );
};
