import React, { useState } from "react";

interface ImageArtifactViewProps {
  artifactId: string;
  workspaceId: number;
}

export const ImageArtifactView: React.FC<ImageArtifactViewProps> = ({
  artifactId,
  workspaceId,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const baseURL = process.env.REACT_APP_API_URL;

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
    setError("Failed to load image. Please try again.");
  };

  return (
    <div className="flex items-center justify-center h-full">
      {loading && <div>Loading...</div>}
      {error && <div className="text-red-500">{error}</div>}
      <img
        src={`${baseURL}/workspaces/${workspaceId}/artifacts/raw/${artifactId}`}
        alt="Artifact"
        className="max-w-full max-h-full object-contain"
        onLoad={handleImageLoad}
        onError={handleImageError}
        style={{ display: loading ? "none" : "block" }}
      />
    </div>
  );
};
