import { format } from "date-fns";
import { TicketDTO } from "src/usecase/core-subject-management/DTO/common/TicketDTO";

export const TimestampInfo = ({ ticket }: { ticket: TicketDTO }) => (
  <div className="text-xs text-gray-400 space-y-1">
    <div className="flex items-center justify-between">
      <span>Created:</span>
      <span>
        {ticket.opened_at ? format(new Date(ticket.opened_at), "PPP p") : "N/A"}
      </span>
    </div>
    {ticket.closed_at && (
      <div className="flex items-center justify-between">
        <span>Closed:</span>
        <span>{format(new Date(ticket.closed_at), "PPP p")}</span>
      </div>
    )}
    {ticket.status_updated_at && (
      <div className="flex items-center justify-between">
        <span>Last Updated:</span>
        <span>{format(new Date(ticket.status_updated_at), "PPP p")}</span>
      </div>
    )}
  </div>
);
