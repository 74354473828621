import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/common/shadcn/popover";
import { Bell } from "lucide-react";
import { Button } from "src/common/shadcn/button";
import { WorkspaceInviteDTO } from '../workspace/DTO/invite/WorkspaceInviteDTO';
import { getMyWorkspaceInvites, getMyWorkspaces, acceptWorkspaceInvite, rejectWorkspaceInvite } from '../workspace/api/workspace-api';
import { formatDistanceToNow, format, isAfter } from 'date-fns';
import { useToast } from "src/common/shadcn/use-toast";

export const NotificationPopover = () => {
  const [invites, setInvites] = useState<WorkspaceInviteDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  const fetchInvites = async () => {
    try {
      setLoading(true);
      const workspaceInvites = await getMyWorkspaceInvites();
      console.log('Fetched invites:', workspaceInvites);
      setInvites(workspaceInvites || []);
    } catch (error) {
      console.error('Failed to fetch workspace invites:', error);
      setInvites([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvites();
  }, []);

  const isExpired = (expiresAt: string) => {
    return !isAfter(new Date(expiresAt), new Date());
  };

  const pendingInvitesCount = invites?.filter(invite => {
    const isPending = invite.status === 'PENDING';
    const notExpired = !isExpired(invite.expires_at);
    console.log('Invite status:', { id: invite.id, isPending, notExpired, status: invite.status, expiresAt: invite.expires_at });
    return isPending && notExpired;
  }).length || 0;

  console.log('Pending invites count:', pendingInvitesCount);

  const handleAccept = async (invite: WorkspaceInviteDTO) => {
    try {
      setLoading(true);
      await acceptWorkspaceInvite(invite.workspace_id, invite.id);
      await fetchInvites();
      // Refresh the entire page to update workspaces
      window.location.reload();
      toast({
        title: "Success",
        description: "Workspace invitation accepted successfully",
      });
    } catch (error) {
      console.error('Failed to accept workspace invite:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to accept workspace invitation. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async (invite: WorkspaceInviteDTO) => {
    try {
      setLoading(true);
      await rejectWorkspaceInvite(invite.workspace_id, invite.id);
      await fetchInvites();
      toast({
        title: "Success",
        description: "Workspace invitation rejected successfully",
      });
    } catch (error) {
      console.error('Failed to reject workspace invite:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to reject workspace invitation. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="relative py-1.5">
          <Button variant="ghost" size="icon" className="relative">
            <Bell className="h-5 w-5" />
            {pendingInvitesCount > 0 && (
              <div className="absolute -top-1 -right-1 min-w-[18px] h-[18px] rounded-full bg-red-500 flex items-center justify-center px-1">
                <span className="text-[11px] text-white font-medium leading-none">{pendingInvitesCount}</span>
              </div>
            )}
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-80 p-0" align="end">
        <div className="p-4">
          <div className="flex items-center justify-between mb-4">
            <h4 className="font-semibold">Workspace Invites</h4>
            {loading && <span className="text-sm text-gray-500">Loading...</span>}
          </div>
          {!invites?.length ? (
            <p className="text-sm text-gray-500 text-center py-4">No pending invites</p>
          ) : (
            <div className="space-y-4 max-h-[400px] overflow-y-auto pr-2">
              {invites.map((invite) => {
                const expired = isExpired(invite.expires_at);
                return (
                  <div
                    key={invite.id}
                    className={`border-b border-gray-100 last:border-0 pb-3 last:pb-0 ${expired ? 'opacity-50' : ''}`}
                  >
                    <h5 className="font-medium text-sm">
                      Workspace Invite from {invite.inviter_user?.username}
                    </h5>
                    <p className="text-sm text-gray-600">
                      You've been invited as {invite.role.toLowerCase()} to workspace {invite.workspace?.name || `#${invite.workspace_id}`}
                    </p>
                    <div className="flex flex-col gap-2 mt-2">
                      <div className="flex justify-between items-center">
                        <span className="text-xs text-gray-400">
                          Invited {formatDistanceToNow(new Date(invite.created_at), { addSuffix: true })}
                        </span>
                        {invite.status === 'PENDING' && (
                          <span className="text-xs text-gray-400">
                            Expires {formatDistanceToNow(new Date(invite.expires_at), { addSuffix: true })}
                          </span>
                        )}
                      </div>
                      {invite.status === 'PENDING' && !expired ? (
                        <div className="flex justify-end space-x-2">
                          <Button 
                            variant="outline" 
                            size="sm"
                            className="text-xs"
                            onClick={() => handleAccept(invite)}
                            disabled={loading}
                          >
                            Accept
                          </Button>
                          <Button 
                            variant="ghost" 
                            size="sm"
                            className="text-xs"
                            onClick={() => handleReject(invite)}
                            disabled={loading}
                          >
                            Decline
                          </Button>
                        </div>
                      ) : (
                        <div className="flex justify-end">
                          <span className={`text-xs px-2 py-1 rounded-full ${
                            invite.status === 'ACCEPTED' ? 'bg-green-100 text-green-800' :
                            invite.status === 'DECLINED' ? 'bg-red-100 text-red-800' :
                            invite.status === 'PENDING' ? 'bg-yellow-100 text-yellow-800' :
                            'bg-gray-100 text-gray-800'
                          }`}>
                            {invite.status === 'ACCEPTED' ? 'Accepted' :
                             invite.status === 'DECLINED' ? 'Declined' :
                             invite.status === 'PENDING' ? 'Expired' :
                             'Unknown'}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
