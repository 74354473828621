import React from 'react';
import {  useForm, } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button } from 'src/common/shadcn/button';
import { ControllerRenderProps } from 'react-hook-form';

import { Input } from 'src/common/shadcn/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/common/shadcn/select';
import { Textarea } from 'src/common/shadcn/textarea';
import { CreateSubjectRequest } from '../core-subject-management/DTO/subject/CreateSubjectRequest';
import { SubjectDTO } from '../core-subject-management/DTO/common/SubjectDTO';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/common/shadcn/form';

const subjectFormSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  alias: z.string().optional(),
  type: z.enum(['person', 'company', 'entity'] as const),
  description: z.string().optional(),
  contact_email: z.string().email().optional().or(z.literal('')),
  contact_phone: z.string().optional(),
  contact_address: z.string().optional(),
  contact_wechat: z.string().optional(),
  contact_whatsapp: z.string().optional(),
  remark: z.string().optional(),
});

interface SubjectFormProps {
  onSubmit: (data: CreateSubjectRequest) => void;
  onCancel: () => void;
  initialData?: SubjectDTO | null;
  isEdit?: boolean;
}

export const SubjectForm: React.FC<SubjectFormProps> = ({ onSubmit, onCancel, initialData, isEdit = false }) => {
  const form = useForm<CreateSubjectRequest>({
    resolver: zodResolver(subjectFormSchema),
    defaultValues: initialData ? {
      ...initialData,
      type: initialData.type as 'person' | 'company' | 'entity'
    } : {
      name: '',
      type: 'person',
      alias: '',
      contact_email: '',
      description: '',
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="name"
            render={({ field }: { field: ControllerRenderProps<CreateSubjectRequest, "name"> }) => (
              <FormItem>
                <FormLabel>Name *</FormLabel>
                <FormControl>
                  <Input placeholder="Enter name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="alias"
            render={({ field }: { field: ControllerRenderProps<CreateSubjectRequest, "alias"> }) => (
              <FormItem>
                <FormLabel>Alias</FormLabel>
                <FormControl>
                  <Input placeholder="Enter alias" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="type"
            render={({ field }: { field: ControllerRenderProps<CreateSubjectRequest, "type"> }) => (
              <FormItem>
                <FormLabel>Type *</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select type" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="person">Person</SelectItem>
                    <SelectItem value="company">Company</SelectItem>
                    <SelectItem value="entity">Entity</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="contact_email"
            render={({ field }: { field: ControllerRenderProps<CreateSubjectRequest, "contact_email"> }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input type="email" placeholder="Enter email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="contact_phone"
            render={({ field }: { field: ControllerRenderProps<CreateSubjectRequest, "contact_phone"> }) => (
              <FormItem>
                <FormLabel>Phone</FormLabel>
                <FormControl>
                  <Input placeholder="Enter phone number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="contact_address"
            render={({ field }: { field: ControllerRenderProps<CreateSubjectRequest, "contact_address"> }) => (
              <FormItem>
                <FormLabel>Address</FormLabel>
                <FormControl>
                  <Input placeholder="Enter address" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="description"
          render={({ field }: { field: ControllerRenderProps<CreateSubjectRequest, "description"> }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea placeholder="Enter description" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="remark"
          render={({ field }: { field: ControllerRenderProps<CreateSubjectRequest, "remark"> }) => (
            <FormItem>
              <FormLabel>Remark</FormLabel>
              <FormControl>
                <Textarea placeholder="Enter remark" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex justify-end gap-3">
          <Button type="button" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">
            {isEdit ? 'Update Subject' : 'Add Subject'}
          </Button>
        </div>
      </form>
    </Form>
  );
}; 