import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/common/shadcn/card";
import { Input } from "src/common/shadcn/input";
import { Label } from "src/common/shadcn/label";
import { Button } from "src/common/shadcn/button";
import { RegisterUser, IsReferCodeNeeded } from "../api/api";
import { useToast } from "src/common/shadcn/use-toast";
import { useWorkspaceNavigate } from 'src/common/hooks/useWorkspaceNavigate';
import { UserRegistrationDTO } from "../DTO/UserRegistrationDTO";

const RegisterPage = () => {
  const { toast } = useToast();
  const navigate = useWorkspaceNavigate();
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [isReferCodeRequired, setIsReferCodeRequired] = React.useState(false);

  React.useEffect(() => {
    const checkReferCode = async () => {
      try {
        const { isReferCodeNeeded } = await IsReferCodeNeeded();
        setIsReferCodeRequired(isReferCodeNeeded);
      } catch (error) {
        console.error('Failed to check refer code requirement:', error);
        // Default to not required if check fails
        setIsReferCodeRequired(false);
      }
    };
    checkReferCode();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    // Get the form data
    const formData = new FormData(e.target as HTMLFormElement);
    const name = formData.get("name") as string;
    const email = formData.get("email") as string;
    const referCode = formData.get("refer_code") as string;
    
    // Validate passwords match
    if (password !== confirmPassword) {
      toast({
        title: "Passwords do not match",
        variant: "destructive",
      });
      return;
    }

    // Validate password length
    if (password.length < 6) {
      toast({
        title: "Password too short",
        description: "Password must be at least 6 characters long",
        variant: "destructive",
      });
      return;
    }

    // Create registration data
    const registrationData: UserRegistrationDTO = {
      name,
      email,
      password,
    };

    // Add refer code if required
    if (isReferCodeRequired) {
      if (!referCode) {
        toast({
          title: "Refer code required",
          description: "Please enter a valid refer code",
          variant: "destructive",
        });
        return;
      }
      registrationData.refer_code = referCode;
    }

    try {
      await RegisterUser(registrationData);
      toast({
        title: "Registration successful",
        description: "Please login with your credentials",
      });
      navigate("/login");
    } catch (error) {
      // Handle axios error with response data
      if (error && typeof error === 'object' && 'response' in error) {
        const axiosError = error as { response?: { data?: { error?: string } } };
        const errorMessage = axiosError.response?.data?.error || 'Unknown error occurred';
        toast({
          title: "Registration failed",
          description: errorMessage,
          variant: "destructive",
        });
      } else {
        toast({
          title: "Registration failed",
          description: error instanceof Error ? error.message : "Unknown error occurred",
          variant: "destructive",
        });
      }
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-purple-900 to-indigo-900">
      <Card className="w-full max-w-md">
        <CardHeader className="space-y-1">
          <div className="flex justify-center mb-4">
            <img src="/logo-full.png" alt="Subject Manager" className="h-12" />
          </div>
          <CardTitle className="text-2xl font-bold text-center">
            Create an Account
          </CardTitle>
        </CardHeader>
        <CardContent>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div className="space-y-2">
              <Label htmlFor="name">Username</Label>
              <Input
                id="name"
                name="name"
                placeholder="Enter your username"
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="confirm-password">Confirm Password</Label>
              <Input
                id="confirm-password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm your password"
                required
              />
            </div>
            {isReferCodeRequired && (
              <div className="space-y-2">
                <Label htmlFor="refer_code">Refer Code</Label>
                <Input
                  id="refer_code"
                  name="refer_code"
                  placeholder="Enter your refer code"
                  required
                />
              </div>
            )}
            <Button type="submit" className="w-full">
              Register
            </Button>
            <div className="text-center text-sm">
              Already have an account?{" "}
              <a
                href="/login"
                className="text-primary hover:underline"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/login");
                }}
              >
                Login here
              </a>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default RegisterPage;
