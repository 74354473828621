import React from 'react';
import { User, Lock } from "lucide-react";
import { Button } from 'src/common/shadcn/button';
import { Separator } from 'src/common/shadcn/separator';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from 'src/common/shadcn/tooltip';
import { useLocation, useNavigate } from 'react-router-dom';

interface AccountSidebarProps {
}

export const AccountSidebar: React.FC<AccountSidebarProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const menuItems = [
    { icon: User, label: "Basic Information", path: "/account" },
    { icon: Lock, label: "Change Password", path: "/account/password" },
  ];

  return (
    <div className="w-64 bg-white border-r">
      <div>
        <h2 className="p-4 text-lg font-semibold text-gray-900">
          Account Settings
        </h2>
        <Separator />
        <nav className="space-y-1 px-2">
          <TooltipProvider>
            {menuItems.map((item) => (
              <Tooltip key={item.path}>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    className={`w-full flex items-center gap-2 px-4 py-2 justify-start hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors ${
                      currentPath === item.path
                        ? 'bg-gray-900 text-white dark:bg-black hover:bg-gray-800 dark:hover:bg-gray-900'
                        : ''
                    }`}
                    onClick={() => navigate(item.path)}
                  >
                    <item.icon className="w-4 h-4" />
                    <span>{item.label}</span>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Go to {item.label}</p>
                </TooltipContent>
              </Tooltip>
            ))}
          </TooltipProvider>
        </nav>
      </div>
    </div>
  );
};
