import { AxiosResponse } from "axios";
import { axiosClient } from "src/common/axios/axiosClient";
import { TicketDTO } from "../DTO/common/TicketDTO";
import { CreateTicketRequest } from "../DTO/ticket/CreateTicketRequest";
import { UpdateTicketRequest } from "../DTO/ticket/UpdateTicketRequest";

export interface ListTicketsResponse {
    tickets: TicketDTO[];
    total_pages: number;
    current_page: number;
    page_size: number;
}

// API functions
export const createTicket = async (
    workspaceId: number,
    subjectId: number,
    request: CreateTicketRequest
): Promise<{ message: string }> => {
    const response = await axiosClient.post<{ message: string }>(
        `/workspaces/${workspaceId}/tickets`,
        request,
        {
            params: { subject_id: subjectId }
        }
    );
    return response.data;
};

export const updateTicket = async (
    workspaceId: number,
    ticketId: number,
    request: UpdateTicketRequest
): Promise<{ message: string }> => {
    const response = await axiosClient.put<{ message: string }>(
        `/workspaces/${workspaceId}/tickets/${ticketId}`,
        request,
    );
    return response.data;
};

export const deleteTicket = async (
    workspaceId: number,
    ticketId: number
): Promise<{ message: string }> => {
    const response = await axiosClient.delete<{ message: string }>(
        `/workspaces/${workspaceId}/tickets/${ticketId}`
    );
    return response.data;
};

export const getTicket = async (
    workspaceId: number,
    ticketId: number
): Promise<TicketDTO> => {
    const response = await axiosClient.get<TicketDTO>(
        `/workspaces/${workspaceId}/tickets/${ticketId}`
    );
    return response.data;
};

export const listTickets = async (
    workspaceId: number,
): Promise<TicketDTO[]> => {
    const params = new URLSearchParams();
    const response = await axiosClient.get<TicketDTO[]>(
        `/workspaces/${workspaceId}/tickets${params.toString() ? '?' + params.toString() : ''}`
    );
    return response.data;
};

/* Example usage:
// Create a new ticket
try {
    const newTicket = await createTicket(1, 1, {
        title: "New Issue",
        description: "Description of the issue",
        priority: 1
    });
    console.log('Created ticket:', newTicket);
} catch (error) {
    console.error('Failed to create ticket:', error);
}

// Update a ticket
try {
    const updatedTicket = await updateTicket(1, 1, 1, {
        title: "Updated Issue",
        description: "Updated description",
        terminal_status: "closed"
    });
    console.log('Updated ticket:', updatedTicket);
} catch (error) {
    console.error('Failed to update ticket:', error);
}

// Get a ticket
try {
    const ticket = await getTicket(1, 1);
    console.log('Retrieved ticket:', ticket);
} catch (error) {
    console.error('Failed to get ticket:', error);
}

// Delete a ticket
try {
    const result = await deleteTicket(1, 1);
    console.log(result.message); // "Ticket deleted successfully"
} catch (error) {
    console.error('Failed to delete ticket:', error);
}

// List tickets
try {
    const result = await listTickets(1, 1);
    console.log('All tickets:', result);
    
    // List only open tickets
    const openTickets = await listTickets(1, 1, 'open');
    console.log('Open tickets:', openTickets);
} catch (error) {
    console.error('Failed to list tickets:', error);
}
*/
