import { format } from "date-fns";
import { User, Clock, MoreVertical, Trash2 } from "lucide-react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "src/common/shadcn/card";
import { Badge } from "src/common/shadcn/badge";
import { Avatar, AvatarImage, AvatarFallback } from "src/common/shadcn/avatar";
import { TicketDTO } from "src/usecase/core-subject-management/DTO/common/TicketDTO";
import { getPriorityBadge, getStatusColor } from "./common";
import { SubjectHoverCard } from "./TicketDetailViewComponent/SubjectHoverCard";
import { generateText } from "src/common/app-base-components/TextEditor/extension/create_editor_extensions";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "src/common/shadcn/dropdown";

export const TicketCard = ({ 
  ticket, 
  onDelete, 
  isWritable = true,
  workspaceId 
}: { 
  ticket: TicketDTO;
  onDelete?: (ticketId: number) => void;
  isWritable?: boolean;
  workspaceId: number;
}) => {
  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete?.(ticket.id);
  };

  return (
    <Card className="group relative overflow-hidden transition-all duration-300 hover:shadow-lg bg-white border-0 ring-1 ring-gray-200 flex flex-col w-full break-inside-avoid">
      <CardHeader className="p-4 pb-3 flex-1">
        <div className="flex items-start justify-between gap-3 w-full">
          <div className="min-w-0 space-y-1 flex-1">
            <div className="flex flex-wrap items-center gap-2">
              <div className="flex items-center gap-2 min-w-0 flex-1">
                <CardTitle className="text-base font-semibold text-gray-900 truncate">
                  {ticket.title}
                </CardTitle>
                <span className="text-xs text-gray-500 shrink-0">#{ticket.id}</span>
              </div>
              <div className="flex items-center gap-2 shrink-0">
                <Badge
                  className={`${getStatusColor(ticket.current_status)} text-white`}
                >
                  {ticket.current_status}
                </Badge>
                {ticket.priority && getPriorityBadge(ticket.priority)}
                {isWritable && onDelete && (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <button 
                        className="h-8 w-8 flex items-center justify-center rounded-full hover:bg-gray-100"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <MoreVertical className="h-4 w-4 text-gray-500" />
                      </button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem 
                        onClick={handleDelete}
                        className="text-red-600"
                      >
                        <Trash2 className="mr-2 h-4 w-4" />
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
            </div>

            {ticket.description && (
              <p className="text-xs text-gray-600 whitespace-pre-wrap line-clamp-5">
                { ticket.description  && generateText(ticket.description) }
              </p>
            )}
          </div>
        </div>
      </CardHeader>

      <CardContent className="border-t border-gray-100 bg-gray-50 px-4 py-1.5 mt-auto">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {ticket.associated_subject ? (
              <SubjectHoverCard subject={ticket.associated_subject}>
                <div className="flex items-center gap-1.5 cursor-pointer shrink-0">
                  <Avatar className="h-5 w-5">
                    <AvatarImage
                      src={`/avatars/${ticket.associated_subject.avatar_id}`}
                    />
                    <AvatarFallback className="bg-gray-100 text-gray-500 text-xs">
                      {ticket.associated_subject.name.charAt(0)}
                    </AvatarFallback>
                  </Avatar>
                  <span className="text-xs text-gray-600 font-medium truncate max-w-[100px]">
                    {ticket.associated_subject.name}
                  </span>
                </div>
              </SubjectHoverCard>
            ) : (
              <div className="flex items-center gap-1.5 shrink-0">
                <Avatar className="h-5 w-5">
                  <AvatarFallback className="bg-gray-100 text-gray-500 text-xs">
                    ?
                  </AvatarFallback>
                </Avatar>
                <span className="text-xs text-gray-600 font-medium">
                  No Subject
                </span>
              </div>
            )}
            <div className="flex items-center gap-1">
              <User className="h-3.5 w-3.5 text-gray-500" />
              <span className="text-xs font-medium text-gray-600">
                {ticket.pic_user ? ticket.pic_user.username : "Unassigned"}
              </span>
            </div>
            <div className="flex items-center gap-1">
              <Clock className="h-3.5 w-3.5 text-gray-500" />
              <span className="text-xs font-medium text-gray-600">
                {ticket.due_date
                  ? format(new Date(ticket.due_date), "MMM dd, yyyy")
                  : "No due date"}
              </span>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
