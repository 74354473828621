import React from "react";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "./common/shadcn/toaster";
import { router } from "./common/router/router";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
      <Toaster />
    </div>
  );
}

export default App;
