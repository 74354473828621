import { axiosClient } from "src/common/axios/axiosClient";
import { UploadArtifactRequest } from "../DTO/artifact/UploadArtifactRequest";
import { UploadArtifactResponse } from "../DTO/artifact/UploadArtifactResponse";
import { ArtifactDTO } from "../DTO/common/ArtifactDTO";
import { AxiosProgressEvent, AxiosError } from "axios";
import { CreateFolderRequest } from "../DTO/artifact/CreateFolderRequest";
import { MoveArtifactRequest } from "../DTO/artifact/MoveArtifactRequest";
import { RenameArtifactRequest } from "../DTO/artifact/RenameArtifactRequest";
import { CreateFileRequest } from "../DTO/artifact/CreateFileRequest";
import { CopyArtifactRequest } from "../DTO/artifact/CopyArtifactRequest";
import { CopyArtifactResponse } from "../DTO/artifact/CopyArtifactResponse";
import { CreateFileArtifactResponse } from "../DTO/artifact/CreateFileArtifactResponse";
import { ListWorkspaceArtifactsRequest } from "../DTO/artifact/ListWorkspaceArtifactsRequest";
import { ListWorkspaceArtifactsResponse } from "../DTO/artifact/ListWorkspaceArtifactsResponse";
import { SearchArtifactsResponse } from "../DTO/artifact/SearchArtifactsResponse";

export const uploadArtifact = async (
  workspaceId: number,
  request: UploadArtifactRequest,
  file: File,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
): Promise<UploadArtifactResponse> => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("ticket_id", request.ticket_id.toString());
  formData.append("artifact_title", request.artifact_title);
  formData.append("artifact_description", request.artifact_description);
  if (request.parent_folder_id) {
    formData.append("parent_folder_id", request.parent_folder_id.toString());
  }
  if (request.artifact_metadata) {
    formData.append(
      "artifact_metadata",
      JSON.stringify(request.artifact_metadata)
    );
  }

  if (request.artifact_content) {
    formData.append("artifact_content", request.artifact_content);
  }

  try {
    const response = await axiosClient.post<UploadArtifactResponse>(
      `/workspaces/${workspaceId}/artifacts`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      }
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      // If the server returned an error response, throw the error message
      throw new Error(error.response.data.error || "An unknown error occurred");
    }
    // For network errors or other issues, throw a generic error
    throw new Error("Failed to upload artifact");
  }
};

export interface ListArtifactsRequest {
  ticket_id: number;
}

export interface ListArtifactsResponse {
  artifacts: ArtifactDTO[];
}

export const listArtifacts = async (
  workspaceId: number,
  request: ListArtifactsRequest
): Promise<ListArtifactsResponse> => {
  const response = await axiosClient.get<ListArtifactsResponse>(
    `/workspaces/${workspaceId}/artifacts`,
    {
      params: request,
    }
  );
  return response.data;
};

export const listWorkspaceArtifacts = async (
  workspaceId: number,
  request: ListWorkspaceArtifactsRequest
): Promise<ListWorkspaceArtifactsResponse> => {
  const response = await axiosClient.get<ListWorkspaceArtifactsResponse>(
    `/workspaces/${workspaceId}/artifacts/all`,
    {
      params: request,
    }
  );
  return response.data;
};

export const createFolder = async (
  workspaceId: number,
  request: CreateFolderRequest
): Promise<{ message: string }> => {
  const response = await axiosClient.post<{ message: string }>(
    `/workspaces/${workspaceId}/artifacts/folder`,
    request
  );
  return response.data;
};

export const moveArtifact = async (
  workspaceId: number,
  request: MoveArtifactRequest
): Promise<{ message: string }> => {
  const response = await axiosClient.put<{ message: string }>(
    `/workspaces/${workspaceId}/artifacts/move`,
    request
  );
  return response.data;
};

export const renameArtifact = async (
  workspaceId: number,
  request: RenameArtifactRequest
): Promise<{ message: string }> => {
  const response = await axiosClient.put<{ message: string }>(
    `/workspaces/${workspaceId}/artifacts/rename`,
    request
  );
  return response.data;
};

export const downloadArtifact = async (
  workspaceId: number,
  artifactId: number,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
): Promise<Blob> => {
  const response = await axiosClient.get(
    `/workspaces/${workspaceId}/artifacts/${artifactId}/download`,
    {
      responseType: "blob",
      onDownloadProgress,
    }
  );
  return response.data;
};

export const deleteArtifact = async (
  workspaceId: number,
  artifactId: number
): Promise<void> => {
  await axiosClient.delete(
    `/workspaces/${workspaceId}/artifacts/${artifactId}`
  );
};

export const getArtifact = async (
  workspaceId: number,
  artifactId: number
): Promise<ArtifactDTO> => {
  const response = await axiosClient.get<ArtifactDTO>(
    `/workspaces/${workspaceId}/artifacts/${artifactId}`
  );
  return response.data;
};

export const updateArtifactContent = async (
  workspaceId: number,
  artifactId: number,
  file: File,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
): Promise<void> => {
  const formData = new FormData();
  formData.append("file", file);
  await axiosClient.put(
    `/workspaces/${workspaceId}/artifacts/${artifactId}/content`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    }
  );
};

export const createFileArtifact = async (
  workspaceId: number,
  request: CreateFileRequest
): Promise<CreateFileArtifactResponse> => {
  const response = await axiosClient.post<CreateFileArtifactResponse>(
    `/workspaces/${workspaceId}/artifacts/file`,
    request
  );
  return response.data;
};

export const copyArtifact = async (
  workspaceId: number,
  request: CopyArtifactRequest
): Promise<CopyArtifactResponse> => {
  try {
    const response = await axiosClient.post<CopyArtifactResponse>(
      `/workspaces/${workspaceId}/artifacts/copy`,
      request
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      throw error.response.data;
    }
    throw error;
  }
};

export const searchArtifacts = async (
  workspaceId: number,
  query: string
): Promise<SearchArtifactsResponse> => {
    const response = await axiosClient.get<SearchArtifactsResponse>(
      `/workspaces/${workspaceId}/artifacts/search`,
      {
        params: { query },
      }
    );
    return response.data;
};
