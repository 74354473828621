import { AxiosResponse } from "axios";
import { axiosClient } from "src/common/axios/axiosClient";
import { SubjectDTO } from "../DTO/common/SubjectDTO";
import { CreateSubjectRequest } from "../DTO/subject/CreateSubjectRequest";
import { UpdateSubjectRequest } from "../DTO/subject/UpdateSubjectRequest";
import { ListSubjectsResponse } from "../DTO/subject/ListSubjectResponse";

// API functions
export const createSubject = async (
    workspaceId: number,
    request: CreateSubjectRequest
): Promise<{ message: string }> => {
    const response = await axiosClient.post<{ message: string }>(
        `/workspaces/${workspaceId}/subjects`,
        request
    );
    return response.data;
};

export const updateSubject = async (
    workspaceId: number,
    id: number,
    request: UpdateSubjectRequest
): Promise<{ message: string }> => {
    const response = await axiosClient.put<{ message: string }>(
        `/workspaces/${workspaceId}/subjects/${id}`,
        request
    );
    return response.data;
};

export const deleteSubject = async (
    workspaceId: number,
    id: number
): Promise<{ message: string }> => {
    const response = await axiosClient.delete<{ message: string }>(
        `/workspaces/${workspaceId}/subjects/${id}`
    );
    return response.data;
};

export const listSubjectsWithOpenTicketsAndLastEvent = async (
    workspaceId: number
): Promise<ListSubjectsResponse> => {
    const response = await axiosClient.get<ListSubjectsResponse>(
        `/workspaces/${workspaceId}/subjects`
    );
    return response.data;
};

export const getSubjectWithTicketsAndEvents = async (
    workspaceId: number,
    id: number
): Promise<SubjectDTO> => {
    const response = await axiosClient.get<SubjectDTO>(
        `/workspaces/${workspaceId}/subjects/${id}/with-tickets`
    );
    return response.data;
};
