// import { getExportedFullHtml } from '../../usecase/pdf_html_export/html_template/template';
// import { getMainFileExplorerCurrentPath } from '../../../file_terminal_app/file_explorer/hooks/useFileExplorerCurrentSelectedFiles';
// import { callExportHtmlToPdf } from 'renderer/ipcFunctions/ipcMainFunction/file_related_imports';
// import { getPdfCommand } from '../../usecase/pdf_html_export/command/getPdfCommand';
// import { getHtmlCommand } from '../../usecase/pdf_html_export/command/getHtmlCommand';

// Most of the items here are static items.
// Some are dynamic items, such as the image items.
const getStaticSuggestionItems = (query) => {
  console.log('Query: ', query);
  return [
    {
      icon: 'header-one',
      title: 'Heading 1',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode('heading', { level: 1 })
          .run();
      },
    },
    {
      icon: 'header-two',
      title: 'Heading 2',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode('heading', { level: 2 })
          .run();
      },
    },
    // Paragraph
    {
      icon: 'paragraph',
      title: 'Paragraph',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setParagraph().run();
      },
    },
    {
      icon: 'bold',
      title: 'Bold',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setMark('bold').run();
      },
    },
    {
      icon: 'italic',
      title: 'Italic',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setMark('italic').run();
      },
    },
    // Strike
    {
      icon: 'strikethrough',
      title: 'Strike',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setMark('strike').run();
      },
    },
    {
      icon: 'form',
      title: 'Todo',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleTaskList().run();
      },
    },
    {
      icon: 'th',
      title: 'Table',
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
          .run();
      },
    },
    {
      icon: 'citation',
      title: 'Blockquote',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleBlockquote().run();
      },
    },
    {
      icon: 'code',
      title: 'Code',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleCodeBlock().run();
      },
    },
    // Bullet list
    {
      icon: 'properties',
      title: 'Bullet List',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleBulletList().run();
      },
    },
    // Numbered list
    {
      icon: 'numbered-list',
      title: 'Numbered List',
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).toggleOrderedList().run();
      },
    },
      // getHtmlCommand(),
      // getPdfCommand(),
  ];
};

export default getStaticSuggestionItems;
