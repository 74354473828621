import React from 'react';
import { format } from 'date-fns';
import { TicketDTO } from 'src/usecase/core-subject-management/DTO/common/TicketDTO';
import { Button } from 'src/common/shadcn/button';
import { Badge } from 'src/common/shadcn/badge';
import { Clock, Trash2, User, MoreVertical } from 'lucide-react';
import { generateText } from "src/common/app-base-components/TextEditor/extension/create_editor_extensions";
import { getStatusColor, getPriorityBadge } from './common';
import { Avatar, AvatarImage, AvatarFallback } from "src/common/shadcn/avatar";
import { SubjectHoverCard } from "./TicketDetailViewComponent/SubjectHoverCard";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "src/common/shadcn/dropdown";

interface TicketListItemProps {
  ticket: TicketDTO;
  onDelete?: (ticketId: number) => void;
  isWritable?: boolean;
  onTicketClick: (ticketId: number) => void;
}

export const TicketListItem = ({
  ticket,
  onDelete,
  isWritable,
  onTicketClick,
}: TicketListItemProps) => {
  return (
    <div 
      className="group flex items-center gap-4 px-2 py-2 hover:bg-gray-50 bg-white hover:shadow-md transition-all cursor-pointer border border-gray-200 rounded-lg mb-2"
      onClick={() => onTicketClick(ticket.id)}
    >
      {ticket.associated_subject ? (
        <SubjectHoverCard subject={ticket.associated_subject}>
          <div className="flex items-center gap-2 cursor-pointer shrink-0">
            <Avatar className="h-6 w-6">
              <AvatarImage
                src={`/avatars/${ticket.associated_subject.avatar_id}`}
              />
              <AvatarFallback className="bg-gray-100 text-gray-500 text-xs">
                {ticket.associated_subject.name.charAt(0)}
              </AvatarFallback>
            </Avatar>
            <span className="text-xs text-gray-600 font-medium truncate max-w-[100px]">
              {ticket.associated_subject.name}
            </span>
          </div>
        </SubjectHoverCard>
      ) : (
        <div className="flex items-center gap-2 shrink-0">
          <Avatar className="h-6 w-6">
            <AvatarFallback className="bg-gray-100 text-gray-500 text-xs">
              ?
            </AvatarFallback>
          </Avatar>
          <span className="text-xs text-gray-600 font-medium">
            No Subject
          </span>
        </div>
      )}

      <div className="flex items-center gap-3 min-w-0 flex-1">
        <div className="flex items-center gap-2 min-w-0 flex-1">
          <h4 className="text-sm font-medium text-gray-900 truncate">
            {ticket.title}
          </h4>
          <span className="text-xs text-gray-500 shrink-0">#{ticket.id}</span>
          {ticket.description && (
            <p className="text-xs text-gray-500 truncate ">
              — {generateText(ticket.description)}
            </p>
          )}
        </div>

        <div className="flex items-center gap-2 text-xs text-gray-600 shrink-0 ml-auto pr-1">
          <div className="flex items-center gap-1">
            {ticket.priority && getPriorityBadge(ticket.priority)}
            <Badge className={`${getStatusColor(ticket.current_status)} text-white`}>
              {ticket.current_status}
            </Badge>
          </div>
          {ticket.due_date && (
            <div className="flex items-center gap-1.5">
              <Clock className="h-3.5 w-3.5 text-gray-500" />
              <span className="font-medium">
                {format(new Date(ticket.due_date), "MMM dd, yyyy")}
              </span>
            </div>
          )}
          {ticket.status_updated_at && (
            <span className="text-gray-500">
              Closed {format(new Date(ticket.status_updated_at), 'MMM d, yyyy')}
            </span>
          )}
          <div className="flex items-center gap-1.5">
            <User className="h-3.5 w-3.5 text-gray-500" />
            <span className="font-medium">
              {ticket.pic_user ? ticket.pic_user.username : "Unassigned"}
            </span>
          </div>
        </div>
      </div>

      {isWritable && onDelete && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button 
              className="h-8 w-8 flex items-center justify-center rounded-full hover:bg-gray-100"
              onClick={(e) => e.stopPropagation()}
            >
              <MoreVertical className="h-4 w-4 text-gray-500" />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem 
              onClick={(e) => {
                e.stopPropagation();
                onDelete(ticket.id);
              }}
              className="text-red-600"
            >
              <Trash2 className="mr-2 h-4 w-4" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
};
