import { axiosClient } from "../../../common/axios/axiosClient";
import { GetCurrentUserResponseDTO } from "../DTO/GetCurrentUserResponseDTO";
import { GetUserByIdResponseDTO } from "../DTO/GetUserByIdResponseDTO";
import { ListUserResponseDTO } from "../DTO/ListUserResponseDTO";
import { User } from "../DTO/common";
import { UserRegistrationDTO } from "../DTO/UserRegistrationDTO";
import { UpdateUserRequestDTO } from "../DTO/UpdateUserRequestDTO";
import { UpdateUserResponseDTO } from "../DTO/UpdateUserResponseDTO";

export interface UserDTO {
    id: number;
    username: string;
    email: string;
}

export interface IsReferCodeNeededResponse {
    isReferCodeNeeded: boolean;
}

export const GetCurrentUser = async (): Promise<GetCurrentUserResponseDTO> => {
  const res = await axiosClient.get("/getCurrentUser");
  return res.data;
};

export const ListUsersWithinWorkspace = async (workspaceId: number): Promise<ListUserResponseDTO> => {
  const res = await axiosClient.get(`/users/${workspaceId}`);
  return res.data;
};

export const GetUserByIdWithinWorkspace = async (workspaceId: number, userId: number): Promise<GetUserByIdResponseDTO> => {
  const res = await axiosClient.get(`/users/${workspaceId}/${userId}`);
  return res.data;
};

export const getUserByUsernameWithinWorkspace = async (workspaceId: number, username: string): Promise<UserDTO> => {
  const response = await axiosClient.get<UserDTO>(`/users/${workspaceId}/by-username/${username}`);
  return response.data;
};

export const RegisterUser = async (data: UserRegistrationDTO): Promise<User> => {
  const res = await axiosClient.post("/register", data);
  return res.data;
};

export async function updateUser(
    userId: number,
    request: UpdateUserRequestDTO
): Promise<UpdateUserResponseDTO> {
    const response = await axiosClient.put<UpdateUserResponseDTO>(
      `/users/${userId}`,
        request
    );
    return response.data;
}

export const IsReferCodeNeeded = async (): Promise<IsReferCodeNeededResponse> => {
    const res = await axiosClient.get("/isReferCodeNeeded");
    return res.data;
};