import { axiosClient } from "src/common/axios/axiosClient";
import { ActivityDTO } from "./DTO/ActivityDTO";
import { ListActivitiesResponse } from "./DTO/ListActivitiesResponse";

export const getActivity = async (
    workspaceId: number,
    activityId: number
): Promise<ActivityDTO> => {
    const response = await axiosClient.get<ActivityDTO>(
        `/activities/${workspaceId}/${activityId}`
    );
    return response.data;
};

export const listActivities = async (
    workspaceId: number
): Promise<ActivityDTO[]> => {
    const response = await axiosClient.get<ListActivitiesResponse>(
        `/activities/${workspaceId}`
    );
    return response.data.activities;
};
