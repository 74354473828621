import React, { useState } from 'react';
import Masonry from 'react-masonry-css';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from 'src/common/shadcn/collapsible';
import { Badge } from 'src/common/shadcn/badge';
import { TicketCard } from './TicketCard';
import { TicketListItem } from './TicketListItem';
import { TicketDTO } from 'src/usecase/core-subject-management/DTO/common/TicketDTO';

interface EmptyStateProps {
  icon: string;
  title: string;
  subtitle: string;
}

const EmptyState = ({ icon, title, subtitle }: EmptyStateProps) => (
  <div className="p-6 text-center">
    <div className="flex flex-col items-center gap-2">
      <span className="text-4xl">{icon}</span>
      <p className="text-gray-600">{title}</p>
      <p className="text-sm text-gray-500">{subtitle}</p>
    </div>
  </div>
);

interface TicketSectionProps {
  title: string;
  tickets: TicketDTO[];
  onDelete?: (ticketId: number) => void;
  isWritable?: boolean;
  workspaceId: number;
  isOpen?: boolean;
  onTicketClick: (ticketId: number) => void;
  viewType?: 'card' | 'list';
  columns?: number;
}

export const TicketSection = ({
  title,
  tickets,
  onDelete,
  isWritable,
  workspaceId,
  isOpen = false,
  onTicketClick,
  viewType = 'card',
  columns = 3,
}: TicketSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(isOpen);

  const breakpointColumns = {
    default: columns,
    1536: columns,
    1280: columns,
    1024: columns,
    768: columns,
    640: columns,
    480: columns,
  };

  const getEmptyStateProps = (): EmptyStateProps => {
    if (title.includes("Open")) {
      return {
        icon: "✨",
        title: "No open tickets at the moment",
        subtitle: "Everything is running smoothly!"
      };
    }
    return {
      icon: "📝",
      title: "No tickets have been created yet",
      subtitle: "Create a new ticket to get started"
    };
  };

  return (
    <Collapsible open={isExpanded} onOpenChange={setIsExpanded}>
      <div className="px-1 py-4">
        <CollapsibleTrigger className="flex items-center justify-between w-full">
          <div className="flex items-center gap-2">
            {isExpanded ? (
              <ChevronDown className="h-4 w-4 shrink-0 text-muted-foreground transition-transform" />
            ) : (
              <ChevronRight className="h-4 w-4 shrink-0 text-muted-foreground transition-transform" />
            )}
            <h3 className="text-lg font-semibold leading-none tracking-tight">
              {title}
            </h3>
            <Badge variant="secondary">
              {tickets.length}
            </Badge>
          </div>
        </CollapsibleTrigger>
      </div>

      <CollapsibleContent>
        <div className="px-6 pb-4">
          {tickets.length === 0 ? (
            <EmptyState {...getEmptyStateProps()} />
          ) : viewType === 'list' ? (
            <div>
              {tickets.map((ticket) => (
                <TicketListItem
                  key={ticket.id}
                  ticket={ticket}
                  onDelete={onDelete}
                  isWritable={isWritable}
                  onTicketClick={onTicketClick}
                />
              ))}
            </div>
          ) : (
            <div className="masonry-wrapper">
              <Masonry
                breakpointCols={breakpointColumns}
                className="flex -mx-2 w-auto"
                columnClassName="px-2 bg-clip-padding"
              >
                {tickets.map((ticket) => (
                  <div key={ticket.id} className="mb-4 break-inside-avoid">
                    <div 
                      onClick={() => onTicketClick(ticket.id)}
                      className="cursor-pointer hover:opacity-80 transition-opacity"
                    >
                      <TicketCard 
                        ticket={ticket} 
                        onDelete={onDelete ?() => onDelete?.(ticket.id) : undefined}
                        isWritable={isWritable}
                        workspaceId={workspaceId}
                      />
                    </div>
                  </div>
                ))}
              </Masonry>
            </div>
          )}
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};