import { SubjectDTO } from "../../../core-subject-management/DTO/common/SubjectDTO";
import { Input } from "src/common/shadcn/input";
import { Textarea } from "src/common/shadcn/textarea";
import { Label } from "src/common/shadcn/label";

interface SubjectDetailsProps {
  subject: SubjectDTO;
  handleUpdate: (field: string, value: any) => void;
  isWritable?: boolean;
}

export const SubjectDetails: React.FC<SubjectDetailsProps> = ({
  subject,
  handleUpdate,
  isWritable = true,
}) => {
  const inputClass =
    "mt-1 border-transparent focus:border-gray-300 transition-colors duration-200 shadow-none focus:shadow-none placeholder-gray-100";
  const textareaClass =
    "mt-1 border-transparent focus:border-gray-300 transition-colors duration-200 shadow-none focus:shadow-none resize-none placeholder-gray-100";

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="space-y-4">
        <div>
          <Label htmlFor="name" className="text-sm font-medium text-gray-700">
            👤 Name
          </Label>
          <Input
            id="name"
            value={subject.name}
            onChange={(e) => handleUpdate("name", e.target.value)}
            className={inputClass}
            placeholder="name"
            readOnly={!isWritable}
          />
        </div>
        <div>
          <Label htmlFor="alias" className="text-sm font-medium text-gray-700">
            🏷️ Alias
          </Label>
          <Input
            id="alias"
            value={subject.alias}
            onChange={(e) => handleUpdate("alias", e.target.value)}
            className={inputClass}
            placeholder="alias"
            readOnly={!isWritable}
          />
        </div>
        <div>
          <Label
            htmlFor="description"
            className="text-sm font-medium text-gray-700"
          >
            📝 Description
          </Label>
          <Textarea
            id="description"
            value={subject.description}
            onChange={(e) => handleUpdate("description", e.target.value)}
            className={textareaClass}
            rows={3}
            placeholder="description"
            readOnly={!isWritable}
          />
        </div>
        <div>
          <Label htmlFor="remark" className="text-sm font-medium text-gray-700">
            💭 Remarks
          </Label>
          <Textarea
            id="remark"
            value={subject.remark}
            onChange={(e) => handleUpdate("remark", e.target.value)}
            className={textareaClass}
            rows={3}
            placeholder="remarks"
            readOnly={!isWritable}
          />
        </div>
      </div>
      <div className="space-y-4">
        <div>
          <Label htmlFor="email" className="text-sm font-medium text-gray-700">
            ✉️ Email
          </Label>
          <Input
            id="email"
            type="email"
            value={subject.contact_email}
            onChange={(e) => handleUpdate("contact_email", e.target.value)}
            className={inputClass}
            placeholder="email"
            readOnly={!isWritable}
          />
        </div>
        <div>
          <Label htmlFor="phone" className="text-sm font-medium text-gray-700">
            📞 Phone
          </Label>
          <Input
            id="phone"
            type="tel"
            value={subject.contact_phone}
            onChange={(e) => handleUpdate("contact_phone", e.target.value)}
            className={inputClass}
            placeholder="phone"
            readOnly={!isWritable}
          />
        </div>
        <div>
          <Label
            htmlFor="address"
            className="text-sm font-medium text-gray-700"
          >
            📍 Address
          </Label>
          <Textarea
            id="address"
            value={subject.contact_address}
            onChange={(e) => handleUpdate("contact_address", e.target.value)}
            className={textareaClass}
            rows={3}
            placeholder="address"
            readOnly={!isWritable}
          />
        </div>
        <div className="flex space-x-4">
          <div className="flex-1">
            <Label
              htmlFor="wechat"
              className="text-sm font-medium text-gray-700"
            >
              💬 WeChat
            </Label>
            <Input
              id="wechat"
              value={subject.contact_wechat}
              onChange={(e) => handleUpdate("contact_wechat", e.target.value)}
              className={inputClass}
              placeholder="wechat"
              readOnly={!isWritable}
            />
          </div>
          <div className="flex-1">
            <Label
              htmlFor="whatsapp"
              className="text-sm font-medium text-gray-700"
            >
              📱 WhatsApp
            </Label>
            <Input
              id="whatsapp"
              value={subject.contact_whatsapp}
              onChange={(e) => handleUpdate("contact_whatsapp", e.target.value)}
              className={inputClass}
              placeholder="whatsapp"
              readOnly={!isWritable}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
