import React from 'react'
import { NodeViewWrapper } from '@tiptap/react'
import { motion } from 'framer-motion'
import { FileText, ExternalLink, Calendar, HardDrive } from 'lucide-react'

const formatFileSize = (bytes: number): string => {
  if (!bytes) return '0 B'
  const k = 1024
  const sizes = ['B', 'KB', 'MB', 'GB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`
}



export const ArtifactComponent = (prop: any) => {
  const { node } = prop

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (node.attrs.artifactId) {
      // You can access the showArtifact option through the editor's extension
      const showArtifact = prop?.extension?.options?.showArtifact
      
      if (showArtifact) {
        showArtifact(node.attrs.artifactId)
      }
    }
  }

  return (
    <NodeViewWrapper 
      onClick={handleClick}
      style={{ pointerEvents: 'auto' }}
      className="select-none"
    >
      <motion.div 
        whileHover={{ scale: 1.01 }}
        whileTap={{ scale: 0.99 }}
        className="bg-white shadow-sm hover:shadow-md transition-all duration-200 rounded-lg px-2 py-1 my-0.5 border border-gray-200 cursor-pointer max-w-[300px]"
        onClick={handleClick}
        onMouseDown={(e: { preventDefault: () => void; stopPropagation: () => void }) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <div className="flex items-center space-x-2">
          <div className="p-1 bg-blue-50 rounded-md">
            <FileText className="w-3.5 h-3.5 text-blue-500" />
          </div>
          <div className="flex-1 min-w-0">
            <div className="flex items-center space-x-1">
              <div className="font-medium text-sm text-gray-900 truncate">{node.attrs.title}</div>
              <ExternalLink className="w-3 h-3 text-gray-400 hover:text-gray-600 flex-shrink-0" />
            </div>
            <div className="flex items-center gap-3 text-xs">
              <p className="text-gray-600 truncate flex-1 !mb-0">{node.attrs.description}</p>
              <div className="flex items-center gap-2 text-gray-500 whitespace-nowrap">
                <div className="flex items-center">
                  <HardDrive className="w-3 h-3 mr-0.5 flex-shrink-0" />
                  <span>{formatFileSize(node.attrs.size)}</span>
                </div>
                <div className="flex items-center">
                  <Calendar className="w-3 h-3 mr-0.5 flex-shrink-0" />
                  <span>{new Date(node.attrs.date).toLocaleDateString()}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </NodeViewWrapper>
  )
}
