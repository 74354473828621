import React from "react";

interface AudioArtifactViewProps {
  artifactId: string;
  workspaceId: number;
}

export const AudioArtifactView: React.FC<AudioArtifactViewProps> = ({
  artifactId,
  workspaceId,
}) => {
  const baseURL = process.env.REACT_APP_API_URL;

  return (
    <div className="flex items-center justify-center h-full">
      <audio
        controls
        className="w-full"
        src={`${baseURL}/workspaces/${workspaceId}/artifacts/raw/${artifactId}`}
      />
    </div>
  );
};
