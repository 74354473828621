import { Badge } from "src/common/shadcn/badge";
import { Users, Building2, Box } from "lucide-react";
import { SubjectType } from "src/usecase/core-subject-management/DTO/common/SubjectDTO";

export const getStatusColor = (status: string): string => {
    const colors = {
      blocked: 'bg-red-500',
      waiting: 'bg-yellow-500',
      pending: 'bg-blue-500',
      ongoing: 'bg-purple-500',
      resolved: 'bg-green-500',
      failed: 'bg-red-700',
      cancelled: 'bg-gray-500'
    };
    return colors[status as keyof typeof colors] || 'bg-gray-500';
  };
  
export const getPriorityBadge = (priority: number) => {
    const colors = {
      1: 'bg-red-500',
      2: 'bg-orange-500',
      3: 'bg-yellow-500',
    };
    return (
      <Badge className={`${colors[priority as keyof typeof colors]} text-white`}>
        P{priority}
      </Badge>
    );
  };
  
export const getSubjectIcon = (type: SubjectType) => {
    switch (type) {
      case 'person':
        return <Users className="h-4 w-4 text-blue-500" />;
      case 'company':
        return <Building2 className="h-4 w-4 text-purple-500" />;
      case 'entity':
        return <Box className="h-4 w-4 text-emerald-500" />;
    }
  };