import axios, { Axios, AxiosError } from "axios";
import { toast } from "../shadcn/use-toast";
export const axiosClient = (() => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  });

  // Add a response interceptor
  client.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // If the error is due to a 401 status code, then the user is not authenticated.
      // Redirect to the login page.
      if (error.response?.status === 401) {
        window.location.href = "/login";
      }

      if ((error as AxiosError).code === "ERR_NETWORK") {
        // Handle network error
        console.error("Network error");

        toast({
          title: "Network Error",
          description:
            "A network error occurred. Err:" + (error as AxiosError).message,
          variant: "destructive",
        });
      }
      return Promise.reject(error);
    }
  );

  return client;
})();
