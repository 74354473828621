import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from "react-router-dom";
import { ArtifactDTO } from 'src/usecase/core-subject-management/DTO/common/ArtifactDTO';
import { listWorkspaceArtifacts, downloadArtifact, searchArtifacts } from 'src/usecase/core-subject-management/api/artifact-api';
import { AxiosProgressEvent } from 'axios';
import { Loader2, Search, X } from 'lucide-react';
import { getMyWorkspaceRole } from "src/usecase/workspace/api/workspace-api";
import { WorkspaceRole } from "src/usecase/workspace/DTO/workspace_user/WorkspaceUserDTO";
import { 
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from 'src/common/shadcn/pagination';
import { toast } from 'src/common/shadcn/use-toast';
import { Input } from 'src/common/shadcn/input';
import { useDebounce } from 'src/common/hooks/useDebounce';
import { ArtifactListItem } from './components/ArtifactListItem';
import { ArtifactSidebar } from '../ticket-manager/components/ArtifactSidebar';

const PAGE_SIZE = 20;



export const ArtifactHomeView: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [artifacts, setArtifacts] = useState<ArtifactDTO[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const workspaceId = parseInt(searchParams.get('wid') || '0');
  const page = parseInt(searchParams.get('page') || '1');
  const [isWritable, setIsWritable] = useState(false);
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const [downloadProgress, setDownloadProgress] = useState<{
    fileName: string;
    progress: number;
    loaded: number;
    total: number;
    completed: boolean;
  } | undefined>(undefined);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedArtifactId, setSelectedArtifactId] = useState<number | null>(null);

  const debouncedSearch = useDebounce(async (query: string) => {
    if (!query.trim()) {
      setIsSearching(false);
      loadArtifacts();
      return;
    }

    setIsSearching(true);
    try {
      const response = await searchArtifacts(workspaceId, query);
      setArtifacts(response.artifacts || []);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to search artifacts",
        variant: "destructive",
      });
    }
  }, 1000);

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    debouncedSearch(value);
  };

  const clearSearch = () => {
    setSearchQuery('');
    setIsSearching(false);
    loadArtifacts();
  };

  const loadArtifacts = async () => {
    if (!workspaceId) return;
    
    setIsLoading(true);
    try {
      const response = await listWorkspaceArtifacts(workspaceId, {
        page: page,
        page_size: PAGE_SIZE,
      });
      setArtifacts(response.artifacts);
      setTotalPages(response.page_count);
    } catch (error) {
      console.error('Failed to fetch artifacts:', error);
      // TODO: Add error toast notification
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchMyRole = async () => {
      if (!workspaceId) return;
      try {
        const roleData = await getMyWorkspaceRole(workspaceId);
        setIsWritable(roleData?.role === WorkspaceRole.Owner || roleData?.role === WorkspaceRole.Member);
      } catch (error) {
        console.error("Failed to fetch user role:", error);
      }
    };

    fetchMyRole();
  }, [workspaceId]);

  useEffect(() => {
    loadArtifacts();
  }, [workspaceId, page]);

  const handleDownload = async (artifactId: number) => {
    const artifact = artifacts.find(a => a.id === artifactId);
    if (!artifact) return;

    try {
      setDownloadProgress({
        fileName: artifact.artifact_file_name,
        progress: 0,
        loaded: 0,
        total: artifact.size,
        completed: false,
      });

      const blob = await downloadArtifact(workspaceId, artifactId, (progressEvent: AxiosProgressEvent) => {
        const { loaded, total } = progressEvent;
        setDownloadProgress({
          fileName: artifact.artifact_file_name,
          progress: total ? Math.round((loaded * 100) / total) : 0,
          loaded,
          total: total || artifact.size,
          completed: false,
        });
      });

      const url = window.URL.createObjectURL(blob);
      
      if (downloadLinkRef.current) {
        downloadLinkRef.current.href = url;
        downloadLinkRef.current.download = artifact.artifact_file_name;
        downloadLinkRef.current.click();
        window.URL.revokeObjectURL(url);
      }

      setDownloadProgress(undefined);
      toast({
        title: 'Success',
        description: `Downloaded ${artifact.artifact_file_name}`,
      });
    } catch (error) {
      console.error('Error downloading artifact:', error);
      toast({
        title: 'Error',
        description: `Failed to download ${artifact.artifact_file_name}`,
        variant: 'destructive',
      });
      setDownloadProgress(undefined);
    }
  };

  const handleArtifactClick = (artifactId: number) => {
    setSelectedArtifactId(artifactId);
    setIsSidebarOpen(true);
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
    setSelectedArtifactId(null);
  };

  if (!workspaceId) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-gray-500">Please select a workspace</p>
      </div>
    );
  }

  return (
    <div className="h-full flex overflow-hidden">
      <div className="flex-1 overflow-auto">
        <div className="container mx-auto py-6 px-4">
          <div className="mb-6 flex items-center gap-4">
            <div className="relative flex-1">
              <Input
                type="text"
                placeholder="Search artifacts..."
                value={searchQuery}
                onChange={(e) => handleSearch(e.target.value)}
                className="pl-10 pr-10"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
              {searchQuery && (
                <button
                  onClick={clearSearch}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                >
                  <X className="h-4 w-4" />
                </button>
              )}
            </div>
          </div>

          {isLoading ? (
            <div className="flex items-center justify-center py-8">
              <Loader2 className="h-6 w-6 animate-spin text-gray-500" />
            </div>
          ) : artifacts.length === 0 ? (
            <div className="text-center py-8">
              <p className="text-gray-500">No artifacts found</p>
            </div>
          ) : (
            <>
              <div className="space-y-2">
                {artifacts.map((artifact) => (
                  <ArtifactListItem
                    key={artifact.id}
                    artifact={artifact}
                    onDownload={handleDownload}
                    onClick={handleArtifactClick}
                    isWritable={isWritable}
                  />
                ))}
              </div>
              {!isSearching && (
                <div className="mt-6 flex justify-center">
                  <Pagination>
                    <PaginationContent>
                      <PaginationItem>
                        <PaginationPrevious 
                          onClick={() => setSearchParams({ page: Math.max(1, page - 1).toString() })}
                          className={page === 1 ? "pointer-events-none opacity-50" : "cursor-pointer"}
                        />
                      </PaginationItem>
                      {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNum) => (
                        <PaginationItem key={pageNum}>
                          <PaginationLink
                            onClick={() => setSearchParams({ page: pageNum.toString() })}
                            isActive={pageNum === page}
                            className="cursor-pointer"
                          >
                            {pageNum}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem>
                        <PaginationNext
                          onClick={() => setSearchParams({ page: Math.min(totalPages, page + 1).toString() })}
                          className={page === totalPages ? "pointer-events-none opacity-50" : "cursor-pointer"}
                        />
                      </PaginationItem>
                    </PaginationContent>
                  </Pagination>
                </div>
              )}
            </>
          )}

          {/* Hidden download link */}
          <a ref={downloadLinkRef} className="hidden" />

          {/* Progress indicator */}
          {downloadProgress && (
            <div className="fixed bottom-4 right-4 bg-white p-4 rounded-lg shadow-lg border border-gray-200 w-80 z-10">
              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium">{downloadProgress.fileName}</span>
                  <span className="text-sm text-gray-500">{downloadProgress.progress}%</span>
                </div>
                <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-blue-500 transition-all duration-200"
                    style={{ width: `${downloadProgress.progress}%` }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Artifact Sidebar */}
      <ArtifactSidebar
        isOpen={isSidebarOpen}
        artifactId={selectedArtifactId}
        onClose={handleSidebarClose}
        isWritable={isWritable}
      />
    </div>
  );
};