import React, { useEffect, useState } from 'react';
import { Input } from 'src/common/shadcn/input';
import { Label } from 'src/common/shadcn/label';
import { Button } from 'src/common/shadcn/button';
import { ScrollArea } from 'src/common/shadcn/scroll-area';

const EMOJI_LIST = [
  '🏠', '🏢', '🏗️', '🏭', '🏬', '🏫', '🏪', '🏥',
  '📚', '💻', '🖥️', '📱', '⌨️', '🖱️', '🖨️', '📷',
  '📹', '🎥', '📺', '📻', '📟', '📞', '📠', '🔋',
  '🔌', '💡', '🔦', '🕯️', '📖', '📗', '📘', '📙',
];

interface CreateWorkspaceDialogProps {
  onNameChange: (name: string) => void;
  onEmojiSelect: (emoji: string) => void;
  selectedEmoji: string | null;
  name: string;
}

export const CreateWorkspaceDialog: React.FC<CreateWorkspaceDialogProps> = ({
  onNameChange,
  onEmojiSelect,
  selectedEmoji,
  name,
}) => {
  // Local state to track input values
  const [localName, setLocalName] = useState(name);
  const [localEmoji, setLocalEmoji] = useState(selectedEmoji);

  // Update local state when props change
  useEffect(() => {
    setLocalName(name);
  }, [name]);

  useEffect(() => {
    setLocalEmoji(selectedEmoji);
  }, [selectedEmoji]);

  // Handle changes
  const handleNameChange = (value: string) => {
    setLocalName(value);
    onNameChange(value);
  };

  const handleEmojiSelect = (emoji: string) => {
    setLocalEmoji(emoji);
    onEmojiSelect(emoji);
  };

  return (
    <div className="space-y-4 p-2">
      <div className="space-y-2">
        <Label htmlFor="workspace-name">Workspace Name</Label>
        <Input
          id="workspace-name"
          placeholder="Enter workspace name"
          value={localName}
          onChange={(e) => handleNameChange(e.target.value)}
          autoFocus
        />
      </div>
      
      <div className="space-y-2">
        <Label>Emoji (Optional)</Label>
        <ScrollArea className="h-[200px] w-full rounded-md border">
          <div className="grid grid-cols-8 gap-2 p-2">
            {EMOJI_LIST.map((emoji) => (
              <Button
                key={emoji}
                variant={localEmoji === emoji ? "secondary" : "ghost"}
                className="h-8 w-8 p-0 hover:bg-gray-100"
                onClick={() => handleEmojiSelect(emoji)}
                type="button"
              >
                {emoji}
              </Button>
            ))}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
};
