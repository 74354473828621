import { useNavigate, useSearchParams } from 'react-router-dom';

export const useWorkspaceNavigate = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (to: string) => {
    const wid = searchParams.get('wid');
    if (wid) {
      // If the target path already has query parameters
      if (to.includes('?')) {
        navigate(`${to}&wid=${wid}`);
      } else {
        navigate(`${to}?wid=${wid}`);
      }
    } else {
      navigate(to);
    }
  };
};
