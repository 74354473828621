import { uploadArtifact, getArtifact } from "src/usecase/core-subject-management/api/artifact-api";
import { UploadArtifactRequest } from "src/usecase/core-subject-management/DTO/artifact/UploadArtifactRequest";
import { UploadArtifactResponse } from "src/usecase/core-subject-management/DTO/artifact/UploadArtifactResponse";
import { toast } from "src/common/shadcn/use-toast";
import { ArtifactDTO } from "../../DTO/common/ArtifactDTO";

export const artifactCommand = (
  query: string, 
  ticketId: number,
  workspaceId: number,
  onUploadDone?: () => void, 
  onSelectArtifact?: () => Promise<number | undefined>,
  onNewArtifact?: (extension: string) => Promise<ArtifactDTO | null>
) => ([
  {
    icon: 'upload',
    title: 'Upload Artifact',
    command: ({ editor, range }: { editor: any; range: any }) => {
      // Create file input
      const input = document.createElement('input');
      input.type = 'file';
      input.multiple = false;
      
      input.onchange = async (e) => {
        const file = (e.target as HTMLInputElement).files?.[0];
        if (!file) return;

        try {
          // Get file extension
          const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
          console.log('Upload Artifact with extension:', fileExtension);
          
          // Create upload request
          const request: UploadArtifactRequest = {
            ticket_id: ticketId,
            artifact_title: file.name,
            artifact_description: `Uploaded file: ${file.name}`,
            parent_folder_id: null,
          };

          // Upload the file
          const response: UploadArtifactResponse = await uploadArtifact(workspaceId, request, file);
          
          // Insert the artifact node with file type as description
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setArtifact({
              title: file.name,
              description: fileExtension ? `.${fileExtension} file` : 'Unknown file type',
              artifactId: response.artifact_id,
              size: response.size,
              date: response.created_at,
            })
            .run();

          toast({
            title: "Success",
            description: "Artifact uploaded successfully",
          });

          // Call the onUploadDone callback if provided
          onUploadDone?.();
        } catch (error) {
          console.error("Error uploading artifact:", error);
          toast({
            title: "Error",
            description: "Failed to upload artifact",
            variant: "destructive",
          });
        }
      };

      input.click();
    },
  }, 
  {
    icon: 'insert',
    title: 'Insert Artifact',
    command: async ({ editor, range }: { editor: any; range: any }) => {
      try {
        const artifactId = await onSelectArtifact?.();
        if (!artifactId) return;

        // Fetch the artifact details
        const artifact = await getArtifact(workspaceId, artifactId);
        console.log('Insert Artifact with extension:', artifact.artifact_title.split('.').pop()?.toLowerCase() || '');

        // Don't insert if it's a folder
        if (artifact.is_folder) {
          toast({
            title: "Error",
            description: "Cannot insert folders as artifacts",
            variant: "destructive",
          });
          return;
        }

        // Insert the artifact node
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setArtifact({
            title: artifact.artifact_title,
            description: artifact.artifact_description,
            artifactId: artifact.id,
            size: artifact.size,
            date: artifact.created_at,
          })
          .run();

        toast({
          title: "Success",
          description: "Artifact inserted successfully",
        });

        onUploadDone?.();
      } catch (error) {
        console.error('Error inserting artifact:', error);
        toast({
          title: "Error",
          description: "Failed to insert artifact",
          variant: "destructive",
        });
      }
    }
  },
  {
    icon: 'file-text',
    title: 'New Richtext Artifact',
    command: async ({ editor, range }: { editor: any; range: any }) => {
      try {
        console.log('New Richtext Artifact with extension: .solartext');
        const artifact = await onNewArtifact?.('.solartext');
        if (!artifact) return;

        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setArtifact({
            title: artifact.artifact_title,
            description: artifact.artifact_description,
            artifactId: artifact.id,
            size: artifact.size,
            date: artifact.created_at,
          })
          .run();
      } catch (error) {
        console.error('Error creating/inserting richtext artifact:', error);
        toast({
          title: "Error",
          description: "Failed to create/insert richtext artifact",
          variant: "destructive",
        });
      }
    }
  }
]);