import React, { useEffect, useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "src/common/shadcn/avatar";
import { Button } from "src/common/shadcn/button";
import { Input } from "src/common/shadcn/input";
import { Label } from "src/common/shadcn/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/common/shadcn/card";
import { Camera } from "lucide-react";
import { useToast } from "src/common/shadcn/use-toast";
import { GetCurrentUser, updateUser } from "../user/api/api";

const AccountBasicInfoPage: React.FC = () => {
  const [avatar, setAvatar] = useState<string>("/api/placeholder/100/100");
  const [email, setEmail] = useState<string>("");
  const [userId, setUserId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const userData = await GetCurrentUser();
        setEmail(userData.email);
        setUserId(userData.id);
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to load user data",
          variant: "destructive",
        });
      }
    };
    loadUserData();
  }, []);

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    if (!userId) {
      toast({
        title: "Error",
        description: "User data not loaded",
        variant: "destructive",
      });
      return;
    }

    setLoading(true);
    try {
      await updateUser(userId, { email });
      toast({
        title: "Success",
        description: "Profile updated successfully",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update profile",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-8 overflow-auto">
      <Card className="max-w-2xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-semibold text-gray-900">
            Basic Account Information
          </CardTitle>
          <CardDescription>
            Update your profile information
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="flex items-center space-x-4">
            <Avatar className="w-24 h-24 border-2 border-gray-200">
              <AvatarImage src={avatar} />
              <AvatarFallback>UN</AvatarFallback>
            </Avatar>
            <Label
              htmlFor="avatar-upload"
              className="cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              <Camera className="mr-2 h-4 w-4" />
              Change Avatar
              <Input
                id="avatar-upload"
                type="file"
                className="hidden"
                onChange={handleAvatarChange}
                accept="image/*"
              />
            </Label>
          </div>
          <div className="space-y-2">
            <Label htmlFor="email">Email Address</Label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full"
            />
          </div>
          <Button 
            className="w-full" 
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save Changes"}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default AccountBasicInfoPage;
