import React, { useCallback, useEffect, useState } from 'react';
import { Plus } from "lucide-react";
import { useWorkspaceNavigate } from 'src/common/hooks/useWorkspaceNavigate';
import { useSearchParams } from 'react-router-dom';
import { Button } from 'src/common/shadcn/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'src/common/shadcn/dialog';
import { SubjectForm } from './SubjectForm';
import { toast } from 'src/common/shadcn/use-toast';
import { SubjectSection } from './components/SubjectSection';
import { EmptyState } from './components/EmptyState';
import { Input } from 'src/common/shadcn/input';
import { useAlertDialog } from 'src/common/hooks/useAlertDialog';
import { SubjectDTO } from '../core-subject-management/DTO/common/SubjectDTO';
import { CreateSubjectRequest } from '../core-subject-management/DTO/subject/CreateSubjectRequest';
import { updateSubject, deleteSubject, listSubjectsWithOpenTicketsAndLastEvent, createSubject } from '../core-subject-management/api/subject-api';
import { WorkspaceRole } from '../workspace/DTO/workspace_user/WorkspaceUserDTO';
import { getMyWorkspaceRole } from '../workspace/api/workspace-api';

export const SubjectHomeView = () => {
  const navigate = useWorkspaceNavigate();
  const [searchParams] = useSearchParams();
  const [subjects, setSubjects] = useState<SubjectDTO[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isNewSubjectModalOpen, setIsNewSubjectModalOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<SubjectDTO | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { showAlert, AlertDialogComponent } = useAlertDialog();

  const workspaceIdStr = searchParams.get('wid') || undefined;
  const workspaceId = workspaceIdStr ? parseInt(workspaceIdStr) : undefined;


  const [myRole, setMyRole] = useState<WorkspaceRole | null>(null);

  const isWritable = myRole === WorkspaceRole.Owner || myRole === WorkspaceRole.Member;

  useEffect(() => {
    const fetchMyRole = async () => {
      if (!workspaceId) return;
      try {
        const roleData = await getMyWorkspaceRole(workspaceId);
        setMyRole(roleData?.role || null);
      } catch (error) {
        console.error("Failed to fetch user role:", error);
      }
    };

    fetchMyRole();
  }, [workspaceId]);

  const fetchSubjects = useCallback(async () => {
    if (!workspaceId) {
      setError('No workspace ID provided');
      setLoading(false);
      return;
    }

    try {
      const response = await listSubjectsWithOpenTicketsAndLastEvent(workspaceId);
      setSubjects(Array.isArray(response.subjects) ? response.subjects : []);
    } catch (err) {
      setError('Failed to load subjects');
      console.error('Error fetching subjects:', err);
      setSubjects([]); // Ensure subjects is always an array even on error
    } finally {
      setLoading(false);
    }
  }, [workspaceId]);

  useEffect(() => {
    fetchSubjects();
  }, [fetchSubjects]);

  const handleCreateSubject = useCallback(async (data: CreateSubjectRequest) => {
    if (!workspaceId) return;

    try {
      await createSubject(workspaceId, data);
      toast({
        title: "Success",
        description: "Subject created successfully",
      });
      setIsNewSubjectModalOpen(false);
      fetchSubjects();
    } catch (error) {
      console.error('Failed to create subject:', error);
      toast({
        title: "Error",
        description: "Failed to create subject",
        variant: "destructive",
      });
    }
  }, [fetchSubjects, workspaceId]);

  const handleEditSubject = useCallback(async (data: CreateSubjectRequest) => {
    if (!selectedSubject || !workspaceId) return;
    
    try {
      await updateSubject(workspaceId, selectedSubject.id, data);
      toast({
        title: "Success",
        description: "Subject updated successfully",
      });
      setIsEditModalOpen(false);
      fetchSubjects();
    } catch (error) {
      console.error('Failed to update subject:', error);
      toast({
        title: "Error",
        description: "Failed to update subject",
        variant: "destructive",
      });
    }
  }, [selectedSubject, fetchSubjects, workspaceId]);

  const handleDeleteSubject = useCallback(async (subject: SubjectDTO) => {
    if (!workspaceId) return;

    const shouldDelete = await showAlert({
      title: "Delete Subject",
      description: `Are you sure you want to delete "${subject.name}"? This action cannot be undone.`,
      confirmText: "Delete",
      cancelText: "Cancel",
    });

    if (shouldDelete) {
      try {
        await deleteSubject(workspaceId, subject.id);
        toast({
          title: "Success",
          description: "Subject deleted successfully",
        });
        fetchSubjects();
      } catch (error) {
        console.error('Failed to delete subject:', error);
        toast({
          title: "Error",
          description: "Failed to delete subject",
          variant: "destructive",
        });
      }
    }
  }, [showAlert, fetchSubjects, workspaceId]);

  const handleEditClick = useCallback((subject: SubjectDTO) => {
    setSelectedSubject(subject);
    setIsEditModalOpen(true);
  }, []);

  const handleSubjectClick = (subjectId: number) => {
    navigate(`/subject/${subjectId}`);
  };

  const filteredSubjects = (subjects || []).filter(subject =>
    subject.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (subject.alias && subject.alias.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const subjectsWithOpenTickets = (filteredSubjects || []).filter(s => 
    s.associated_open_tickets && s.associated_open_tickets.length > 0
  );
  const remainingSubjects = (filteredSubjects || []).filter(s => 
    !s.associated_open_tickets || s.associated_open_tickets.length === 0
  );

  const renderContent = () => {
    if (subjects.length === 0) {
      return (
        <>
          <div className="flex justify-between items-center mb-6">
            <Button
              onClick={() => setIsNewSubjectModalOpen(true)}
              className="flex items-center gap-2"
            >
              <Plus className="h-4 w-4" />
              New Subject
            </Button>
          </div>
          <EmptyState />
        </>
      );
    }

    return (
      <>
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-4 justify-between w-full">
            <Button
              onClick={() => setIsNewSubjectModalOpen(true)}
              className="flex items-center gap-2"
              disabled={!isWritable}
            >
              <Plus className="h-4 w-4" />
              New Subject
            </Button>
            <Input
              placeholder="Search subjects..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="max-w-xs"
            />
          </div>
        </div>

        <div className="space-y-8">
          {subjectsWithOpenTickets.length > 0 && (
            <SubjectSection 
              title="Subjects with Open Tickets" 
              subjects={subjectsWithOpenTickets}
              onEdit={handleEditClick}
              onDelete={handleDeleteSubject}
              showOpenTickets={true}
              onSubjectClick={handleSubjectClick}
              isWritable={isWritable}
            />
          )}
          {remainingSubjects.length > 0 && (
            <SubjectSection 
              title="All Subjects" 
              subjects={remainingSubjects}
              onEdit={handleEditClick}
              showPastFooter={true}
              onDelete={handleDeleteSubject}
              onSubjectClick={handleSubjectClick}
              isWritable={isWritable}
            />
          )}
          {filteredSubjects.length === 0 && (
            <div className="text-center text-gray-500 text-sm">
              No subjects found matching your search.
            </div>
          )}
        </div>
      </>
    );
  };

  if (loading) {
    return <div className="flex-1 flex items-center justify-center">Loading...</div>;
  }

  if (error) {
    return <div className="flex-1 flex items-center justify-center text-red-500">{error}</div>;
  }

  return (
    <div className="flex-1 overflow-auto bg-gray-50/50 h-full">
      <div className="max-w-[1400px] h-full mx-auto p-4 lg:p-6">
        {renderContent()}

        <Dialog open={isNewSubjectModalOpen} onOpenChange={setIsNewSubjectModalOpen}>
          <DialogContent className="sm:max-w-[600px]">
            <DialogHeader>
              <DialogTitle>Create New Subject</DialogTitle>
            </DialogHeader>
            <SubjectForm
              onSubmit={handleCreateSubject}
              onCancel={() => setIsNewSubjectModalOpen(false)}
            />
          </DialogContent>
        </Dialog>

        <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
          <DialogContent className="sm:max-w-[600px]">
            <DialogHeader>
              <DialogTitle>Edit Subject</DialogTitle>
            </DialogHeader>
            <SubjectForm
              onSubmit={handleEditSubject}
              onCancel={() => setIsEditModalOpen(false)}
              initialData={selectedSubject}
              isEdit={true}
            />
          </DialogContent>
        </Dialog>
      </div>
      <AlertDialogComponent />
    </div>
  );
};
