import { useEffect, useState } from "react";
import { GetCurrentUser } from "../../user/api/api";
import { GetCurrentUserResponseDTO } from "../../user/DTO/GetCurrentUserResponseDTO";

export const useCurrentLoggedInUser = () => {
  const [user, setUser] = useState<GetCurrentUserResponseDTO | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const user = await GetCurrentUser();
        setUser(user);
      } catch (e) {
        setUser(null);
      }
    })();
  }, []);

  return user;
};
