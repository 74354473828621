import React, { useState, useMemo, useRef, useEffect, useCallback } from "react";
import {
  FileIcon,
  UploadIcon,
  DownloadIcon,
  TrashIcon,
  FolderIcon,
  PlusIcon,
  FolderPlusIcon,
  MoveIcon,
  EditIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  CopyIcon,
} from "lucide-react";
import { ReactNode } from "react";
// ... existing imports ...

import { useAlertDialog } from "src/common/hooks/useAlertDialog";
import { Button } from "src/common/shadcn/button";
import { Progress } from "src/common/shadcn/progress";
import { formatFileSize, formatDate } from "src/utils/utils";
import { Input } from "src/common/shadcn/input";
import FolderSelector from "./FolderSelector";
import { FileActionDialog, FileActionType } from "./FileActionDialog";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "src/common/shadcn/dropdown";



// Add this interface for upload progress
export interface FileUploadProgress {
  fileName: string;
  progress: number; // 0-100
  loaded: number; // bytes uploaded
  total: number; // total bytes
  completed: boolean;
}

// Update the FileDownloadProgress interface
export interface FileDownloadProgress {
  fileName: string;
  progress: number; // 0-100
  loaded: number; // bytes downloaded
  total: number; // total bytes
  completed: boolean;
}
// Add these new interfaces after the existing interfaces
export interface FileInfo {
  id: number; // Add this line
  name: string;
  size: number;
  updatedAt: string;
  isFolder: boolean; // Add this line
  parentFolderId: number | null; // Add this line
}

// Add this new interface near the top with other interfaces
export interface FileTypeOption {
  extension: string;
  label: string;
  icon: ReactNode;
}

// Update the FileExplorerProps interface
interface FileExplorerProps {
  
  files: FileInfo[];
  onUpload?: (files: File[], parentFolderId: number | null) => void;
  onDelete?: (fileIds: number[]) => void;
  onCreateFolder?: (folderName: string, parentFolderId: number | null) => void;
  onMove?: (fileIds: number[], targetFolderId: number | null) => void;
  onDownload: (fileIds: number[]) => void;
  onRename?: (fileId: number, newName: string) => void;
  uploadProgress?: FileUploadProgress;
  downloadProgress?: FileDownloadProgress;
  onFileSelect: (fileId: number) => void;
  onFolderChange?: (folderId: number | null) => void;
  supportedFileTypes?: FileTypeOption[];
  onCreateFile?: (fileName: string, extension: string, parentFolderId: number | null) => void;
  onCopyFromTicket?: () => void;
  disabled?: boolean;
}

interface FolderStructure {
  [folderId: string]: FileInfo[]; // Change to string
}

export const FileExplorer: React.FC<FileExplorerProps> = ({
  files,
  onUpload,
  onDownload,
  onDelete,
  onCreateFolder,
  onMove,
  onRename,
  uploadProgress,
  downloadProgress,
  onFileSelect,
  onFolderChange,
  supportedFileTypes = [],
  onCreateFile,
  onCopyFromTicket,
  disabled = false,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<Set<number>>(new Set());
  const [currentFolderId, setCurrentFolderId] = useState<number | null>(null);
  const [actionType, setActionType] = useState<FileActionType | null>(null);
  const [actionValue, setActionValue] = useState("");
  const [renamingFile, setRenamingFile] = useState<FileInfo | null>(null);
  const [selectedExtension, setSelectedExtension] = useState<string>("");
  const [expandedFolders, setExpandedFolders] = useState<Set<number>>(new Set());

  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const lastClickTimeRef = useRef<number>(0);
  const lastClickedIdRef = useRef<number | null>(null);
  const lastUploadFolderRef = useRef<number | null>(null);

  const { showAlert, AlertDialogComponent } = useAlertDialog();

  const fileMap = useMemo(() => {
    const map = new Map<number, FileInfo>();
    files.forEach((file) => map.set(file.id, file));
    return map;
  }, [files]);

  const folderStructure = useMemo(() => {
    const structure: FolderStructure = { root: [] };
    files.forEach((file) => {
      const key =
        file.parentFolderId !== null ? file.parentFolderId.toString() : "root";
      if (!structure[key]) {
        structure[key] = [];
      }
      structure[key].push(file);
    });
    return structure;
  }, [files]);

  const isDownloadEnabled = useMemo(() => {
    return Array.from(selectedFiles).every((id) => {
      const file = files.find((f) => f.id === id);
      return file && !file.isFolder;
    });
  }, [selectedFiles, files]);

  const toggleFolder = useCallback((folderId: number) => {
    setExpandedFolders((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(folderId)) {
        newSet.delete(folderId);
      } else {
        newSet.add(folderId);
      }
      return newSet;
    });
  }, []);

  const getAllVisibleFiles = useCallback((): number[] => {
    const visibleFiles: number[] = [];
    const processFile = (fileId: number) => {
      const file = fileMap.get(fileId);
      if (!file) return;
      
      visibleFiles.push(fileId);
      if (expandedFolders.has(fileId)) {
        const children = files.filter(f => f.parentFolderId === fileId);
        children.forEach(child => processFile(child.id));
      }
    };
    
    // Start with root level files (files with no parent)
    files.filter(f => f.parentFolderId === null).forEach(file => processFile(file.id));
    return visibleFiles;
  }, [files, expandedFolders, fileMap]);

  const handleFileSelect = useCallback((fileId: number, event: React.MouseEvent) => {
    const currentTime = new Date().getTime();
    const isDoubleClick =
      currentTime - lastClickTimeRef.current < 300 &&
      lastClickedIdRef.current === fileId;

    if (isDoubleClick) {
      const clickedFile = fileMap.get(fileId);
      if (clickedFile?.isFolder) {
        toggleFolder(fileId);
      } else {
        onFileSelect(fileId);
      }
      return;
    }

    // Update click tracking refs
    lastClickTimeRef.current = currentTime;
    lastClickedIdRef.current = fileId;

    // Handle single click selection
    const newSelection = new Set(selectedFiles);

    if (event.metaKey || event.ctrlKey) {
      if (newSelection.has(fileId)) {
        newSelection.delete(fileId);
      } else {
        newSelection.add(fileId);
      }
    } else if (event.shiftKey && selectedFiles.size > 0) {
      const visibleFileIds = getAllVisibleFiles();
      const lastSelected = Array.from(selectedFiles)[selectedFiles.size - 1];
      const start = visibleFileIds.indexOf(lastSelected);
      const end = visibleFileIds.indexOf(fileId);
      if (start !== -1 && end !== -1) {
        const range = visibleFileIds.slice(
          Math.min(start, end),
          Math.max(start, end) + 1
        );
        range.forEach((id) => newSelection.add(id));
      }
    } else {
      newSelection.clear();
      newSelection.add(fileId);
    }

    setSelectedFiles(newSelection);
  }, [files, fileMap, selectedFiles, onFileSelect, toggleFolder, expandedFolders, getAllVisibleFiles]);

  const handleUploadClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = Array.from(event.target.files || []);
    if (uploadedFiles.length > 0 && onUpload) {
      const selectedFileIds = Array.from(selectedFiles);
      let parentId = currentFolderId;

      if (selectedFileIds.length === 1) {
        const selectedFile = fileMap.get(selectedFileIds[0]);
        if (selectedFile?.isFolder) {
          parentId = selectedFile.id;
        }
      }

      // Store the target folder ID before upload
      if (parentId !== null) {
        lastUploadFolderRef.current = parentId;
      }

      onUpload(uploadedFiles, parentId);
      event.target.value = "";
    }
  }, [onUpload, selectedFiles, currentFolderId, fileMap]);

  const getOutermostParentIds = useCallback((selectedIds: number[]): number[] => {
    const selectedSet = new Set(selectedIds);
    const result = new Set<number>();

    // Check each selected item
    selectedIds.forEach(id => {
      const file = fileMap.get(id);
      if (!file) return;

      // Check if any ancestor folder is selected
      let currentParentId = file.parentFolderId;
      let hasSelectedAncestor = false;
      
      while (currentParentId !== null) {
        const parent = fileMap.get(currentParentId);
        if (parent && selectedSet.has(parent.id)) {
          hasSelectedAncestor = true;
          break;
        }
        currentParentId = parent?.parentFolderId ?? null;
      }

      // Only include items that don't have any selected ancestors
      if (!hasSelectedAncestor) {
        result.add(id);
      }
    });

    return Array.from(result);
  }, [fileMap]);

  const handleDelete = useCallback(async () => {
    const selectedIds = Array.from(selectedFiles);
    const fileIdsToDelete = getOutermostParentIds(selectedIds);
    const isMultiple = fileIdsToDelete.length > 1;

    const confirmed = await showAlert({
      title: "Confirm Deletion",
      description: isMultiple
        ? `Are you sure you want to delete these ${fileIdsToDelete.length} items?`
        : `Are you sure you want to delete "${fileMap.get(fileIdsToDelete[0])?.name}"?`,
      confirmText: "Delete",
      cancelText: "Cancel",
    });

    if (confirmed && onDelete) {
      onDelete(fileIdsToDelete);
      setSelectedFiles(new Set());
    }
  }, [selectedFiles, fileMap, showAlert, onDelete, getOutermostParentIds]);

  const handleCreateFolder = useCallback(() => {
    setActionType("createFolder");
    setActionValue("");
  }, []);

  const handleRename = useCallback(() => {
    const selectedFileId = Array.from(selectedFiles)[0];
    const fileToRename = files.find((f) => f.id === selectedFileId);
    if (fileToRename) {
      setActionType("rename");
      setActionValue(fileToRename.name);
    }
  }, [files, selectedFiles]);

  const handleCreateFile = useCallback((extension: string) => {
    setActionType("newFile");
    setActionValue("");
    setSelectedExtension(extension);
  }, []);

  const submitAction = useCallback(() => {
    if (!actionValue) return;

    switch (actionType) {
      case "createFolder":
        if (onCreateFolder) {
          onCreateFolder(actionValue, currentFolderId);
          if (currentFolderId !== null) {
            setExpandedFolders(
              (prev) => new Set(Array.from(prev).concat(currentFolderId))
            );
          }
        }
        break;
      case "rename":
        const selectedFileId = Array.from(selectedFiles)[0];
        if (onRename && selectedFileId) {
          onRename(selectedFileId, actionValue);
        }
        break;
      case "newFile":
        if (onCreateFile) {
          onCreateFile(actionValue, selectedExtension, currentFolderId);
          if (currentFolderId !== null) {
            setExpandedFolders(
              (prev) => new Set(Array.from(prev).concat(currentFolderId))
            );
          }
        }
        break;
    }

    setActionType(null);
    setActionValue("");
    setSelectedExtension("");
    setSelectedFiles(new Set());
  }, [
    actionType,
    actionValue,
    selectedExtension,
    currentFolderId,
    selectedFiles,
    onCreateFolder,
    onRename,
    onCreateFile,
    setExpandedFolders
  ]);

  const handleMove = useCallback(async () => {
    const fileIds = Array.from(selectedFiles);
    let selectedFolderId: number | null = null;

    await showAlert({
      title: "Move Items",
      description: "Select a destination folder",
      confirmText: "Move",
      cancelText: "Cancel",
      content: (
        <FolderSelector
          files={files}
          onSelect={(folderId) => {
            selectedFolderId = folderId;
          }}
        />
      ),
      onClose: (confirmed) => {
        if (confirmed && onMove && selectedFolderId !== undefined) {
          onMove(fileIds, selectedFolderId);
          setSelectedFiles(new Set());
        }
      },
    });
  }, [selectedFiles, files, showAlert, onMove]);

  const handleCopyFromTicket = useCallback(async () => {
    if (onCopyFromTicket) {
      onCopyFromTicket();
      if (currentFolderId !== null) {
        setExpandedFolders(
          (prev) => new Set(Array.from(prev).concat(currentFolderId))
        );
      }
    }
  }, [onCopyFromTicket, currentFolderId, setExpandedFolders]);

  const renderFileList = useCallback((parentFolderId: number | null, depth: number = 0) => {
    const key = parentFolderId !== null ? parentFolderId.toString() : "root";
    const folderContents = folderStructure[key] || [];

    return folderContents.map((file) => (
      <React.Fragment key={file.id}>
        <div
          onClick={(e) => handleFileSelect(file.id, e)}
          className={`grid grid-cols-12 gap-4 px-4 py-2 cursor-default select-none hover:bg-accent/50 ${
            selectedFiles.has(file.id) ? "bg-accent" : ""
          }`}
          style={{ paddingLeft: `${depth * 20 + 16}px` }}
        >
          <div className="col-span-6 flex items-center gap-2">
            {file.isFolder && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFolder(file.id);
                }}
              >
                {expandedFolders.has(file.id) ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4" />
                )}
              </button>
            )}
            {file.isFolder ? (
              <FolderIcon className="h-4 w-4 text-muted-foreground" />
            ) : (
              <FileIcon className="h-4 w-4 text-muted-foreground" />
            )}
            <span className="truncate">{file.name}</span>
          </div>
          <div className="col-span-3 text-sm text-muted-foreground">
            {formatFileSize(file.size)}
          </div>
          <div className="col-span-3 text-sm text-muted-foreground">
            {formatDate(file.updatedAt)}
          </div>
        </div>
        {file.isFolder &&
          expandedFolders.has(file.id) &&
          renderFileList(file.id, depth + 1)}
      </React.Fragment>
    ));
  }, [folderStructure, selectedFiles, expandedFolders, handleFileSelect, toggleFolder]);

  // Update currentFolderId whenever selection changes
  useEffect(() => {
    if (selectedFiles.size === 1) {
      const selectedFileId = Array.from(selectedFiles)[0];
      const selectedFile = fileMap.get(selectedFileId);
      if (selectedFile?.isFolder) {
        setCurrentFolderId(selectedFile.id);
      } else if (selectedFile) {
        setCurrentFolderId(selectedFile.parentFolderId);
      }
    } else if (selectedFiles.size === 0) {
      setCurrentFolderId(null);
    }
  }, [selectedFiles, fileMap]);

  // Update useEffect to call onFolderChange when currentFolderId changes
  useEffect(() => {
    if (onFolderChange) {
      onFolderChange(currentFolderId);
    }
  }, [currentFolderId, onFolderChange]);

  // Add effect to expand folder after upload
  useEffect(() => {
    if (lastUploadFolderRef.current !== null) {
      setExpandedFolders(
        (prev) => new Set(Array.from(prev).concat(lastUploadFolderRef.current!))
      );
      lastUploadFolderRef.current = null;
    }
  }, [files]);

  return (
    <div className="flex flex-col h-full bg-background">
      {/* Toolbar */}
      <div className="flex items-center gap-2 p-2 border-b">
        <Button
          variant="ghost"
          size="icon"
          onClick={handleCreateFolder}
          className="hover:bg-accent"
          title="New Folder"
          disabled={disabled}
        >
          <FolderPlusIcon className="h-4 w-4" />
        </Button>

        {/* Add the new file type dropdown menu */}
        {supportedFileTypes.length > 0 && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild disabled={disabled}>
              <Button
                variant="ghost"
                size="icon"
                className="hover:bg-accent"
                title="New File"
                disabled={disabled}
              >
                <PlusIcon className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              {supportedFileTypes.map((type) => (
                <DropdownMenuItem
                  key={type.extension}
                  onClick={() => handleCreateFile(type.extension)}
                  className="flex items-center gap-2"
                  disabled={disabled}
                >
                  {type.icon}
                  <span>{type.label}</span>
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        )}

        <Button
          variant="ghost"
          size="icon"
          onClick={handleUploadClick}
          className="hover:bg-accent"
          title="Upload File"
          disabled={disabled}
        >
          <UploadIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => onDownload(Array.from(selectedFiles))}
          disabled={!isDownloadEnabled || selectedFiles.size === 0}
          className="hover:bg-accent"
          title="Download"
        >
          <DownloadIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          onClick={handleMove}
          disabled={selectedFiles.size === 0 || disabled}
          className="hover:bg-accent"
          title="Move"
        >
          <MoveIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          onClick={handleRename}
          disabled={selectedFiles.size !== 1 || disabled}
          className="hover:bg-accent"
          title="Rename"
        >
          <EditIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          onClick={handleDelete}
          disabled={selectedFiles.size === 0 || disabled}
          className="hover:bg-accent text-destructive hover:text-destructive"
          title="Delete"
        >
          <TrashIcon className="h-4 w-4" />
        </Button>
        <Button
          variant="ghost"
          size="icon"
          onClick={handleCopyFromTicket}
          className="hover:bg-accent"
          title="Copy From Existing Ticket"
          disabled={disabled}
        >
          <CopyIcon className="h-4 w-4" />
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          multiple
          onChange={handleFileInputChange}
        />
      </div>

      {/* Progress Bars */}
      {(uploadProgress || downloadProgress) && (
        <div className="p-2 border-b">
          {uploadProgress && (
            <div className="mb-2">
              <div className="text-sm text-muted-foreground mb-1">
                Uploading: {uploadProgress.fileName}
              </div>
              <Progress value={uploadProgress.progress} />
            </div>
          )}
          {downloadProgress && (
            <div>
              <div className="text-sm text-muted-foreground mb-1">
                Downloading: {downloadProgress.fileName}
              </div>
              <Progress value={downloadProgress.progress} />
            </div>
          )}
        </div>
      )}

      {/* File Action Dialog */}
      <FileActionDialog
        type={actionType || "createFolder"}
        isOpen={actionType !== null}
        value={actionValue}
        onChange={setActionValue}
        onSubmit={submitAction}
        onCancel={() => {
          setActionType(null);
          setSelectedExtension("");
        }}
        fileExtension={actionType === "newFile" ? selectedExtension : undefined}
      />

      {/* File List Header */}
      <div className="grid grid-cols-12 gap-4 px-4 py-2 border-b text-sm font-medium text-muted-foreground">
        <div className="col-span-6">Name</div>
        <div className="col-span-3">Size</div>
        <div className="col-span-3">Modified</div>
      </div>

      {/* File List */}
      <div className="flex-1 overflow-auto">
        {files.length > 0 ? (
          renderFileList(null)
        ) : (
          <div className="flex flex-col items-center justify-center h-full text-muted-foreground py-8">
            <FolderIcon className="h-12 w-12 mb-2" />
            <p className="text-sm">No artifacts in this folder</p>
            <p className="text-xs mt-1">
              Click the create/upload button to add artifacts
            </p>
          </div>
        )}
      </div>
      <AlertDialogComponent />
    </div>
  );
};
