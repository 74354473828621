import React, { useEffect, useState } from 'react';
import { Input } from 'src/common/shadcn/input';
import { Label } from 'src/common/shadcn/label';
import { Button } from 'src/common/shadcn/button';
import { ScrollArea } from 'src/common/shadcn/scroll-area';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'src/common/shadcn/select';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'src/common/shadcn/dialog';
import { MyWorkspace, listWorkspaceUsers, updateWorkspace, updateWorkspaceUser, removeWorkspaceUser, getWorkspace, getMyWorkspaceRole, deleteWorkspace, quitMyWorkspace, createWorkspaceInviteByUsername, listWorkspacePendingInvites, deleteWorkspaceInvite } from '../workspace/api/workspace-api';
import { WorkspaceUserDTO, WorkspaceRole } from '../workspace/DTO/workspace_user/WorkspaceUserDTO';
import { WorkspaceInviteDTO } from '../workspace/DTO/invite/WorkspaceInviteDTO';
import { useAlertDialog } from 'src/common/hooks/useAlertDialog';
import { Settings, Trash } from 'lucide-react';
import { GetUserByIdWithinWorkspace, getUserByUsernameWithinWorkspace, UserDTO } from '../user/api/api';
import { useToast } from 'src/common/shadcn/use-toast';
import { useCurrentLoggedInUser } from 'src/usecase/auth/hooks/useCurrentLoggedInUser';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from 'src/common/shadcn/dropdown';

interface EditWorkspaceDialogProps {
  workspace: MyWorkspace;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: () => void;
}

export const EditWorkspaceDialog: React.FC<EditWorkspaceDialogProps> = ({
  workspace,
  isOpen,
  onClose,
  onUpdate,
}) => {
  const [name, setName] = useState(workspace.name);
  const [users, setUsers] = useState<WorkspaceUserDTO[]>([]);
  const [userDetails, setUserDetails] = useState<Record<number, UserDTO>>({});
  const [newUsername, setNewUsername] = useState('');
  const [newUserRole, setNewUserRole] = useState<WorkspaceRole>(WorkspaceRole.Member);
  const [pendingInvites, setPendingInvites] = useState<WorkspaceInviteDTO[]>([]);
  const [isOwner, setIsOwner] = useState(workspace.role === 'owner');
  const [isLoading, setIsLoading] = useState(false);
  const [isInvitingUser, setIsInvitingUser] = useState(false);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const { showAlert, AlertDialogComponent } = useAlertDialog();
  const { toast } = useToast();
  const currentUser = useCurrentLoggedInUser();

  const countOwners = (usersList: WorkspaceUserDTO[]) => {
    return usersList.filter(user => user.role === WorkspaceRole.Owner).length;
  };

  const fetchUsers = async () => {
    try {
      const workspaceUsers = await listWorkspaceUsers(workspace.id);
      setUsers(workspaceUsers);
      
      // Fetch user details for each workspace user
      const userDetailsMap: Record<number, UserDTO> = {};
      await Promise.all(
        workspaceUsers.map(async (user) => {
          try {
            const response = await GetUserByIdWithinWorkspace(workspace.id, user.user_id);
            userDetailsMap[user.user_id] = response;
          } catch (error) {
            console.error(`Failed to fetch user details for user ${user.user_id}:`, error);
            toast({
              variant: "destructive",
              title: "Error",
              description: `Failed to fetch details for user ${user.user_id}`
            });
          }
        })
      );
      setUserDetails(userDetailsMap);
    } catch (error) {
      console.error('Failed to fetch workspace users:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch workspace users. Please try again later."
      });
    }
  };

  const fetchPendingInvites = async () => {
    try {
      const invites = await listWorkspacePendingInvites(workspace.id);
      setPendingInvites(invites);
    } catch (error) {
      console.error('Failed to fetch pending invites:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to fetch pending invites. Please try again later."
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchUsers();
      fetchPendingInvites();
    }
  }, [isOpen, workspace.id]);

  const handleUpdateWorkspace = async () => {
    try {
      setIsLoading(true);
      await updateWorkspace(workspace.id, { name });
      onUpdate();
      onClose();
    } catch (error) {
      console.error('Failed to update workspace:', error);
      toast({
        variant: "destructive",
        title: "Failed to update workspace",
        description: "An error occurred while updating the workspace."
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateUserRole = async (userId: number, role: WorkspaceRole, currentRole: WorkspaceRole) => {
    // If changing from owner to non-owner, check if this is the last owner
    if (currentRole === WorkspaceRole.Owner && role !== WorkspaceRole.Owner && countOwners(users) <= 1) {
      toast({
        variant: "destructive",
        title: "Cannot update role",
        description: "Cannot remove the last owner from workspace"
      });
      return;
    }

    // If user is renouncing their own owner role, show warning
    const isCurrentUser = currentUser?.id === userId;
    if (isCurrentUser && currentRole === WorkspaceRole.Owner && role !== WorkspaceRole.Owner) {
      const confirmed = await showAlert({
        title: "Renounce Owner Role",
        description: "Are you sure you want to give up your owner role? You will lose administrative privileges for this workspace.",
        confirmText: "Yes, I understand",
        cancelText: "Cancel",
      });

      if (!confirmed) return;
    }

    try {
      setIsLoading(true);
      await updateWorkspaceUser(workspace.id, userId, { role });
      await fetchUsers();
      
      // If the current user's role was changed, update permissions immediately
      if (isCurrentUser) {
        // Get the updated workspace role to ensure we have the latest role
        const updatedRole = await getMyWorkspaceRole(workspace.id);
        setIsOwner(updatedRole.role === WorkspaceRole.Owner);
        onUpdate();
      }
    } catch (error) {
      console.error('Failed to update user role:', error);
      if (error instanceof Error && error.message.includes('last owner')) {
        toast({
          variant: "destructive",
          title: "Cannot update role",
          description: "Cannot remove the last owner from workspace"
        });
      } else {
        toast({
          variant: "destructive",
          title: "Failed to update role",
          description: "An error occurred while updating the user role."
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteUser = async (userId: number, username: string, role: WorkspaceRole) => {
    // Prevent deleting the last owner
    if (role === WorkspaceRole.Owner && countOwners(users) <= 1) {
      toast({
        variant: "destructive",
        title: "Cannot remove user",
        description: "Cannot remove the last owner from workspace"
      });
      return;
    }

    const confirmed = await showAlert({
      title: "Remove User",
      description: `Are you sure you want to remove ${username} from this workspace?`,
      confirmText: "Remove",
      cancelText: "Cancel",
    });

    if (!confirmed) return;
    
    try {
      setIsLoading(true);
      await removeWorkspaceUser(workspace.id, userId);
      await fetchUsers();
      await onUpdate();
    } catch (error) {
      console.error('Failed to delete user:', error);
      toast({
        variant: "destructive",
        title: "Failed to remove user",
        description: "An error occurred while removing the user from workspace."
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInviteUser = async () => {
    if (!newUsername) return;

    try {
      setIsInvitingUser(true);
      await createWorkspaceInviteByUsername(workspace.id, {
        username: newUsername,
        role: newUserRole
      });
      await fetchPendingInvites();
      setNewUsername('');
      setNewUserRole(WorkspaceRole.Member);
      toast({
        title: "Success",
        description: `Invitation sent to ${newUsername}`
      });
    } catch (error: any) {
      console.error('Error inviting user:', error);
      let errorMessage = "An error occurred while inviting the user to workspace.";
      
      // Extract error message from response if available
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else if (error.response?.data?.error) {
        errorMessage = error.response.data.error;
      } else if (error.message) {
        errorMessage = error.message;
      }
      
      toast({
        variant: "destructive",
        title: "Failed to invite user",
        description: errorMessage
      });
    } finally {
      setIsInvitingUser(false);
    }
  };

  const handleRemoveInvite = async (inviteId: number) => {
    try {
      await deleteWorkspaceInvite(workspace.id, inviteId);
      await fetchPendingInvites();
      toast({
        title: "Success",
        description: "Invitation removed successfully"
      });
    } catch (error) {
      console.error('Failed to remove invite:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to remove invitation. Please try again later."
      });
    }
  };

  const handleDeleteWorkspace = async () => {
    showAlert({
      title: "Delete Workspace",
      description: "Are you sure you want to delete this workspace? This action cannot be undone and will delete all subjects and related data.",
      confirmText: "Delete",
      cancelText: "Cancel",
      onClose: async (confirmed) => {
        if (confirmed) {
          try {
            await deleteWorkspace(workspace.id);
            onUpdate();
            onClose();
            toast({
              title: "Success",
              description: "Workspace deleted successfully"
            });
          } catch (error) {
            console.error('Failed to delete workspace:', error);
            toast({
              variant: "destructive",
              title: "Error",
              description: "Failed to delete workspace. Please try again later."
            });
          }
        }
      }
    });
  };

  const handleQuitWorkspace = async () => {
    // Check if user is the last owner
    if (workspace.role === 'owner' && countOwners(users) <= 1) {
      toast({
        variant: "destructive",
        title: "Cannot quit workspace",
        description: "You are the last owner of this workspace. Please transfer ownership to another user or delete the workspace."
      });
      return;
    }

    const confirmed = await showAlert({
      title: "Quit Workspace",
      description: "Are you sure you want to quit this workspace? You will lose access to all workspace content.",
      confirmText: "Quit",
      cancelText: "Cancel",
    });

    if (!confirmed) return;

    try {
      await quitMyWorkspace(workspace.id);
      onUpdate();
      onClose();
      toast({
        title: "Success",
        description: "Successfully quit workspace"
      });
    } catch (error) {
      console.error('Failed to quit workspace:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to quit workspace. Please try again later."
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <div className="flex items-center justify-center">
            <DialogTitle className="text-xl">Edit Workspace</DialogTitle>
            {isOwner && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="icon" className="h-auto px-1 hover:bg-transparent">
                    <Settings className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    className="text-destructive focus:text-destructive"
                    onClick={handleDeleteWorkspace}
                  >
                    <Trash className="mr-2 h-4 w-4" />
                    Delete Workspace
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-left">
              Name
            </Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="col-span-3"
              disabled={!isOwner}
            />
          </div>
          <div className="grid gap-2">
            <Label>Users</Label>
            <ScrollArea className="h-[200px] w-full rounded-md border p-4">
              {users.map((user) => (
                <div key={user.user_id} className="flex items-center justify-between py-2">
                  <span>{userDetails[user.user_id]?.username || `User ${user.user_id}`}</span>
                  <div className="flex items-center gap-2">
                    {isOwner ? (
                      <>
                        <Select
                          value={user.role}
                          onValueChange={(value) => handleUpdateUserRole(user.user_id, value as WorkspaceRole, user.role)}
                          disabled={isLoading || (user.role === WorkspaceRole.Owner && countOwners(users) <= 1)}
                        >
                          <SelectTrigger className="w-[100px]">
                            <SelectValue placeholder="Select role" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value={WorkspaceRole.Reader}>Reader</SelectItem>
                            <SelectItem value={WorkspaceRole.Member}>Member</SelectItem>
                            <SelectItem value={WorkspaceRole.Owner}>Owner</SelectItem>
                          </SelectContent>
                        </Select>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleDeleteUser(user.user_id, userDetails[user.user_id]?.username || `User ${user.user_id}`, user.role)}
                          disabled={isLoading || (user.role === WorkspaceRole.Owner && countOwners(users) <= 1)}
                          className="h-8 w-8"
                        >
                          <Trash className="h-4 w-4" />
                        </Button>
                      </>
                    ) : (
                      <span className="text-sm text-gray-500">{user.role}</span>
                    )}
                  </div>
                </div>
              ))}
            </ScrollArea>
          </div>

          {/* Pending Invites Section */}
          {isOwner && (
            <div className="space-y-4">
              <h3 className="font-semibold">Pending Invites</h3>
              <ScrollArea className="h-[200px] w-full rounded-md border p-4">
                {pendingInvites?.length > 0 ? (
                  pendingInvites.map((invite) => {
                    const expiryDate = new Date(invite.expires_at);
                    const isExpired = expiryDate < new Date();
                    
                    return (
                    <div key={invite.id} className="flex items-center justify-between py-2">
                      <div className="flex flex-col">
                        <div>
                          <span className="font-medium">{invite.invitee_user?.username}</span>
                          <span className="ml-2 text-sm text-gray-500">({invite.role})</span>
                        </div>
                        <div className="text-xs text-gray-500">
                          Invited by: {invite.inviter_user?.username || 'Unknown'}
                          <span className="mx-2">•</span>
                          <span className={isExpired ? "text-red-500" : ""}>
                            Expires: {expiryDate.toLocaleDateString()}
                          </span>
                        </div>
                      </div>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleRemoveInvite(invite.id)}
                      >
                        <Trash className="h-4 w-4" />
                      </Button>
                    </div>
                    );
                  })
                ) : (
                  <div className="text-sm text-gray-500">No pending invites</div>
                )}
              </ScrollArea>
            </div>
          )}

          {/* User Management Section */}
          {isOwner && (
            <div className="space-y-4">
              <h3 className="font-semibold">Invite User</h3>
              <div className="flex items-center space-x-2">
                <Input
                  placeholder="Enter username"
                  value={newUsername}
                  onChange={(e) => setNewUsername(e.target.value)}
                />
                <Select
                  value={newUserRole}
                  onValueChange={(value) => setNewUserRole(value as WorkspaceRole)}
                >
                  <SelectTrigger className="w-[100px]">
                    <SelectValue placeholder="Select role" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={WorkspaceRole.Reader}>Reader</SelectItem>
                    <SelectItem value={WorkspaceRole.Member}>Member</SelectItem>
                    <SelectItem value={WorkspaceRole.Owner}>Owner</SelectItem>
                  </SelectContent>
                </Select>
                <Button 
                  onClick={handleInviteUser} 
                  disabled={isLoading || !newUsername || isInvitingUser}
                >
                  {isInvitingUser ? "Inviting..." : "Invite"}
                </Button>
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-end gap-2">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          {workspace.role === 'owner' && countOwners(users) > 1 && (
            <Button variant="destructive" onClick={handleQuitWorkspace}>
              Quit Workspace
            </Button>
          )}
          {workspace.role !== 'owner' && (
            <Button variant="destructive" onClick={handleQuitWorkspace}>
              Quit Workspace
            </Button>
          )}
          {isOwner && (
            <Button onClick={handleUpdateWorkspace} disabled={isLoading}>
              Save Changes
            </Button>
          )}
        </div>
      </DialogContent>
      <AlertDialogComponent />
    </Dialog>
  );
};
