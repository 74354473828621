import React from 'react';
import { Ticket, Users, Activity, Book, FileText } from "lucide-react";
import { Button } from 'src/common/shadcn/button';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from 'src/common/shadcn/tooltip';
import { useLocation } from 'react-router-dom';
import { WorkspaceSwitcher } from './WorkspaceSwitcher';
import { WorkspaceLink } from 'src/common/components/WorkspaceLink';

export const MainSidebar = () => {
  const location = useLocation();

  return (
    <div className="w-64 bg-white border-r">
      <WorkspaceSwitcher />
      
      <nav className="space-y-1 px-2">
        <TooltipProvider>
          {[
            { icon: Ticket, label: "Tickets", href: "/ticket" },
            { icon: Book, label: "Subjects", href: "/subject" },
            { icon: FileText, label: "Artifacts", href: "/artifact" },
            { icon: Activity, label: "Activity", href: "/activity" },
          ].map((item) => (
            <Tooltip key={item.label}>
              <TooltipTrigger asChild>
                <WorkspaceLink 
                  to={item.href} 
                  className={`flex items-center gap-2 px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-md transition-colors ${
                    location.pathname.startsWith(item.href) 
                      ? 'bg-gray-900 text-white dark:bg-black hover:bg-gray-800 dark:hover:bg-gray-900' 
                      : ''
                  }`}
                >
                  <item.icon className="w-4 h-4" />
                  <span>{item.label}</span>
                </WorkspaceLink>
              </TooltipTrigger>
              <TooltipContent>
                <p>Go to {item.label}</p>
              </TooltipContent>
            </Tooltip>
          ))}
        </TooltipProvider>
      </nav>
    </div>
  );
};
