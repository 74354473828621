import { TicketDTO } from "src/usecase/core-subject-management/DTO/common/TicketDTO";
import { UpdateTicketRequest } from "src/usecase/core-subject-management/DTO/ticket/UpdateTicketRequest";
import { Label } from "src/common/shadcn/label";
import { DueDatePicker } from "./DueDatePicker";
import { Avatar, AvatarImage, AvatarFallback } from "src/common/shadcn/avatar";
import { SubjectHoverCard } from "./SubjectHoverCard";
import { PiCPicker } from "../PICPicker";
import SingleMdEditor from "src/common/app-base-components/TextEditor/single_md_editor";
import { useRef, useEffect, useState, useCallback } from "react";
import { artifactCommand } from "src/usecase/core-subject-management/artifact-extension-editor/artifact-extention/command";
import { TicketFileExplorerRef } from "./TicketFileExplorer";
import { ArtifactNode } from "src/usecase/core-subject-management/artifact-extension-editor/artifact-extention/ArtifactNode";
import { ArtifactDTO } from "src/usecase/core-subject-management/DTO/common/ArtifactDTO";
import { useNavigate } from "react-router-dom";

interface TicketDetailsProps {
  ticket: TicketDTO;
  onSubjectClick?: (subjectId: number) => void;
  handleUpdate: (field: keyof UpdateTicketRequest, value: any) => Promise<void>;
  savingStatus?: 'idle' | 'saving' | 'saved';
  fileExplorerRef: React.RefObject<TicketFileExplorerRef>;
  handleArtifactSelect: (artifactId: number) => void;
  showSelectArtifactDialog: () => Promise<number | undefined>;
  handleInsertNewEmptyArtifact: (extension: string) => Promise<ArtifactDTO | null>;
  workspaceId: number;
  isWritable?: boolean;
}

export const TicketDetails: React.FC<TicketDetailsProps> = ({
  ticket,
  onSubjectClick,
  handleUpdate,
  savingStatus = 'idle',
  fileExplorerRef,
  handleArtifactSelect,
  showSelectArtifactDialog,
  handleInsertNewEmptyArtifact,
  workspaceId,
  isWritable = true,
}: TicketDetailsProps) => {
  const editorRef = useRef<any>(null);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isEditorReady || !editorRef.current || !ticket.description) return;
    setTimeout(() => editorRef.current.setJsonString(ticket.description), 0);
  }, [ticket.description, isEditorReady]);

  const handleEditorMount = useCallback(() => {
    setIsEditorReady(true);
  }, []);

  const handleEditorChange = useCallback(() => {
    if (!editorRef.current) return;
    const content = editorRef.current.getJsonString();
    handleUpdate("description", content);
  }, [handleUpdate]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">{ticket.title}</h2>
      </div>
      <div className="flex space-x-4">
        {ticket.associated_subject && (
          <div
            onClick={() =>
              ticket.associated_subject &&
              onSubjectClick?.(ticket.associated_subject.id)
            }
            className="flex-1 cursor-pointer hover:bg-gray-50"
          >
            <Label className="text-sm font-medium text-gray-500">
              Associated Subject
            </Label>
            <div className="mt-1.5">
              <SubjectHoverCard subject={ticket.associated_subject}>
                <div className="flex items-center gap-2 cursor-pointer">
                  <Avatar className="h-8 w-8">
                    <AvatarImage
                      src={`/avatars/${ticket.associated_subject.avatar_id}`}
                    />
                    <AvatarFallback className="bg-gray-100 text-gray-500">
                      {ticket.associated_subject.name.charAt(0)}
                    </AvatarFallback>
                  </Avatar>
                  <span className="text-sm text-gray-700">
                    {ticket.associated_subject.name}
                  </span>
                </div>
              </SubjectHoverCard>
            </div>
          </div>
        )}
        <div className="flex-1">
          <Label className="text-sm font-medium text-gray-500">Due Date</Label>
          <DueDatePicker
            value={ticket.due_date}
            onChange={(date) =>
              date && handleUpdate("due_date", date.toISOString())
            }
            disabled={!isWritable}
          />
        </div>
        <div className="flex-1">
          <Label className="text-sm font-medium text-gray-500">
            Person In Charge
          </Label>
          <PiCPicker
            value={ticket.pic_user_id}
            onChange={(val) => handleUpdate("pic_user_id", val)}
            workspaceId={workspaceId}
            disabled={!isWritable}
          />
        </div>
      </div>
      <div className="space-y-2">
        <Label className="text-sm font-medium text-gray-500">Description</Label>
        <div className="relative">
          <SingleMdEditor
            ref={editorRef}
            visible={true}
            onChange={handleEditorChange}
            viewingMode={isWritable ? "edit" : "display"}
            onMount={handleEditorMount}
            additionalExtensions={[ArtifactNode.configure(
              {
                showArtifact: handleArtifactSelect
              }
            )]}
            additionalQueryCommand={(query) => artifactCommand(query, ticket.id, workspaceId, () => {
              fileExplorerRef.current?.refresh();
            }, showSelectArtifactDialog, handleInsertNewEmptyArtifact)}
          />
          {savingStatus !== 'idle' && (
            <div className="absolute top-2 right-2 text-sm text-gray-500">
              {savingStatus === 'saving' ? 'Saving...' : 'Saved'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
