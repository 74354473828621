import React from "react";
import { Badge } from "src/common/shadcn/badge";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "src/common/shadcn/dropdown";

export const TerminalStatusSelect = ({
  status,
  onStatusChange,
  disabled = false,
}: {
  status: string;
  onStatusChange: (value: string) => void;
  disabled?: boolean;
}) => {
  const getBadgeColor = (status: string) => {
    switch (status) {
      case "open":
        return "bg-green-500";
      case "closed":
        return "bg-gray-500";
      default:
        return "bg-blue-500";
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <div className={`outline-none focus:ring-0 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
          <Badge
            variant="outline"
            className={`text-sm h-8 min-w-[100px] flex items-center justify-center rounded-md ${getBadgeColor(
              status
            )} text-white ${disabled ? 'cursor-not-allowed' : 'cursor-pointer hover:opacity-90'}`}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </Badge>
        </div>
      </DropdownMenuTrigger>
      {!disabled && (
        <DropdownMenuContent>
          <DropdownMenuItem onSelect={() => onStatusChange("open")}>
            Open
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => onStatusChange("closed")}>
            Closed
          </DropdownMenuItem>
        </DropdownMenuContent>
      )}
    </DropdownMenu>
  );
};
