import React, { useState, useEffect, useRef, useCallback } from "react";
import { Button } from "src/common/shadcn/button";
import { Save, X } from "lucide-react";
import { updateArtifactContent } from "src/usecase/core-subject-management/api/artifact-api";
import { toast } from "src/common/shadcn/use-toast";
import SingleMdEditor from "src/common/app-base-components/TextEditor/single_md_editor";
import { useSearchParams } from "react-router-dom";

interface RichtextArtifactViewProps {
  artifactId: string;
  workspaceId: number;
  isEditing: boolean;
  onEditStatusChange: (status: boolean) => void;
  isModified: boolean;
  onModifiedChange: (isModified: boolean) => void;
}

export const RichtextArtifactView: React.FC<RichtextArtifactViewProps> = ({
  artifactId,
  workspaceId,
  isEditing,
  onEditStatusChange,
  isModified,
  onModifiedChange,
}) => {
  const [content, setContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const editorRef = useRef<any>(null);
  const baseURL = process.env.REACT_APP_API_URL;

  const fetchContent = async () => {
    try {
      const response = await fetch(
        `${baseURL}/workspaces/${workspaceId}/artifacts/raw/${artifactId}`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch richtext content");
      }
      const text = await response.text();
      setContent(text);
    } catch (err) {
      console.error("Fetch error:", err);
      throw err;
    }
  };

  useEffect(() => {
    const loadContent = async () => {
      try {
        await fetchContent();
      } finally {
        setLoading(false);
      }
    };
    loadContent();
  }, [artifactId, workspaceId]);

  useEffect(() => {
    if (!content || !isEditorReady || !editorRef.current) return;
    setTimeout(() => editorRef.current.setJsonString(content), 0);
  }, [content, isEditorReady]);

  useEffect(() => {
    if (!isEditing && editorRef.current && content) {
      editorRef.current.setJsonString(content);
      onModifiedChange(false);
    }
  }, [isEditing, content]);

  const handleSave = useCallback(async () => {
    if (!workspaceId || !editorRef.current) return;
    
    setSaving(true);
    try {
      const currentContent = editorRef.current.getJsonString();
      const file = new File([currentContent], "content.solartext", {
        type: "text/plain",
      });

      await updateArtifactContent(workspaceId, parseInt(artifactId), file);
      setContent(currentContent);
      onModifiedChange(false);
      toast({
        title: "Success",
        description: "Content saved successfully",
      });
    } catch (error) {
      console.error("Save error:", error);
      toast({
        title: "Error",
        description: "Failed to save content",
        variant: "destructive",
      });
    } finally {
      setSaving(false);
    }
  }, [workspaceId, artifactId, onModifiedChange]);

  const handleCancel = () => {
    onEditStatusChange(false);
  };

  const handleChange = useCallback(() => {
    if (editorRef.current) {
      const currentContent = editorRef.current.getJsonString();
      if (currentContent !== content && !isModified) {
        onModifiedChange(true);
      } else if (currentContent === content && isModified) {
        onModifiedChange(false);
      }
    }
  }, [content, isModified, onModifiedChange]);

  const handleEditorMount = useCallback(() => {
    setIsEditorReady(true);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col h-full">
      {isEditing && (
        <div className="flex items-center gap-2 p-2 bg-gray-50 border-b">
          <Button
            size="sm"
            onClick={handleSave}
            disabled={saving || !isModified}
            className={`flex items-center gap-1 ${saving ? "opacity-50" : ""}`}
          >
            <Save className="w-4 h-4" />
            {saving ? "Saving..." : "Save"}
          </Button>
          <Button
            size="sm"
            variant="ghost"
            onClick={handleCancel}
            disabled={saving}
            className="flex items-center gap-1"
          >
            <X className="w-4 h-4" />
            Exit Edit
          </Button>
        </div>
      )}
      <div className="flex-grow">
        <SingleMdEditor
          ref={editorRef}
          visible={true}
          viewingMode={isEditing ? "edit" : "display"}
          onChange={handleChange}
          onMount={handleEditorMount}
          placeholder="Enter your content..."
        />
      </div>
    </div>
  );
};
