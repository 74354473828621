import { axiosClient } from "src/common/axios/axiosClient";
import { CreateThreadRequest } from "../DTO/thread/CreateThreadRequest";
import { UpdateThreadRequest } from "../DTO/thread/UpdateThreadRequest";
import { ThreadDTO } from "../DTO/common/ThreadDTO";

export interface ListThreadsResponse {
    threads: ThreadDTO[];
}

// API functions
export const createThread = async (
    workspaceId: number,
    request: CreateThreadRequest
): Promise<ThreadDTO> => {
    const response = await axiosClient.post<ThreadDTO>(
        `/workspaces/${workspaceId}/threads`,
        request
    );
    return response.data;
};

export const updateThread = async (
    workspaceId: number,
    threadId: number,
    request: UpdateThreadRequest
): Promise<ThreadDTO> => {
    const response = await axiosClient.put<ThreadDTO>(
        `/workspaces/${workspaceId}/threads/${threadId}`,
        request
    );
    return response.data;
};

export const deleteThread = async (
    workspaceId: number,
    threadId: number
): Promise<{ message: string }> => {
    const response = await axiosClient.delete<{ message: string }>(
        `/workspaces/${workspaceId}/threads/${threadId}`
    );
    return response.data;
};

export const getThread = async (
    workspaceId: number,
    threadId: number
): Promise<ThreadDTO> => {
    const response = await axiosClient.get<ThreadDTO>(
        `/workspaces/${workspaceId}/threads/${threadId}`
    );
    return response.data;
};

export const listThreadsByTicket = async (
    workspaceId: number,
    ticketId: number
): Promise<ThreadDTO[]> => {
    const response = await axiosClient.get<ListThreadsResponse>(
        `/workspaces/${workspaceId}/tickets/${ticketId}/threads`
    );
    return response.data.threads;
};
