import React from "react";
import { Editor, findParentNode, BubbleMenu } from "@tiptap/react";
import {
  CircleMinus,
  Columns,
  Grid2x2X,
  SquareChevronDown,
  SquareChevronLeft,
  SquareChevronRight,
  SquareChevronUp,
  SquareMinus,
  Table,
  TableCellsMerge,
} from "lucide-react";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "src/common/shadcn/tooltip";
import { Button } from "src/common/shadcn/button";

export const TableBubbleMenu = ({ editor }: { editor: Editor }) => {
  return (
    <BubbleMenu
      editor={editor}
      shouldShow={({ editor: theEditor }) =>
        theEditor.isActive("table") && theEditor.isFocused
      }
      tippyOptions={{
        placement: "top-start",
        getReferenceClientRect: (): ClientRect | DOMRect => {
          const { selection } = editor.state;
          const parentTableNode = findParentNode(
            (node) => node.type.name === "table"
          )(selection);
          if (parentTableNode) {
            const startPos = parentTableNode.pos;
            const endPos = startPos + parentTableNode.node.nodeSize;
            const startCoords = editor.view.coordsAtPos(startPos);
            const endCoords = editor.view.coordsAtPos(endPos);

            return {
              top: startCoords.top,
              right: endCoords.right,
              bottom: endCoords.bottom,
              left: startCoords.left,
              width: endCoords.right - startCoords.left,
              height: endCoords.bottom - startCoords.top,
            } as ClientRect;
          }
          return { top: 0, right: 0, bottom: 0, left: 0, width: 0, height: 0 } as ClientRect;
        },
      }}
    >
      <TooltipProvider>
        <div className="flex flex-row bg-background p-1 items-center justify-center rounded-md shadow-md border border-border">
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => editor.chain().focus().addColumnBefore().run()}
              >
                <SquareChevronLeft className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Add column before</p>
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                size="icon"
                onClick={() => editor.chain().focus().addColumnAfter().run()}
              >
                <SquareChevronRight className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Add column after</p>
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => editor.chain().focus().deleteColumn().run()}
              >
                <SquareMinus className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Delete column</p>
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => editor.chain().focus().addRowBefore().run()}
              >
                <SquareChevronUp className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Add row before</p>
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => editor.chain().focus().addRowAfter().run()}
              >
                <SquareChevronDown className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Add row after</p>
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => editor.chain().focus().deleteRow().run()}
              >
                <CircleMinus className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Delete row</p>
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => editor.chain().focus().deleteTable().run()}
              >
                <Grid2x2X className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Delete table</p>
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => editor.chain().focus().mergeCells().run()}
              >
                <TableCellsMerge className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Merge cells</p>
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => editor.chain().focus().splitCell().run()}
              >
                <TableCellsMerge className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Split cells</p>
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={() =>
                  editor.chain().focus().toggleHeaderColumn().run()
                }
              >
                <Columns className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Toggle header column</p>
            </TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => editor.chain().focus().toggleHeaderRow().run()}
              >
                <Table className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Toggle header row</p>
            </TooltipContent>
          </Tooltip>
        </div>
      </TooltipProvider>
    </BubbleMenu>
  );
};
