import { ThreadDTO } from "src/usecase/core-subject-management/DTO/common/ThreadDTO";
import { Card, CardHeader, CardContent, CardFooter } from "src/common/shadcn/card";
import SingleMdEditor from "src/common/app-base-components/TextEditor/single_md_editor";
import { useRef, useEffect, useCallback, useState } from "react";
import { updateThread, deleteThread } from "src/usecase/core-subject-management/api/thread-api";
import { UpdateThreadRequest } from "src/usecase/core-subject-management/DTO/thread/UpdateThreadRequest";
import { useDebounce } from "src/common/hooks/useDebounce";
import { toast } from "src/common/shadcn/use-toast";
import { Avatar, AvatarFallback, AvatarImage } from "src/common/shadcn/avatar";
import { formatDistanceToNow } from 'date-fns';
import { Skeleton } from "src/common/shadcn/skeleton";
import { artifactCommand } from "src/usecase/core-subject-management/artifact-extension-editor/artifact-extention/command";
import { ArtifactNode } from "src/usecase/core-subject-management/artifact-extension-editor/artifact-extention/ArtifactNode";
import { TicketFileExplorerRef } from "./TicketFileExplorer";
import { ArtifactDTO } from "src/usecase/core-subject-management/DTO/common/ArtifactDTO";
import { useSearchParams } from 'react-router-dom';
import { Input } from "src/common/shadcn/input";
import { Button } from "src/common/shadcn/button";
import { Trash2 } from "lucide-react";

interface SingleThreadDetailProps {
  thread: ThreadDTO;
  onEditorReady?: () => void;
  fileExplorerRef: React.RefObject<TicketFileExplorerRef>;
  handleArtifactSelect: (artifactId: number) => void;
  showSelectArtifactDialog: () => Promise<number | undefined>;
  handleInsertNewEmptyArtifact: (extension: string) => Promise<ArtifactDTO | null>;
  isWritable?: boolean;
  onThreadDelete?: () => void;
}

export const SingleThreadDetail: React.FC<SingleThreadDetailProps> = ({ 
  thread, 
  onEditorReady, 
  fileExplorerRef, 
  handleArtifactSelect,
  showSelectArtifactDialog,
  handleInsertNewEmptyArtifact,
  isWritable = true,
  onThreadDelete
}) => {
  const editorRef = useRef<any>(null);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [savingStatus, setSavingStatus] = useState<'idle' | 'saving' | 'saved'>('idle');
  const [lastUpdated, setLastUpdated] = useState<Date>(new Date(thread.updated_at));
  const [searchParams] = useSearchParams();
  const workspaceId = parseInt(searchParams.get('wid') || '0');
  const [threadTitle, setThreadTitle] = useState(thread.thread_title);

  const saveContent = useCallback(async (content: string) => {
    try {
      setSavingStatus('saving');
      const request: UpdateThreadRequest = {
        thread_title: threadTitle,
        thread_content: content,
      };
      await updateThread(workspaceId, thread.id, request);
      setSavingStatus('saved');
      setLastUpdated(new Date());
      // Reset status to idle after 2 seconds
      setTimeout(() => setSavingStatus('idle'), 2000);
    } catch (error) {
      setSavingStatus('idle');
      toast({
        title: "Error saving thread",
        description: "Failed to save thread content. Please try again.",
        variant: "destructive",
      });
    }
  }, [thread.id, threadTitle, workspaceId]);

  const debouncedSave = useDebounce(saveContent, 1000);

  useEffect(() => {
    if (!thread.thread_content || !isEditorReady || !editorRef.current) return;
    setTimeout(() => editorRef.current.setJsonString(thread.thread_content), 0);
  }, [thread.thread_content, isEditorReady]);

  const handleChange = useCallback(() => {
    if (editorRef.current) {
      setSavingStatus('saving');
      const content = editorRef.current.getJsonString();
      debouncedSave(content);
    }
  }, [debouncedSave, setSavingStatus]);

  const handleEditorMount = useCallback(() => {
    setIsEditorReady(true);
    onEditorReady?.();
  }, [onEditorReady]);

  const getInitials = (name: string) => {
    return name
      .split(' ')
      .map(part => part[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const timeAgo = formatDistanceToNow(new Date(thread.created_at), { addSuffix: true });

  const handleDeleteThread = async () => {
    if (!window.confirm('Are you sure you want to delete this thread?')) {
      return;
    }
    try {
      await deleteThread(workspaceId, thread.id);
      toast({
        title: "Success",
        description: "Thread deleted successfully",
      });
      onThreadDelete?.();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete thread",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="w-full bg-white dark:bg-gray-800 shadow-sm hover:shadow-md transition-shadow duration-200">
      <CardHeader className="flex flex-row items-center gap-4 p-4">
        <Avatar className="h-10 w-10">
          <AvatarFallback className="bg-primary/10 text-primary">
            {getInitials(thread.owner?.username || '??')}
          </AvatarFallback>
        </Avatar>
        <div className="flex flex-col flex-grow">
          <div className="flex items-center justify-between">
            <Input
              value={threadTitle}
              readOnly={!isWritable}
              onChange={(e) => {
                setThreadTitle(e.target.value);
                if (editorRef.current) {
                  const content = editorRef.current.getJsonString();
                  debouncedSave(content);
                }
              }}
              className="border-transparent focus:border-gray-300 transition-colors duration-200 shadow-none focus:shadow-none font-semibold text-lg p-0 h-7"
            />
            {isWritable && (
              <Button
                variant="ghost"
                size="icon"
                onClick={handleDeleteThread}
                className="text-destructive hover:text-destructive hover:bg-destructive/10 ml-2"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            )}
          </div>
          <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
            <span>{thread.owner?.username || 'Missing Name'}</span>
            <span>•</span>
            <span>{timeAgo}</span>
            <span className={`${
              savingStatus === 'idle' ? 'opacity-0' : ''
            } transition-all duration-200`}>
              <span className={`${
                savingStatus === 'saving' ? 'text-amber-600 dark:text-amber-400' :
                savingStatus === 'saved' ? 'text-green-600 dark:text-green-400' : ''
              }`}>
                {savingStatus === 'saving' ? 'Saving...' : savingStatus === 'saved' ? 'Saved' : ''}
              </span>
            </span>
          </div>
        </div>
      </CardHeader>
      
      <CardContent className="p-4 pt-0">
        <div className="prose dark:prose-invert max-w-none">
          <SingleMdEditor
            ref={editorRef}
            visible={true}
            onChange={handleChange}
            viewingMode={ isWritable ? "edit" : "display"}
            onMount={handleEditorMount}
            additionalExtensions={[ArtifactNode.configure(
              {
                showArtifact: handleArtifactSelect
              }
            )]}
            additionalQueryCommand={(query) => artifactCommand(query, thread.ticket_id, workspaceId, () => {
              fileExplorerRef.current?.refresh();
            }, showSelectArtifactDialog, handleInsertNewEmptyArtifact)}
        />
        </div>
      </CardContent>

      <CardFooter className="p-4 pt-0">
        <div className="w-full flex justify-end">
          <span className="text-xs text-gray-400 dark:text-gray-500">
            Last updated: {lastUpdated.toLocaleString()}
          </span>
        </div>
      </CardFooter>
    </Card>
  );
};

export const ThreadDetailSkeleton: React.FC = () => (
  <Card className="w-full">
    <CardHeader className="flex flex-row items-center gap-4 p-4">
      <Skeleton className="h-10 w-10 rounded-full" />
      <div className="flex flex-col gap-2">
        <Skeleton className="h-5 w-48" />
        <Skeleton className="h-4 w-32" />
      </div>
    </CardHeader>
    <CardContent className="p-4 pt-0">
      <Skeleton className="h-32 w-full" />
    </CardContent>
  </Card>
);
