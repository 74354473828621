import { useState } from "react";
import { Button } from "src/common/shadcn/button";
import { Input } from "src/common/shadcn/input";
import { Label } from "src/common/shadcn/label";

interface GetInputDialogProps {
  title?: string;
  label?: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export const GetInputDialog = ({ 
  title = "Enter Input", 
  label = "Input",
  placeholder = "Enter value...",
  onChange,
}: GetInputDialogProps) => {
  const [value, setValue] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className="py-2">
      <div className="space-y-2">
        <Label htmlFor="input">{label}</Label>
        <Input
          id="input"
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          className="w-full"
          autoFocus
        />
      </div>
    </div>
  );
};
