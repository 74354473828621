import React, { useState, useMemo, useRef, useEffect } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

interface PDFArtifactViewProps {
  artifactId: string;
  workspaceId: number;
}

export const PDFArtifactView: React.FC<PDFArtifactViewProps> = ({
  artifactId,
  workspaceId,
}) => {
  const [numPages, setNumPages] = useState<number>();
  const [pageWidth, setPageWidth] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const baseURL = process.env.REACT_APP_API_URL;
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerInnerRef = useRef<HTMLDivElement>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
    pageRefs.current = new Array(numPages).fill(null);
  }

  const options = useMemo(
    () => ({
      withCredentials: true,
    }),
    []
  );

  const handleContainerResize = (containerWidth: number) => {
    setPageWidth(containerWidth);
  };

  useEffect(() => {
    if (containerInnerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          handleContainerResize(entry.contentRect.width);
        }
      });
      resizeObserver.observe(containerInnerRef.current);
      return () => resizeObserver.disconnect();
    }
  }, []);

  useEffect(() => {
    const updateCurrentPage = () => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const containerTop = container.scrollTop;
      const containerBottom = containerTop + container.clientHeight;

      let maxVisiblePage = 1;
      let maxVisibleRatio = 0;

      pageRefs.current.forEach((ref, index) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const pageTop =
            rect.top - container.getBoundingClientRect().top + containerTop;
          const pageBottom = pageTop + rect.height;

          const visibleTop = Math.max(containerTop, pageTop);
          const visibleBottom = Math.min(containerBottom, pageBottom);
          const visibleHeight = Math.max(0, visibleBottom - visibleTop);

          const visibilityRatio = visibleHeight / rect.height;

          if (visibilityRatio > maxVisibleRatio) {
            maxVisibleRatio = visibilityRatio;
            maxVisiblePage = index + 1;
          }
        }
      });

      setCurrentPage(maxVisiblePage);
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", updateCurrentPage);
      // Initial update
      updateCurrentPage();
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", updateCurrentPage);
      }
    };
  }, [numPages]);

  return (
    <div className="flex flex-col h-full">
      <div
        ref={containerRef}
        className="flex-grow overflow-auto bg-gray-200"
        onScroll={() => {
          // This empty onScroll handler is needed for iOS Safari
        }}
      >
        <div
          ref={containerInnerRef}
          className="mx-auto"
        >
          <Document
            file={`${baseURL}/workspaces/${workspaceId}/artifacts/raw/${artifactId}`}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <div
                key={`page_${index + 1}`}
                ref={(ref) => (pageRefs.current[index] = ref)}
                data-page-number={index + 1}
                className="flex flex-col mb-4"
              >
                <Page pageNumber={index + 1} width={pageWidth} />
              </div>
            ))}
          </Document>
        </div>
      </div>
      <div className="flex justify-center items-center mt-4 pb-4">
        <p className="text-sm text-gray-600">
          Page {currentPage} of {numPages}
        </p>
      </div>
    </div>
  );
};
